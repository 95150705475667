import { ReactNode } from "react";
import { Colors, Icon } from "swing-components";

import { AlertBoxError, ButtonScore } from "~components";

import "./ModalConfirmation.css"; // Note .css as ionic overrides needed

type ModalConfirmationProps = {
  children: ReactNode;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  onConfirm: () => void;
  title: string;
  hasApiError?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
};

// Note: controlled by `useModal` hook via useIonModal `hook`.
export function ModalConfirmation(props: ModalConfirmationProps) {
  const {
    children,
    onDismiss,
    onConfirm,
    title,
    hasApiError = false,
    primaryButtonText = "Yes",
    secondaryButtonText = "No",
  } = props;

  return (
    <div className="modal-wrapper">
      <div className="modal-header">
        <h2>{title}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={() => onDismiss()} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <div className="modal-body">
        {hasApiError && <AlertBoxError />}
        {children}
      </div>
      <div className="modal-footer">
        <div className="modal-footer-button-spacing">
          <ButtonScore label={primaryButtonText} onClick={onConfirm} />
        </div>
        <ButtonScore label={secondaryButtonText} fill="outline" onClick={onDismiss} />
      </div>
    </div>
  );
}
