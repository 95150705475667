import { ChangeEvent, CSSProperties, ReactNode, useMemo, useState } from "react";
import { Asterisk, Colors } from "swing-components";
import { phoneNumberFormatter, phoneNumberParser } from "swing-utils";

import style from "./PhoneInput.module.css";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  } as CSSProperties,
  label: {
    fontWeight: "600",
    color: Colors.black,
  } as CSSProperties,
  inputWrapper: ({ isDisabled }: Pick<PhoneInputProps, "isDisabled">) =>
    ({
      paddingBottom: 4,
      borderBottom: `1px solid ${isDisabled ? "transparent" : Colors.slate200}`,
    }) as CSSProperties,
  nativeInput: ({ isDisabled }: Pick<PhoneInputProps, "isDisabled">) =>
    ({
      padding: 0,
      border: 0,
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      color: isDisabled ? Colors.slate400 : Colors.black,
      background: "transparent",
      outline: "none",
      appearance: "none",
      touchAction: "manipulation",
    }) as CSSProperties,
};

type PhoneInputProps = {
  error?: ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  onChange: (phoneNumber: string) => void;
  phoneNumber: string;
};

export function PhoneInput(props: PhoneInputProps) {
  const { error, isDisabled, isRequired, label, onChange, phoneNumber } = props;
  const [rawPhoneNumber, setRawNumber] = useState<string>(
    (phoneNumber && phoneNumberParser({ phoneNumber })) || "",
  );

  const formattedPhoneNumber = useMemo(() => {
    if (rawPhoneNumber && rawPhoneNumber.length <= 10) {
      return phoneNumberFormatter({ phoneNumber: rawPhoneNumber }).value;
    }
    return "";
  }, [rawPhoneNumber]);

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    let val = e.target.value;

    if (
      phoneNumberParser({ phoneNumber: val }) ===
      phoneNumberParser({ phoneNumber: formattedPhoneNumber })
    ) {
      // accommodate additional space in `(xxx) ` when deleting input value
      val = val.slice(0, -1);
    }

    // val is formatted so un-format
    const rawVal = phoneNumberParser({ phoneNumber: val });

    // do not allow more than 10 digits
    if (rawVal.length > 10) {
      return;
    }

    // update raw value
    setRawNumber(rawVal);

    // pass raw value to caller
    onChange(rawVal);
  }

  return (
    <label style={styles.container}>
      {label && (
        <div style={styles.label}>
          {label}
          {isRequired && <Asterisk />}
        </div>
      )}
      <div style={styles.inputWrapper({ isDisabled })}>
        <input
          data-testid="phone-input"
          className={style["phone-input"]}
          style={styles.nativeInput({ isDisabled })}
          type="tel"
          disabled={isDisabled}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          aria-label={label}
          inputMode="numeric"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          placeholder={error ? "" : "(xxx) xxx-xxx"}
          value={formattedPhoneNumber}
          onChange={handleOnChange}
        />
      </div>
      {error && <div>{error}</div>}
    </label>
  );
}
