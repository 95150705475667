const e = {
  // Blue
  blue300: "var(--swing-blue300)",
  blue400: "var(--swing-blue400)",
  blue500: "var(--swing-blue500)",
  blue600: "var(--swing-blue600)",
  // Yellow
  yellow200: "var(--swing-yellow200)",
  yellow400: "var(--swing-yellow400)",
  yellow500: "var(--swing-yellow500)",
  yellow600: "var(--swing-yellow600)",
  // Purple
  purple200: "var(--swing-purple200)",
  purple500: "var(--swing-purple500)",
  purple600: "var(--swing-purple600)",
  // Teal
  teal200: "var(--swing-teal200)",
  teal500: "var(--swing-teal500)",
  teal600: "var(--swing-teal600)",
  // Green
  green200: "var(--swing-green200)",
  green500: "var(--swing-green500)",
  green600: "var(--swing-green600)",
  // Red
  red200: "var(--swing-red200)",
  red500: "var(--swing-red500)",
  red600: "var(--swing-red600)",
  // Slate
  slate200: "var(--swing-slate200)",
  slate300: "var(--swing-slate300)",
  slate400: "var(--swing-slate400)",
  slate600: "var(--swing-slate600)",
  // White
  white200: "var(--swing-white200)",
  white300: "var(--swing-white300)",
  // Black
  black: "var(--swing-black)",
  blueStone200: "var(--swing-blueStone200)",
  blueStone300: "var(--swing-blueStone300)",
  // Other
  overlay: "var(--swing-overlay)",
  shadow: "var(--swing-shadow)",
  // Pink
  pink: "var(--swing-pink)"
};
export {
  e as Colors
};
