import { ModalOptions, ReactComponentOrElement, useIonModal } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";

type ModalProps = {
  component: ReactComponentOrElement;
  modalOptions?: Omit<ModalOptions, "component" | "componentProps"> & HookOverlayOptions;
};

export const useModal = (props: ModalProps) => {
  const { component, modalOptions } = props;

  const [presentModal, dismissModal] = useIonModal(component);

  const openModal = () => presentModal(modalOptions);
  const closeModal = () => dismissModal();

  return { openModal, closeModal };
};
