import { ChangeEvent, FormEvent, useState } from "react";
import { Colors, Icon } from "swing-components";
import { generateSwingSupportURL } from "swing-utils";

import { ErrorMessage } from "~components";
import { msg } from "~utils";
import styles from "./LocationSearch.module.css";

type LocationSearchProps = {
  hasError?: boolean;
  onChange?: (searchTerm: string) => void;
  onSearchSubmit: (searchTerm: string) => void;
  searchTerm: string;
};

export function LocationSearch(props: LocationSearchProps) {
  const { hasError = false, onChange, onSearchSubmit, searchTerm } = props;

  return (
    <div className={`${styles["location-search"]}`}>
      <p className={styles["location-search-description"]}>{msg("LOCATION_SEARCH_DESCRIPTION")}</p>
      <InputZipCode
        hasError={hasError}
        onChange={onChange}
        onSearchSubmit={onSearchSubmit}
        searchTerm={searchTerm}
      />
      {searchTerm && !hasError && (
        <p className={styles["location-search-search-term"]}>
          {msg("LOCATION_SEARCH_SHOWING_LOCATIONS")}{" "}
          <span style={{ fontWeight: 700 }}>{searchTerm}</span>
        </p>
      )}
    </div>
  );
}

type InputZipCodeProps = {
  hasError: boolean;
  onChange?: (searchTerm: string) => void;
  onSearchSubmit: (searchTerm: string) => void;
  searchTerm: string;
};

function InputZipCode(props: InputZipCodeProps) {
  const { hasError, onChange, onSearchSubmit, searchTerm } = props;
  const [_searchTerm, _setSearchTerm] = useState<string>(searchTerm || "");
  const [isInvalidZip, setIsInvalidZip] = useState<boolean>(false);

  function handleInput(e: ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    // remove error when user types
    setIsInvalidZip(false);
    // length 0 allows user to delete input
    if (val.length === 0 || isNumericCharsOnly(val)) {
      _setSearchTerm(val);
      onChange?.(val);
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isZipCodeValid(_searchTerm)) {
      setIsInvalidZip(false);
      return onSearchSubmit(_searchTerm);
    }
    setIsInvalidZip(true);
    return;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={`${styles["input-zip-code-wrapper"]} ${
          isInvalidZip || hasError ? styles["input-zip-code-wrapper-error"] : ""
        }`}
      >
        <Icon name="Location" aria-hidden="true" />
        <input
          data-testid="location-search-input"
          className={styles["input-zip-code-input"]}
          type="text"
          inputMode="numeric"
          pattern="[0-9]{5}"
          maxLength={5}
          placeholder="xxxxx"
          value={_searchTerm}
          onChange={handleInput}
          required
        />
        <button
          data-testid="location-search-button"
          type="submit"
          className={styles["input-zip-code-submit-btn"]}
          disabled={isZipCodeValid(_searchTerm) && searchTerm !== _searchTerm ? false : true}
        >
          <Icon
            name="Arrow Right"
            color={
              isZipCodeValid(_searchTerm) && searchTerm !== _searchTerm
                ? Colors.blue500
                : Colors.slate300
            }
          />
        </button>
      </div>
      {isInvalidZip && !hasError && (
        <ErrorMessage>
          <span>{msg("LOCATION_SEARCH_INVALID_ZIP_CODE")}</span>
        </ErrorMessage>
      )}
      {!isInvalidZip && hasError && (
        <ErrorMessage>
          <span>
            <a
              href={generateSwingSupportURL(msg("SWING_SUPPORT_LINK_GENERAL_REQUEST"))}
              target="_blank"
              rel="noreferrer"
            >
              {msg("LOCATION_SEARCH_CONTACT_SUPPORT_1")}
            </a>{" "}
            {msg("LOCATION_SEARCH_CONTACT_SUPPORT_2")}.
          </span>
        </ErrorMessage>
      )}
    </form>
  );
}

export function isNumericCharsOnly(value: string) {
  const regExNumericOnly = new RegExp("^[0-9]+$");
  return regExNumericOnly.test(value);
}

export function isZipCodeValid(zipCode: string) {
  // valid when 5 numeric chars
  return zipCode.length === 5 && isNumericCharsOnly(zipCode);
}
