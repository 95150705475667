import { jsx as t } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-DO6tcIND.js";
import o from "../../../../string-table.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as c } from "../Section/Section.js";
import '../../../../../css/Education.css';const m = "_list_1k8vm_1", s = {
  "list-wrapper": "_list-wrapper_1k8vm_1",
  list: m
};
function O(n) {
  const {
    editable: i,
    education: r
  } = n;
  return /* @__PURE__ */ t(c, {
    onEditClick: i != null && i.isEditable ? i.onEditEducation : void 0,
    title: o("SUB_PROFILE_EDUCATION_HEADER"),
    children: /* @__PURE__ */ t("div", {
      children: r && r.length > 0 ? /* @__PURE__ */ t("ul", {
        className: s["list-wrapper"],
        children: r.map((l) => /* @__PURE__ */ t("li", {
          className: s.list,
          children: l.name
        }, l.id))
      }) : /* @__PURE__ */ t("span", {
        style: {
          fontStyle: "italic"
        },
        children: i != null && i.isEditable ? o("SUB_PROFILE_EDUCATION_EDIT_EMPTY") : o("SUB_PROFILE_NO_INFORMATION")
      })
    })
  });
}
export {
  O as Education
};
