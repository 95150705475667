import { jsx as i, jsxs as o } from "react/jsx-runtime";
import { I as r } from "../../../../../Icon-CcIP5yi-.js";
import "../../../../../AuthProvider-DO6tcIND.js";
import "@ionic/react";
import a from "../../../../string-table.js";
import { IconBlock as s } from "../../../../molecules/IconBlock/IconBlock.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as p } from "../Section/Section.js";
import '../../../../../css/ClassroomPreferences.css';const S = "_container_1d0ci_1", c = {
  container: S,
  "content-title": "_content-title_1d0ci_9",
  "content-no-info": "_content-no-info_1d0ci_13"
}, _ = {
  "k-2": "tk,pre-k,k,1,2",
  "3-5": "3,4,5",
  "6-8": "6,7,8",
  "9-12": "9,10,11,12"
}, B = {
  "pre-k": "k-2",
  tk: "k-2",
  k: "k-2",
  1: "k-2",
  2: "k-2",
  3: "3-5",
  4: "3-5",
  5: "3-5",
  6: "6-8",
  7: "6-8",
  8: "6-8",
  9: "9-12",
  10: "9-12",
  11: "9-12",
  12: "9-12"
}, m = {
  "pre-k": "Pre-K to 2nd",
  tk: "Pre-K to 2nd",
  k: "Pre-K to 2nd",
  1: "Pre-K to 2nd",
  2: "Pre-K to 2nd",
  3: "3rd to 5th",
  4: "3rd to 5th",
  5: "3rd to 5th",
  6: "6th to 8th",
  7: "6th to 8th",
  8: "6th to 8th",
  9: "9th to 12th",
  10: "9th to 12th",
  11: "9th to 12th",
  12: "9th to 12th"
}, g = {
  "gen-ed": "General Education",
  elective: "Electives (Music, Art, etc.)",
  english: "English/Language Arts",
  "physical-education": "Health/Physical Education",
  "social-studies": "History/Social Studies",
  math: "Math",
  science: "Science",
  "special-education": "Special Education",
  "world-languages": "World Languages",
  other: "Other",
  "not-sure": "I'm not sure yet"
}, L = {
  english: "English",
  spanish: "Spanish",
  mandarin: "Chinese (Mandarin)",
  cantonese: "Chinese (Cantonese)",
  french: "French",
  tagalog: "Tagalog",
  vietnamese: "Vietnamese",
  arabic: "Arabic",
  korean: "Korean",
  japanese: "Japanese",
  german: "German",
  asl: "American Sign Language",
  other: "Other"
};
function d(e, t) {
  if (e)
    return Array.from(new Set(e.map((n) => t[n])));
}
function I(e, t) {
  return t === _ ? e.map((n) => t[n]) : JSON.parse(e).map((n) => n);
}
function F(e) {
  var h, E, u;
  const {
    editable: t,
    classroomPreferences: n
  } = e;
  return /* @__PURE__ */ i(p, {
    title: a("SUB_PROFILE_CLASSROOM_PREFERENCES_HEADER"),
    children: /* @__PURE__ */ o("div", {
      className: c.container,
      children: [/* @__PURE__ */ i(s, {
        content: /* @__PURE__ */ o("div", {
          children: [/* @__PURE__ */ o("span", {
            className: c["content-title"],
            children: [a("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_GRADE_LEVEL"), ":", " "]
          }), l(d((h = n == null ? void 0 : n.gradeLevels) == null ? void 0 : h.value, m))]
        }),
        icon: /* @__PURE__ */ i(r, {
          name: "Grad Cap"
        }),
        onClick: t != null && t.isEditable ? t.onEditGradeLevel : void 0
      }), /* @__PURE__ */ i(s, {
        content: /* @__PURE__ */ o("div", {
          children: [/* @__PURE__ */ o("span", {
            className: c["content-title"],
            children: [a("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_SUBJECTS"), ":", " "]
          }), l(d((E = n == null ? void 0 : n.subjects) == null ? void 0 : E.value, g))]
        }),
        icon: /* @__PURE__ */ i(r, {
          name: "Book"
        }),
        onClick: t != null && t.isEditable ? t.onEditSubjects : void 0
      }), /* @__PURE__ */ i(s, {
        content: /* @__PURE__ */ o("div", {
          children: [/* @__PURE__ */ o("span", {
            className: c["content-title"],
            children: [a("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_LANGUAGES"), ":", " "]
          }), l(d((u = n == null ? void 0 : n.languages) == null ? void 0 : u.value, L))]
        }),
        icon: /* @__PURE__ */ i(r, {
          name: "Chat Bubble Active"
        }),
        onClick: t != null && t.isEditable ? t.onEditLanguages : void 0
      })]
    })
  });
}
function l(e) {
  return e ? /* @__PURE__ */ i("span", {
    children: e.join(", ")
  }) : /* @__PURE__ */ i("span", {
    className: c["content-no-info"],
    children: a("SUB_PROFILE_NO_INFORMATION")
  });
}
export {
  F as ClassroomPreferences,
  _ as convertDatomicGrades,
  m as convertDisplayGrades,
  L as convertLanguages,
  d as convertPreferencesToDisplay,
  I as convertPreferencesToSave,
  g as convertSubjects,
  B as convertValueTypesGrades
};
