import { MarkdownContent } from "~components";
import type { OnboardingConfiguration } from "~onboarding/utils";
import { msg } from "~utils";

type RejectionPageBaseProps = Pick<OnboardingConfiguration, "rejectionMessageMarkdown">;

export function RejectionPageBase(props: RejectionPageBaseProps) {
  const { rejectionMessageMarkdown } = props;

  return (
    <div style={{ padding: "24px 16px" }}>
      <h3>Welcome</h3>
      <p>{msg("SETUP_REJECTION_UNIVERSAL")}</p>
      <MarkdownContent body={rejectionMessageMarkdown} />
      <p>{msg("SETUP_CONTACT_SUPPORT")}</p>
    </div>
  );
}
