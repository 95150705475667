import axios from "axios";

import { components, paths } from "../__generated__/api";
import { BUILD_PROFILE } from "./build";
import { Replace } from "./types";

//#region Models
export type WebinarOption = components["schemas"]["WebinarOption"];
// FIXME: Move this back to using the api typing once we update the endpoint types.
type SubAttributeValue = string | WebinarOption;
export type SubAttribute = Replace<
  Replace<components["schemas"]["SubAttribute"], "updatedAt", Date>,
  "value",
  SubAttributeValue
>;
//#endregion

//#region Models
export type SkillBuilderAttribute = components["schemas"]["SkillBuilderAttr"];
//#endregion

//#region Constants
/**
 * TODO: To reduce the reliance on a runtime built.ts file, Vite offers server
 * and client environment variable support. See https://vitejs.dev/guide/env-and-mode.html
 *
 * This would help skip the usage of `BUILD_PROFILE` and `API_HOST` as these
 * are only used in here.
 */
export const API_HOSTS = {
  local: "http://localhost:8000",
  staging: "https://staging2-api.aws.swingeducation.com",
  prod: "https://prod-api.aws.swingeducation.com",
};

export const SWING_API_HOST = API_HOSTS[BUILD_PROFILE];

/* Axios is the fetching library of choice since it supports middleware, named
 * interceptors, which is used to add authentication headers.
 *
 * This constant is defined here without an interceptor, however, when it's
 * passed into the `AuthProvider` component in `src/components/pages/AuthWrapper.tsx`,
 * an interceptor is added to it.
 */
export const api = axios.create({
  baseURL: SWING_API_HOST,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
//#endregion

//#region Utility Types
/**
 * Given a map (object with keys) and a shape (object with key and types),
 * return the keys which match the shape.
 *
 * @example
 * GetMatchingKeys<{ a: string, b: string }, { a: any }> -> "a"
 * GetMatchingKeys<{ a: { b: boolean, c: string } }, { a: { b: boolean} }> -> "a"
 * GetMatchingKeys<{ a: { b: boolean, c: string } }, { a: { d: boolean} }> -> never
 */
type GetMatchingKeys<Map, Shape> = {
  [Key in keyof Map]: Map[Key] extends Shape ? Key : never;
}[keyof Map];

/**
 * Given an object and an array of keys, walk down the object using each key
 * at each level, and return the type of the final key.
 *
 * @example
 * MaybeKeys<{ a: { b : string } }, ["a", "b"]> -> string
 * MaybeKeys<{ a: string }, ["c"]> -> never
 */
type MaybeKeys<TObj, TKeys extends unknown[]> = TKeys extends [infer TKey, ...infer TRest]
  ? TKey extends keyof TObj
    ? MaybeKeys<TObj[TKey], TRest>
    : never
  : TObj;

//#endregion

//#region GET Function
//#region Types
// A union of all endpoints which support a GET method and has a response type
type GETEndpoints = GetMatchingKeys<
  paths,
  {
    get: {
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// A union of all endpoint which support a GET method and has a path parameter
type GETEndpointsWithPathParameters = GetMatchingKeys<
  paths,
  {
    get: {
      parameters: {
        path: object;
      };
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of GET endpoints without path parameters
type GETEndpointWithoutPathParameters = Exclude<GETEndpoints, GETEndpointsWithPathParameters>;
//#endregion

/**
 * Typesafe GET endpoints fetch wrapper with typing support for the endpoint,
 * path parameters and query parameters.
 *
 * @example
 * // GET endpoint WITHOUT path and query parameters
 * const response = GET("/endpoint");
 *
 * // GET endpoint WITH query parameters and WITHOUT path parameters
 * const response = GET("/endpoint", {
 *  queryParams: {
 *   page: 2
 *  }
 * });
 *
 * // GET endpoint WITH path and query parameter
 * const response = GET("/endpoint/{requestId}", {
 *  pathParams: {
 *    requestId: 123
 *  },
 *  queryParams: {
 *    page: 1
 *  }
 * });
 */
// Typing for the GET method for endpoints WITHOUT path parameters
export async function GET<Endpoint extends GETEndpointWithoutPathParameters>(
  endpoint: Endpoint,
  maybeQueryParams?: {
    // We have to double the `MaybeKey` since the `parameters` key is not always
    // present in these endpoints. We could be more strict above, however, we'd
    // then create another pair of unions to deal with.
    // TODO: @KoltonG - Create a utility type to help with this.
    queryParams?: MaybeKeys<paths[Endpoint]["get"], ["parameters", "query"]>;
  },
): Promise<paths[Endpoint]["get"]["responses"][200]["content"]["application/json"]>;
// Typing for the GET method for endpoint WITH path parameters
export async function GET<Endpoint extends GETEndpointsWithPathParameters>(
  endpoint: Endpoint,
  pathParamsAndMaybeQueryParams: {
    pathParams: paths[Endpoint]["get"]["parameters"]["path"];
    queryParams?: MaybeKeys<paths[Endpoint]["get"]["parameters"], ["query"]>;
  },
): Promise<paths[Endpoint]["get"]["responses"][200]["content"]["application/json"]>;
// Implementation of the GET method
export async function GET<Endpoint extends GETEndpoints>(
  endpoint: Endpoint,
  maybePathAndQueryParams?: {
    pathParams?: MaybeKeys<paths[Endpoint]["get"], ["parameters", "path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["get"], ["parameters", "query"]>;
  },
) {
  const { pathParams, queryParams } = maybePathAndQueryParams ?? {};

  const url = generateUrlWithPathParams(endpoint, pathParams);
  return (await api.get(url, { params: queryParams })).data;
}
//#endregion

//#region POST Function
//#region Types
// Union of ALL POST endpoints
type POSTEndpoints = GetMatchingKeys<
  paths,
  {
    post: {
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of POST endpoints with a body parameter
type POSTEndpointsWithBodyParameters = GetMatchingKeys<
  paths,
  {
    post: {
      // TODO: @KoltonG - Find out why body are optional in the generated types
      // as this should not be the case.
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of POST endpoints with path parameters
type POSTEndpointsWithPathParameters = GetMatchingKeys<
  paths,
  {
    post: {
      parameters: {
        path: object;
      };
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: object;
      };
    };
  }
>;
// Union of POST endpoints without path parameters
type POSTEndpointsWithoutPathParameters = Exclude<
  POSTEndpointsWithBodyParameters,
  POSTEndpointsWithPathParameters
>;
//#endregion

/**
 * Typesafe POST endpoints fetch wrapper with typing support for the endpoint,
 * body, and path parameters.
 *
 * @example
 * // POST endpoint WITHOUT path parameters
 * const response = POST("/endpoint", {
 *  firstName: "John",
 *  lastName: "Doe",
 * });
 *
 * // POST endpoint WITH path parameters
 * const response = POST("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  pathParams: {
 *    requestId: "123"
 *  }
 * });
 *
 * // POST endpoint WITH path parameters and query parameters
 * const response = POST("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  pathParams: {
 *    requestId: "123"
 *  },
 *  queryParams: {
 *   page: 1
 *  }
 * });
 */
// Typing for the POST endpoint WITHOUT a body and a path parameter
export async function POST<Endpoint extends "/api/user/me">(
  endpoint: Endpoint,
): Promise<paths[Endpoint]["post"]["responses"][200]["content"]["application/json"]>;
// Typing for the POST method for endpoints WITHOUT path parameters
export async function POST<Endpoint extends POSTEndpointsWithoutPathParameters>(
  endpoint: Endpoint,
  body: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["post"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
): Promise<paths[Endpoint]["post"]["responses"][200]["content"]["application/json"]>;
// Typing for the POST method for endpoints WITH path parameters
export async function POST<Endpoint extends POSTEndpointsWithPathParameters>(
  endpoint: Endpoint,
  body: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["post"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
  pathAndMaybeQueryParameters: {
    pathParams: MaybeKeys<paths[Endpoint]["post"]["parameters"], ["path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["post"]["parameters"], ["query"]>;
  },
): Promise<paths[Endpoint]["post"]["responses"][200]["content"]["application/json"]>;
// Implementation of the POST method
export async function POST<Endpoint extends POSTEndpoints>(
  endpoint: Endpoint,
  body?: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["post"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
  maybePathAndQueryParams?: {
    pathParams?: MaybeKeys<paths[Endpoint]["post"], ["parameters", "path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["post"], ["parameters", "query"]>;
  },
) {
  const { pathParams, queryParams } = maybePathAndQueryParams ?? {};

  const url = generateUrlWithPathParams(endpoint, pathParams);
  return (await api.post(url, body, { params: queryParams })).data;
}

export type POSTBody<Endpoint extends Exclude<POSTEndpoints, "/api/user/me">> = MaybeKeys<
  Extract<MaybeKeys<paths[Endpoint]["post"], ["requestBody"]>, object>,
  ["content", "application/json"]
>;

//#endregion

//#region PUT Function
//#region Types
// Union of PUT endpoints
type PUTEndpoints = GetMatchingKeys<
  paths,
  {
    put: {
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of PUT endpoints with a body parameter
type PUTEndpointsWithBodyParameters = GetMatchingKeys<
  paths,
  {
    put: {
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of PUT endpoints with path parameters
type PUTEndpointsWithPathParameters = GetMatchingKeys<
  paths,
  {
    put: {
      parameters: {
        path: object;
      };
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;
// Union of PUT endpoints without path parameters
type PUTEndpointsWithoutPathParameters = Exclude<
  PUTEndpointsWithBodyParameters,
  PUTEndpointsWithPathParameters
>;

/**
 * Typesafe PUT endpoints fetch wrapper with typing support for the endpoint,
 * body, and path parameters.
 *
 * @example
 * // PUT endpoint WITHOUT path parameters
 * const response = PUT("/endpoint", {
 *  firstName: "John",
 *  lastName: "Doe",
 * });
 *
 * // PUT endpoint WITH path parameters
 * const response = PUT("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  path: {
 *    requestId: "123"
 *  }
 * });
 *
 * // PUT endpoint WITH path parameters and query parameters
 * const response = PUT("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  pathParams: {
 *    requestId: "123"
 *  },
 *  queryParams: {
 *   page: 1
 *  }
 * });
 */
// Typing for the PUT method for endpoint WITH path parameters
export async function PUT<Endpoint extends PUTEndpointsWithPathParameters>(
  endpoint: Endpoint,
  body: Extract<paths[Endpoint]["put"]["requestBody"], object>["content"]["application/json"],
  pathAndMaybeQueryParameters: {
    pathParams: MaybeKeys<paths[Endpoint]["put"]["parameters"], ["path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["put"]["parameters"], ["query"]>;
  },
): Promise<paths[Endpoint]["put"]["responses"][200]["content"]["application/json"]>;
export async function PUT<Endpoint extends PUTEndpointsWithoutPathParameters>(
  endpoint: Endpoint,
  body: Extract<paths[Endpoint]["put"]["requestBody"], object>["content"]["application/json"],
): Promise<paths[Endpoint]["put"]["responses"][200]["content"]["application/json"]>;
// Implementation of the PUT method
export async function PUT<Endpoint extends PUTEndpoints>(
  endpoint: Endpoint,
  body: Extract<paths[Endpoint]["put"]["requestBody"], object>["content"]["application/json"],
  maybePathAndQueryParams?: {
    pathParams?: MaybeKeys<paths[Endpoint]["put"], ["parameters", "path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["put"], ["parameters", "path"]>;
  },
) {
  const { pathParams, queryParams } = maybePathAndQueryParams ?? {};

  const url = generateUrlWithPathParams(endpoint, pathParams);

  return (await api.put(url, body, { params: queryParams })).data;
}

export type PUTBody<Endpoint extends PUTEndpoints> = MaybeKeys<
  Extract<MaybeKeys<paths[Endpoint]["put"], ["requestBody"]>, object>,
  ["content", "application/json"]
>;

//#endregion

//#region DELETE Function
//#region Types
// Union of DELETE endpoints
type DELETEEndpoints = GetMatchingKeys<
  paths,
  {
    delete: {
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;

// Union of DELETE endpoints with a body parameter
type DELETEEndpointsWithBodyParameters = GetMatchingKeys<
  paths,
  {
    delete: {
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: {
          content: object;
        };
      };
    };
  }
>;

// Union of DELETE endpoints with path parameters
type DELETEEndpointsWithPathParameters = GetMatchingKeys<
  paths,
  {
    delete: {
      parameters: {
        path: object;
      };
      requestBody?: {
        content: {
          // We are specifically filtering for JSON content types
          "application/json": object;
        };
      };
      responses: {
        200: object;
      };
    };
  }
>;

type DELETEEndpointsWithoutPathParameters = Exclude<
  DELETEEndpointsWithBodyParameters,
  DELETEEndpointsWithPathParameters
>;
//#endregion

/**
 * Typesafe DELETE endpoints fetch wrapper with typing support for the endpoint,
 * body, and path parameters.
 *
 * @example
 * // DELETE endpoint WITHOUT path parameters
 * const response = DELETE("/endpoint", {
 *  firstName: "John",
 *  lastName: "Doe",
 * });
 *
 * // DELETE endpoint WITH path parameters
 * const response = DELETE("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  path: {
 *    requestId: "123"
 *  }
 * });
 *
 * // DELETE endpoint WITH path parameters and query parameters
 * const response = DELETE("/endpoint/{requestId}", {
 *  firstName: "John",
 *  lastName: "Doe",
 * }, {
 *  pathParams: {
 *    requestId: "123"
 *  },
 *  queryParams: {
 *   page: 1
 *  }
 * });
 */

// Typing for the DELETE method for endpoints WITHOUT path parameters
export async function DELETE<Endpoint extends DELETEEndpointsWithoutPathParameters>(
  endpoint: Endpoint,
  body: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["delete"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
): Promise<paths[Endpoint]["delete"]["responses"][200]["content"]["application/json"]>;

// Typing for the DELETE method for endpoints WITH path parameters
export async function DELETE<Endpoint extends DELETEEndpointsWithPathParameters>(
  endpoint: Endpoint,
  body: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["delete"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
  pathAndMaybeQueryParameters: {
    pathParams: MaybeKeys<paths[Endpoint]["delete"]["parameters"], ["path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["delete"]["parameters"], ["query"]>;
  },
): Promise<paths[Endpoint]["delete"]["responses"][200]["content"]["application/json"]>;

// Implementation of the DELETE method
export async function DELETE<Endpoint extends DELETEEndpoints>(
  endpoint: Endpoint,
  body?: MaybeKeys<
    Extract<MaybeKeys<paths[Endpoint]["delete"], ["requestBody"]>, object>,
    ["content", "application/json"]
  >,
  maybePathAndQueryParams?: {
    pathParams?: MaybeKeys<paths[Endpoint]["delete"], ["parameters", "path"]>;
    queryParams?: MaybeKeys<paths[Endpoint]["delete"], ["parameters", "query"]>;
  },
) {
  const { pathParams, queryParams } = maybePathAndQueryParams ?? {};

  const URL = generateUrlWithPathParams(endpoint, pathParams);

  return (await api.delete(URL, { data: body, params: queryParams })).data;
}

export type DELETEBody<Endpoint extends DELETEEndpoints> = MaybeKeys<
  Extract<MaybeKeys<paths[Endpoint]["delete"], ["requestBody"]>, object>,
  ["content", "application/json"]
>;
//#endregion

/********** Helpers **********/
/**
 * Given an URL with potential path parameters, fill in the path parameters with
 * the given second argument.
 *
 * @example
 * // Given an endpoint with path parameters
 * const url = generateURL("/api/sub/{id}", {
 *  path: {
 *    id: 123
 *  }
 * });
 * console.log(url); // "/api/sub/123"
 */
export function generateUrlWithPathParams<PathParams>(
  endpoint: string,
  maybePathParams?: PathParams,
) {
  let URL: string = SWING_API_HOST + endpoint;

  // When there are path parameters
  if (maybePathParams) {
    // Replace the `{path}` with the value of path parameter
    URL = URL.replace(
      // Matches on the `{path}` and captures `path` to be used as a key
      /\{([^}]+)\}/g,
      (match, paramName: keyof typeof maybePathParams) => {
        // Replaces the `{path}` with the value of the path parameter or
        // gracefully falls back to the original match
        return (maybePathParams[paramName] ?? match) as string;
      },
    );
  }

  return URL;
}
