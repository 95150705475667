import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  SegmentValue,
} from "@ionic/react";

import styles from "./Segment.module.css";

type SegmentVal = "past" | "upcoming";

type Segment = {
  label?: string;
  value: SegmentVal;
};

export type Segments = Segment[];

export type SegmentProps = {
  segments: Segments;
  initialValue: SegmentValue;
  onSegmentChange: (v: SegmentValue) => void;
};

export function Segment(props: SegmentProps) {
  const { segments, initialValue, onSegmentChange } = props;

  function handleSegmentChange(e: SegmentCustomEvent) {
    const selectedSegment = e.detail.value;

    if (selectedSegment) {
      onSegmentChange(selectedSegment);
    }
  }

  return (
    <div>
      <IonSegment value={initialValue} onIonChange={handleSegmentChange}>
        {segments.map(({ label, value }) => (
          <IonSegmentButton
            className={styles["segment-width"]}
            key={value}
            color="primary"
            value={value}
          >
            <IonLabel>{label}</IonLabel>
          </IonSegmentButton>
        ))}
      </IonSegment>
    </div>
  );
}
