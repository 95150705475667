import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import { AlertBoxError, ContentSingleColumn } from "~components";
import { GET, msg, SchoolProfileData } from "~utils";
import { ScorePage } from "../ScoreTemplates/ScorePage";
import { SchoolArrivingOnsite, SchoolProfileSummary, SchoolSitePolicies } from "./components";

export function SchoolProfile() {
  const params = useParams<{ schoolId: string }>();

  const { data: schoolProfileData, isLoading: isSchoolProfileLoading } = useQuery({
    queryKey: ["fetchSchoolProfile", params.schoolId],
    queryFn: () =>
      GET("/api/sub/school/{school-id}", {
        pathParams: { "school-id": params.schoolId },
      }),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
  });

  return (
    <ScorePage title={msg("SCHOOL_PROFILE_TITLE")} isLoading={isSchoolProfileLoading} hasBack>
      <ContentSingleColumn>
        <SchoolProfileView school={schoolProfileData?.data} />
      </ContentSingleColumn>
    </ScorePage>
  );
}

export type SchoolProfileViewProps = {
  school?: SchoolProfileData;
};

export function SchoolProfileView(props: SchoolProfileViewProps) {
  const { school } = props;

  if (!school) {
    return <div></div>;
  }

  const {
    name,
    summary,
    websiteUrl,
    dayOfContact,
    dayOfEmail,
    dayOfPhone,
    parkingCost,
    parkingLocation,
    checkinLocation,
    checkinProcedure,
    checkoutLocation,
    checkoutProcedure,
    subPolicies,
    studentPolicies,
    address,
  } = school;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "calc(100% - 48px)",
          maxWidth: "900px",
        }}
      >
        <div style={{ margin: "0 auto" }}>
          {school ? (
            <div style={{ paddingBottom: "16px" }}>
              <div style={{ paddingBottom: "16px" }}>
                <SchoolProfileSummary
                  websiteUrl={websiteUrl}
                  summary={summary}
                  name={name}
                  address={address ?? undefined}
                />
              </div>
              <div style={{ paddingBottom: "16px" }}>
                <SchoolArrivingOnsite
                  dayOfContact={dayOfContact}
                  dayOfEmail={dayOfEmail}
                  dayOfPhone={dayOfPhone}
                  parkingCost={parkingCost}
                  parkingLocation={parkingLocation}
                  checkinLocation={checkinLocation}
                  checkinProcedure={checkinProcedure}
                  checkoutLocation={checkoutLocation}
                  checkoutProcedure={checkoutProcedure}
                />
              </div>
              <div style={{ paddingBottom: "16px" }}>
                <SchoolSitePolicies subPolicies={subPolicies} studentPolicies={studentPolicies} />
              </div>
            </div>
          ) : (
            <SchoolProfileEmptyView />
          )}
        </div>
      </div>
    </div>
  );
}

export function SchoolProfileEmptyView() {
  return <AlertBoxError />;
}
