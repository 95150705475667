import Markdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";
import rehypeRaw from "rehype-raw";

import { markdown } from "./MarkdownContent.module.css";

type MarkdownContentProps = {
  /**
   * A string containing Markdown
   */
  body: string;
  /**
   * Optional prop containing an object with CSS styles
   */
  style?: React.CSSProperties;
};

export function MarkdownContent(props: MarkdownContentProps) {
  const { body, style } = props;
  return (
    <div style={style}>
      <Markdown
        rehypePlugins={[rehypeRaw, [rehypeExternalLinks, { target: "_blank" }]]}
        className={markdown}
      >
        {body}
      </Markdown>
    </div>
  );
}
