/* eslint-disable prettier/prettier */
import "./polyfill";
import { createBrowserHistory } from "history";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { AuthWrapper } from "./components/pages";
import { reportWebVitals } from "./reportWebVitals";
import { API_HOSTS, BUILD_VER, getEnvironment, SWING_SUBS_SENTRY_DSN } from "./utils";

import * as Sentry from "@sentry/react";

// Defining and exporting `history` for Sentry's React Route V5 instrumentation.
const sentryHistory = createBrowserHistory();

/**
 * Sentry Configuration
 *
 * TODO: If SourceMaps are not coming through nicely, see
 * https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps
 */
Sentry.init({
  //#region Base Options
  // DSN controlls if Sentry is initalized, so in envs where we do not want Sentry, leave the var blank or "Not-Available"
  // currently just the Prod env.mk file in 1password has the DSN value
  dsn: SWING_SUBS_SENTRY_DSN,
  environment: getEnvironment(),
  release: BUILD_VER,
  //#endregion

  // Integrations
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history: {sentryHistory},
    }),
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole
    Sentry.captureConsoleIntegration({ levels: ["error", "info"] }),
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/contextlines
    Sentry.contextLinesIntegration(),
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/httpclient
    Sentry.httpClientIntegration(),
    // https://docs.sentry.io/platforms/javascript/guides/react/usage/distributed-tracing

    // TODO: Add Browser Profiling at some point which require S3 header updates
    // https://docs.sentry.io/platforms/javascript/guides/react/profiling

    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay
    Sentry.replayIntegration({
      // Unmask all text as the default is `true`.
      maskAllText: false,
      // Show media as the default is `true`.
      blockAllMedia: false,
      // Mask all inputs. The default is `true`, however, we are not sure if
      // setting `maskAllText` will change this.
      maskAllInputs: true,
      // Capture request and response details for XHR and fetch requests to match
      // the given URL. Here we are placing the possible API endpoints.
      networkDetailAllowUrls: [...Object.values(API_HOSTS), /.*/],
    }),
  ],

  //#region Performance Monitoring
  // https://docs.sentry.io/platforms/javascript/guides/react/performance
  tracesSampleRate: 1.0,
  // Returns an array of all API endpoints to attach tracing headers to.
  tracePropagationTargets: [...Object.values(API_HOSTS), /.*/],
  //#endregion

  //#region Session Replay Configuration
  // Defines the percentage of sessions to be recorded (0.0 = no sessions,
  // 1.0 = all sessions).
  replaysSessionSampleRate: 0,
  // Defined the percentage of sessions to record which have an error (0.0 = no
  // sessions, 1.0 = all sessions)
  replaysOnErrorSampleRate: 1.0,
  //#endregion
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    {/* TODO: KoltonG - Wondering if we can move up more items from the App.tsx
    file in here, things like error boundary that may be helpful even for logged
    out users? */}
    <AuthWrapper />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
