import { IonProgressBar } from "@ionic/react";
import { AlertBox, AlertColor, Colors } from "swing-components";

import { MarkdownContent } from "~components";
import { Step } from "~onboarding/components";
import {
  QuestionStatus,
  StepComputed,
  UnsyncedSubAttribute,
  type TaskComputed,
} from "~onboarding/utils";

/********** Component **********/
type TaskPageBaseProps = {
  taskComputed: Pick<TaskComputed, "name" | "progress" | "status" | "steps">;
  stepIndex: number;
  /** Show the loading state @default false */
  isLoading?: boolean;
  /** @default false */
  isReadOnly?: boolean;
  onAnswerChange: (attributes: UnsyncedSubAttribute) => void;
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  onClose: () => void;
};

export function TaskPageBase(props: TaskPageBaseProps) {
  const {
    taskComputed,
    stepIndex,
    isLoading,
    isReadOnly,
    onAnswerChange,
    onNextButtonClick,
    onPrevButtonClick,
    onClose,
  } = props;
  const { name, progress, status } = taskComputed;

  /***** Constants *****/
  const steps = taskComputed.steps;
  const step = steps[stepIndex];
  // Used to trigger the completion Alert
  const isLastStep = stepIndex === steps.length - 1 ? true : false;
  // Used to disable the "Next" button when not all questions are answered
  const isAllQuestionsAnswered = step.questions.every((question) => question.value !== undefined);

  return (
    <div
      style={{
        color: Colors.slate600,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: Colors.white200,
        flexGrow: 1,
        marginBottom: 40,
      }}
    >
      <TaskHeader name={name} progress={progress} status={status} onClose={onClose} />

      {/* Body */}
      <div
        style={{
          padding: "40px 32px 40px",
          width: "100%",
          backgroundColor: Colors.white200,
        }}
      >
        {/* Step Content Container */}
        <div
          style={{
            maxWidth: 660,
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          {/* Alerts */}
          <TaskAlert
            taskStatus={taskComputed.status}
            stepStatus={step.status}
            questions={step.questions}
            isLastStep={isLastStep}
          />

          {/* Step */}
          <Step
            stepComputed={step}
            onChange={onAnswerChange}
            isReadOnly={isReadOnly}
            isLastStep={isLastStep}
            nextButtonProps={{
              label: step.nextButtonText,
              onClick: onNextButtonClick,
              disabled: isAllQuestionsAnswered === false || isLoading,
              isLoading,
            }}
            // Only render the "Previous" button when not on the first step
            {...(stepIndex !== 0 && {
              prevButtonProps: {
                label: step.prevButtonText,
                onClick: onPrevButtonClick,
                // Do not allow the user to go to the previous step while the
                // we are waiting for a response from the API
                disabled: isLoading,
              },
            })}
          />
        </div>
      </div>
    </div>
  );
}

/********** Helper Components *********/
type TaskHeaderProps = {
  name: TaskComputed["name"];
  progress: TaskComputed["progress"];
  status: TaskComputed["status"];
  onClose: () => void;
};
function TaskHeader(props: TaskHeaderProps) {
  const { name, onClose, progress, status } = props;

  return (
    <header style={{ width: "100%", boxShadow: `0px 2px 4px ${Colors.shadow}`, zIndex: 1 }}>
      <div
        style={{
          padding: "24px 32px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>{name}</h3>
          <button
            style={{
              background: Colors.white200,
              color: Colors.blue500,
              padding: "8px 16px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
        <ProgressIndicator progress={progress} status={status} />
      </div>
    </header>
  );
}

type ProgressIndicatorProps = {
  progress: TaskComputed["progress"];
  status: TaskComputed["status"];
};
function ProgressIndicator(props: ProgressIndicatorProps) {
  const { progress, status } = props;
  const color = status === "COMPLETED" ? Colors.green500 : Colors.blue500;

  return (
    <IonProgressBar
      value={progress}
      style={{
        height: "4px",
        "--progress-background": color,
        "--background": Colors.slate200,
      }}
    />
  );
}

const defaultAlertText: Record<Exclude<QuestionStatus, "ACTION_REQUIRED">, string> = {
  UNDER_REVIEW: "You've successfully submitted this task. Your responses are being reviewed.",
  COMPLETED: "This task can be found in the **Completed** section.",
};

const defaultAlertStatusColors: Record<QuestionStatus, AlertColor> = {
  UNDER_REVIEW: "info",
  COMPLETED: "success",
  ACTION_REQUIRED: "warning",
};

/** Renders Alert content above the Task */
type TaskAlertProps = {
  taskStatus: TaskComputed["status"];
  stepStatus: StepComputed["status"];
  questions: StepComputed["questions"];
  isLastStep: boolean;
};
function TaskAlert(props: TaskAlertProps) {
  const { taskStatus, stepStatus, questions, isLastStep } = props;

  // Ignore if the Task does not have a status since there is nothing to alert
  if (taskStatus === undefined) return null;

  // Ignore if the Task status is different than the Step status since this means
  // that we are not on the Step which has caused the Task to have this status.
  if (taskStatus !== stepStatus) return null;

  // Lastly, ignore if Task and Step status are both `COMPLETED` but we are not
  // on the last Step since we only show the completed alert on the last step.
  if (taskStatus === "COMPLETED" && stepStatus === "COMPLETED" && isLastStep === false) return null;

  // Otherwise, show an alert

  // Find the question on the current Step which has the same status as the Task
  const alertedQuestion = questions.find((question) => question.status === taskStatus);
  // Find it's status text, or use the default text
  let alertMarkdownText: string;
  if (taskStatus === "UNDER_REVIEW" || taskStatus === "COMPLETED") {
    alertMarkdownText =
      alertedQuestion?.statusMarkdown?.[taskStatus] ?? defaultAlertText[taskStatus];
  } else {
    // @ts-expect-error - We've included a `console.error` to handle this case
    // which should not happen, however, it could that the API does not send
    // a `actionRequiredMarkdown` field for a Sub Attribute with the
    // `ACTION_REQUIRED` status.
    alertMarkdownText = alertedQuestion?.actionRequiredMarkdown;
    if (alertMarkdownText) {
      // eslint-disable-next-line no-console
      console.error(
        "Step Alert has been rendered with `undefined` content since no `actionRequiredMarkdown` content was found on the Sub Attribute.",
      );
    }
  }

  return (
    <AlertBox showIcon color={defaultAlertStatusColors[taskStatus]}>
      <MarkdownContent body={alertMarkdownText} />
    </AlertBox>
  );
}
