import { useQuery } from "@tanstack/react-query";
import HelloSign from "hellosign-embedded";
import { useEffect, useState } from "react";
import { Colors, Icon } from "swing-components";

import { Button } from "~components";
import { SignatureAnswerType } from "~onboarding/utils";
import { GET, HELLOSIGN_CLIENT_ID } from "~utils";

const helloSignClient = new HelloSign({ clientId: HELLOSIGN_CLIENT_ID });

type SignatureAnswerProps = Omit<SignatureAnswerType, "type"> & {
  /**
   * Function to be called when signing
   */
  onChange: (value: string) => void;
  /**
   * Disables the ability to open HelloSign
   */
  isReadOnly?: boolean;
};

/**
 * Set to true to enable test mode during development.
 * See https://developers.hellosign.com/api/reference/operation/signatureRequestCreateEmbedded/#!ct=application/json&path=test_mode&t=request
 */
const DEBUG = process.env.NODE_ENV === "development";

export function SignatureAnswer(props: SignatureAnswerProps) {
  const { document, onChange, value, label, isReadOnly = false } = props;
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);
  const [isAlreadySigned, setIsAlreadySigned] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  /***** Queries *****/
  const { refetch: getSignatureUrl } = useQuery({
    enabled: false,
    queryKey: ["signatureUrl"],
    queryFn: () =>
      GET("/api/sub/signature-url", {
        queryParams: {
          "test-mode": DEBUG,
          // Passing the attribute as a search param so that the API can
          // determine which document url to return.
          document,
        },
      }),
    select: (data) => data.data,
  });

  /***** Handlers *****/
  function handleSigned(signatureId?: string) {
    // Pass to the Question component the signatureId of the signed document
    onChange(signatureId || value);
  }

  function handleClick() {
    if (signatureUrl) {
      if (DEBUG) {
        helloSignClient.open(signatureUrl, {
          debug: true,
          skipDomainVerification: true,
          testMode: true,
        });
      } else {
        helloSignClient.open(signatureUrl);
      }
    }
  }

  /***** Hooks *****/
  useEffect(() => {
    // This is to appease the TypeScript Compiler, due to the 'sign' event passing
    // data to its callback, which is not used here.
    const onSign = () => {
      handleSigned();
    };
    getSignatureUrl()
      .then(({ data }) => {
        // This indicates that the document was already signed but the user
        // didn't click the Submit button to finalize this Step.
        // By calling onChange, the Step will be marked as complete.
        if (data?.signatureId) {
          handleSigned(data?.signatureId);
          setIsAlreadySigned(true);
        } else if (data?.signatureUrl) {
          setSignatureUrl(data?.signatureUrl);
          helloSignClient.on("sign", onSign);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      helloSignClient.off("sign", onSign);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isAlreadySigned && (
        <div
          style={{
            display: "flex",
            gap: "var(--spacing-sm)",
            alignItems: "center",
            color: Colors.green500,
          }}
        >
          <Icon name="Check Circle" color={Colors.green500} />
          <span style={{ fontWeight: "var(--swing-font-bold)" }}>You've finished signing!</span>
        </div>
      )}
      <Button onClick={handleClick} label={label} disabled={isReadOnly || isLoading} />
    </>
  );
}
