import { AxiosError } from "axios";
import { useState } from "react";

import {
  AlertBoxError,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  TextAreaInput,
} from "~components";
import { msg, ProfilePUTPayload } from "~utils";

type ModalContentAboutMeEditProps = {
  aboutMeText?: string;
  hasApiError?: AxiosError | null;
  onConfirm: (payload: ProfilePUTPayload) => void;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  title: string;
};

export function ModalContentAboutMeEdit(props: ModalContentAboutMeEditProps) {
  const { aboutMeText, hasApiError, onConfirm, onDismiss, title } = props;
  const [_aboutMeText, _setAboutMeText] = useState<string | undefined>(aboutMeText);
  const _originalAboutMeText = aboutMeText;
  const isSaveDisabled = !_aboutMeText || _aboutMeText.trim() === _originalAboutMeText?.trim();

  function handleOnChange(aboutMe: string) {
    _setAboutMeText(aboutMe === "" ? undefined : aboutMe);
  }

  function handleOnSave() {
    if (_aboutMeText?.trim()) {
      // trigger API mutation
      onConfirm({ about: _aboutMeText.trim() });
    }
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        {!!hasApiError && <AlertBoxError />}
        <TextAreaInput
          placeholder={msg("PROFILE_EDIT_ABOUT_ME_PLACEHOLDER")}
          value={_aboutMeText}
          onChange={(value: string) => handleOnChange(value)}
          label={msg("PROFILE_EDIT_ABOUT_ME_SUB_HEADER")}
        />
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isSaveDisabled,
          text: msg("LABEL_SAVE"),
          onClick: handleOnSave,
        }}
      />
    </ModalSubProfileEdit>
  );
}
