import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { AlertBox, Colors } from "swing-components";
import { phoneNumberFormatter, phoneNumberParser } from "swing-utils";

import {
  AlertBoxError,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  Toast,
  VerificationCodeInput,
} from "~components";
import {
  msg,
  PhoneVerificationError,
  PhoneVerificationPayload,
  PhoneVerificationSuccess,
  POST,
  useIsDesktop,
} from "~utils";

type ErrorType = "verification" | "request" | null;

type ModalContentPhoneNumberEnterCodeProps = {
  onStepChange: () => void;
  onDismiss: () => void;
  title: string;
  phoneNumber: string;
};

export function ModalContentPhoneNumberEnterCode(props: ModalContentPhoneNumberEnterCodeProps) {
  const { onStepChange, onDismiss, title, phoneNumber } = props;
  const isDesktop = useIsDesktop();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [localError, setLocalError] = useState<ErrorType>(null);
  const [showToast, setShowToast] = useState(false);

  const { mutate: requestCodeMutation } = useMutation<
    PhoneVerificationSuccess | PhoneVerificationError,
    AxiosError,
    PhoneVerificationPayload
  >({
    mutationFn: (payload) => POST("/api/sub/phone-verification", payload),
    onSuccess: () => setShowToast(true),
    onError: () => handleError("request"),
  });

  const { mutate: requestVerificationMutation } = useMutation<
    PhoneVerificationSuccess | PhoneVerificationError,
    AxiosError,
    PhoneVerificationPayload
  >({
    mutationFn: (payload) => POST("/api/sub/phone-verification", payload),
    onSuccess: onStepChange,
    onError: () => handleError("verification"),
  });

  const parsedPhoneNumber = phoneNumberParser({ phoneNumber });

  const formattedPhoneNumber =
    parsedPhoneNumber.length > 10
      ? phoneNumber
      : phoneNumberFormatter({
          phoneNumber: parsedPhoneNumber,
        }).value;

  function handleError(type: ErrorType) {
    setVerificationCode("");
    setLocalError(type);
  }

  function errorAlert() {
    return localError === "verification" ? (
      <AlertBox color="danger" showIcon>
        {msg("PHONE_VERIFICATION_ENTER_CODE_ALERT_ERROR")}
      </AlertBox>
    ) : localError === "request" ? (
      <AlertBoxError margin="0" />
    ) : (
      <AlertBox color="info" title={msg("PHONE_VERIFICATION_ENTER_CODE_ALERT_TITLE")} showIcon>
        {`${msg("PHONE_VERIFICATION_ENTER_CODE_ALERT_MSG")} ${formattedPhoneNumber}`}
      </AlertBox>
    );
  }

  function handleOnChange(code: string) {
    const numericCode = code.replace(/\D/g, "");
    if (numericCode.length <= 4) {
      setVerificationCode(numericCode);
    }
  }

  function handleRequestCode() {
    setLocalError(null);
    requestCodeMutation({ phoneNumber });
  }

  function handleCodeVerification() {
    if (showToast) setShowToast(false);
    requestVerificationMutation({
      phoneNumber,
      verificationCode,
    });
  }

  function handleDismiss() {
    if (showToast) setShowToast(false);
    onDismiss();
  }

  const isPrimaryButtonDisabled = verificationCode.length < 4;

  return (
    <>
      <ModalSubProfileEdit>
        <ModalSubProfileEditHeader onDismiss={handleDismiss} title={title} />
        <ModalSubProfileEditBody>
          {errorAlert()}
          <div style={{ margin: isDesktop ? "16px 0" : "8px 0" }}>
            <VerificationCodeInput
              value={verificationCode}
              onChange={handleOnChange}
              isDisabled={false}
            />
          </div>
          <div style={{ fontSize: "14px" }}>
            <span>{msg("PHONE_VERIFICATION_ENTER_CODE_MISSING_CODE")}</span>{" "}
            <span
              onClick={handleRequestCode}
              style={{ color: Colors.blue500, textDecoration: "underline", cursor: "pointer" }}
            >
              {msg("PHONE_VERIFICATION_ENTER_CODE_REQUEST_NEW_CODE")}
            </span>
          </div>
        </ModalSubProfileEditBody>
        <ModalSubProfileEditFooter
          buttonPrimary={{
            isDisabled: isPrimaryButtonDisabled,
            text: msg("LABEL_VERIFY"),
            onClick: () => handleCodeVerification(),
          }}
          buttonSecondary={{
            isDisabled: false,
            text: msg("LABEL_BACK"),
            onClick: () => handleDismiss(),
          }}
        />
      </ModalSubProfileEdit>
      <Toast
        isVisible={showToast}
        type="positive"
        onDismiss={() => setShowToast(false)}
        text={msg("PHONE_VERIFICATION_ENTER_CODE_TOAST_MSG")}
      />
    </>
  );
}
