import { DateTime } from "luxon";

import {
  AlertBoxError,
  FeedbackExperiences,
  FeedbackReasons,
  FeedbackSelections,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditHeader,
} from "~components";
import { msg, RequestData } from "~utils";

type ModalSchoolFeedbackSubmittedProps = {
  feedback?: RequestData["schoolFeedback"];
  onDismiss: () => void;
};

export function ModalSchoolFeedbackSubmitted(props: ModalSchoolFeedbackSubmittedProps) {
  const { feedback, onDismiss } = props;

  if (!feedback) {
    return <AlertBoxError />;
  }
  // Putting Other at the bottom if selected
  const reasonsWithOtherAtEnd = feedback.reasons?.slice();
  reasonsWithOtherAtEnd?.push(
    reasonsWithOtherAtEnd?.splice(reasonsWithOtherAtEnd?.indexOf("OTHER"), 1)[0],
  );

  const reasonsTitle =
    feedback?.experience === "POSITIVE"
      ? msg("SCHOOL_FEEDBACK_SUBMITTED_REASONS_LABEL_POSITIVE")
      : msg("SCHOOL_FEEDBACK_SUBMITTED_REASONS_LABEL_DEFAULT");

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title="Review" />
      <ModalSubProfileEditBody>
        <div>
          {msg("SCHOOL_FEEDBACK_SUBMITTED_ON_MSG", { date: formatSubmittedDate(feedback.date) })}
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "var(--swing-slate200)",
            margin: "8px 0",
          }}
        ></div>
        <div style={{ display: "grid" }}>
          <span style={{ fontWeight: "var(--swing-font-semibold" }}>
            {msg("SCHOOL_FEEDBACK_SUBMITTED_OVERALL_LABEL")}:
          </span>
          {feedback.experience && <span>{FeedbackExperiences[feedback.experience]}</span>}
        </div>
        <div style={{ margin: "16px 0" }}>
          <span style={{ fontWeight: "var(--swing-font-semibold" }}>{reasonsTitle}?</span>
          <ul style={{ margin: "0", paddingLeft: "24px" }}>
            {reasonsWithOtherAtEnd?.map((reason: FeedbackReasons) => {
              if (reason === "OTHER") {
                return (
                  <li key={reason}>
                    {FeedbackSelections[reason]}: {feedback.otherReason}
                  </li>
                );
              }
              return <li key={reason}>{FeedbackSelections[reason]}</li>;
            })}
          </ul>
        </div>
        <div style={{ fontWeight: "var(--swing-font-semibold" }}>
          {msg("SCHOOL_FEEDBACK_SUBMITTED_ADDITIONAL_INFO_TITLE")}:
        </div>
        {feedback.comments ? (
          <span>{feedback.comments}</span>
        ) : (
          <span style={{ fontStyle: "italic" }}>{msg("LABEL_NONE")}</span>
        )}
      </ModalSubProfileEditBody>
    </ModalSubProfileEdit>
  );
}

function formatSubmittedDate(date: string) {
  if (date.length != 0) {
    return `${DateTime.fromISO(date).monthShort} ${DateTime.fromISO(date).day}, ${DateTime.fromISO(date).year}`;
  } else return msg("SCHOOL_FEEDBACK_SUBMITTED_DEFAULT_DATE_MSG");
}
