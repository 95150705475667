import { Colors, Icon, type IconName } from "swing-components";

export type IconColor = "BLUE" | "YELLOW" | "PURPLE" | "GREEN" | "SLATE";

export type ProgressIconProps = {
  /**
   * Number between 0 and 100 that determines how much of the progress bar to fill
   */
  percentage: number;
  /**
   * icon name that maps to icons in swing components
   */
  icon: IconName;
  /**
   * Icon and progress bar color
   */
  color: IconColor;
};

export function ProgressIcon(props: ProgressIconProps) {
  const { percentage, icon, color } = props;
  return (
    <div
      style={{
        borderRadius: "50%",
        padding: "3px",
        background: `conic-gradient(from 180deg, ${PROGRESS_COLOR[color]} ${percentage}%, ${Colors.shadow} 0)`,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          border: `2px solid ${Colors.white200}`,
          backgroundColor: BACKGROUND_COLOR[color],
          fontSize: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* @ts-expect-error TODO: @KoltonG - TS is not happy with this since there are situations where there are some icons that don't support those colors. */}
        <Icon name={icon} color={PROGRESS_COLOR[color]} />
      </div>
    </div>
  );
}

const PROGRESS_COLOR: Record<IconColor, string> = {
  BLUE: Colors.blue500,
  YELLOW: Colors.yellow600,
  PURPLE: Colors.purple500,
  GREEN: Colors.green500,
  SLATE: Colors.slate400,
};

const BACKGROUND_COLOR: Record<IconColor, string> = {
  BLUE: Colors.slate200,
  YELLOW: Colors.yellow200,
  PURPLE: Colors.purple200,
  GREEN: Colors.green200,
  SLATE: Colors.slate200,
};
