import { CSSProperties } from "react";
import { ColorName, Colors } from "swing-components";

import { RadioInput } from "~components";
import type { RadioAnswerType } from "~onboarding/utils";

type RadioAnswerProps = Omit<RadioAnswerType, "type"> & {
  /**
   * The value of the answer. This is undefined when there is no answer.
   */
  value?: string;
  /**
   * When answering the Question, pass the `value` to the parent.
   */
  onChange: (value: string) => void;
  /**
   * Disables the editability of the answer.
   */
  isReadOnly?: boolean;

  /**
   * Temporarily adding to allow design to play with gap sizing in storybook via params.
   * @default 4
   */
  rowGap?: number;
};

const styles = {
  container: ({ rowGap = 4 }: Pick<RadioAnswerProps, "rowGap">) =>
    ({
      display: "flex",
      flexDirection: "column",
      gap: rowGap,
    }) as CSSProperties,
  isCorrect: ({ color }: { color: ColorName }) =>
    ({
      transform: "translate(calc(24px + 20px + 1.5rem), -16px)",
      display: "inline-block",
      fontSize: "12px",
      color: `var(--swing-${color})`,
    }) as CSSProperties,
};

export function RadioAnswer(props: RadioAnswerProps) {
  const { value, onChange, isReadOnly, rowGap = 16, options } = props;
  return (
    <div style={styles.container({ rowGap })}>
      {options.map((option) => {
        const isCorrectExists = option.isCorrect !== undefined;

        return (
          <div key={option.value} style={{ borderBottom: `1px solid ${Colors.slate200}` }}>
            <RadioInput
              isDisabled={isReadOnly}
              isSelected={value === option.value}
              onChange={onChange}
              label={option.label}
              value={option.value}
            />
            {/* when isCorrect is defined AND this option is selected show correct text */}
            {isCorrectExists && option.isCorrect === true && value === option.value && (
              <span style={styles.isCorrect({ color: "green500" })}>Correct!</span>
            )}
            {/* when isCorrect is defined AND this option is NOT selected show incorrect text */}
            {isCorrectExists && option.isCorrect === false && value === option.value && (
              <span style={styles.isCorrect({ color: "red500" })}>
                That's not right, try again!
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}
