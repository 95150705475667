import { Settings } from "luxon";

/** Defaults Luxon to throw when using DateTime.parse() on an invalid date. */
Settings.throwOnInvalid = true;

/**
 * Update the TS interface for Luxon so that the type of DateTime is always
 * valid due to throwing on invalid dates.
 */
declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}
