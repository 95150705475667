import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import {
  ModalContentPhoneNumberEnterCode,
  ModalContentPhoneNumberRequestCode,
  ModalContentPhoneNumberSuccess,
} from "~components";
import { msg } from "~utils";

type ModalContentStep = "request-code" | "enter-code" | "success";

type ModalContentPhoneNumberEditProps = {
  isPhoneNumberVerified?: boolean;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  phoneNumber?: string;
  step?: ModalContentStep;
};

export function ModalContentPhoneNumberEdit(props: ModalContentPhoneNumberEditProps) {
  const { isPhoneNumberVerified = false, onDismiss, step, phoneNumber } = props;
  const [_step, _setStep] = useState<ModalContentStep>(step ?? "request-code");
  const [_phoneNumber, _setPhoneNumber] = useState<string>(phoneNumber ?? "");
  const queryClient = useQueryClient();

  const TITLE = isPhoneNumberVerified
    ? msg("PROFILE_PHONE_NUMBER_UPDATE_TITLE")
    : msg("PROFILE_PHONE_NUMBER_VERIFY_TITLE");

  const handleSuccess = async () => {
    queryClient.invalidateQueries({ queryKey: ["fetchProfile"] });
    onDismiss();
  };

  switch (_step) {
    case "request-code":
      return (
        <ModalContentPhoneNumberRequestCode
          isPhoneNumberVerified={isPhoneNumberVerified}
          phoneNumber={_phoneNumber}
          originalPhoneNumber={phoneNumber ?? ""}
          onDismiss={onDismiss}
          title={TITLE}
          onStepChange={() => _setStep("enter-code")}
          onPhoneNumberChange={_setPhoneNumber}
        />
      );
    case "enter-code":
      return (
        <ModalContentPhoneNumberEnterCode
          onDismiss={onDismiss}
          title={TITLE}
          onStepChange={() => _setStep("success")}
          phoneNumber={_phoneNumber}
        />
      );
    case "success":
      return <ModalContentPhoneNumberSuccess onDismiss={handleSuccess} title={TITLE} />;
    default:
      throw new Error(msg("PROFILE_PHONE_NUMBER_STEP_ERROR_MESSAGE", _step));
  }
}
