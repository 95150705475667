/**
 * Assertion function for an array of strings.
 * @param value Any
 */
export function isArrayOfString(value: unknown): asserts value is string[] {
  if (!Array.isArray(value) || (value.length > 0 && value.some((x) => typeof x !== "string")))
    throw new Error("Not an array of strings");
}

/**
 * Returns true if all elements of the passed array are Date instances.
 * Returns false for an empty array.
 *
 * @param {unknown[]} value
 * @returns {boolean}
 */
export function isArrayOfDate(value: unknown[]): value is Date[] {
  return value.length > 0 && value.every((i) => i instanceof Date);
}

/**
 * Given an object type, return a union of the possible values of that type.
 *
 * @example
 * type Obj = {
 *  a: 1,
 *  b: "two",
 *  c: { three: 3 },
 * };
 *
 * type Values = ValuesOf<Obj>; // => 1 | "two" | { three: 3 }
 */
export type ValuesOf<TObj extends Record<string, unknown>> = TObj[keyof TObj];

/**
 * Given an object (TObj), return every key as optional. This can handle nested
 * objects and arrays.
 *
 * @example
 * type Obj = {
 *   a: number,
 *   b: {
 *     c: number,
 *   }
 *  d: [
 *    { e: string }
 *  ]
 * }
 *
 * type Result = DeepPartial<Obj>; // => { a?: number, b?: { c?: number }, d?: [{ e?: string }] }
 */
export type DeepPartial<TObj extends Record<string, unknown>> = {
  [Key in keyof TObj]?: TObj[Key] extends Record<string, unknown>
    ? DeepPartial<TObj[Key]>
    : TObj[Key] extends Array<Record<string, unknown>>
      ? Array<DeepPartial<TObj[Key][number]>>
      : TObj[Key];
};

/**
 * Given an object (TObj), replace the given key (Key) with the given type (NewType).
 *
 * @example
 * type Obj = { a: number, b: string };
 *
 * type Result = Replace<Obj, "a", string>; // => { a: string, b: string }
 */
export type Replace<TObj extends Record<string, unknown>, Key extends keyof TObj, NewType> = Omit<
  TObj,
  Key
> & {
  [P in Key]: NewType;
};

/**
 * Given an object (TObj), convert the key (TKey) to be required.
 *
 * @example
 * type Obj = { a?: number, b?: string };
 *
 * type Result = Require<Obj, "a">; // => { a: string, b?: string }
 */
export type Require<TObj extends object, TKey extends keyof TObj> = Omit<TObj, TKey> & {
  [Key in TKey]-?: TObj[Key];
};
