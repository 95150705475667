import { jsx as t } from "react/jsx-runtime";
import o from "../../../../string-table.js";
import "@ionic/react";
import "../../../../../AuthProvider-DO6tcIND.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as e } from "../Section/Section.js";
function d(n) {
  const {
    about: r,
    editable: i
  } = n;
  return /* @__PURE__ */ t(e, {
    title: o("SUB_PROFILE_ABOUT_HEADER"),
    onEditClick: i != null && i.isEditable ? i.onEditAboutMe : void 0,
    children: /* @__PURE__ */ t("div", {
      children: /* @__PURE__ */ t("p", {
        style: {
          marginBottom: 0,
          lineHeight: "var(--swing-leading-5)"
        },
        children: r ? /* @__PURE__ */ t("span", {
          children: r
        }) : /* @__PURE__ */ t("span", {
          style: {
            fontStyle: "italic"
          },
          children: i != null && i.isEditable ? `${o("SUB_PROFILE_ABOUT_EDIT_EMPTY")}.` : o("SUB_PROFILE_NO_INFORMATION")
        })
      })
    })
  });
}
export {
  d as AboutMe
};
