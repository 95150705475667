/**
 * Re-useable helpers that utilize platform features via plugins.
 * These plugins currently need to reside in sub-app's package.json
 * in order to be correctly included as mobile artifacts.
 *
 * For example, removing @capacitor/browser from package.json
 * and moving the @capacitor/browser import and openLinkInApp
 * function outside of sub-app resulted in the inability for
 * Ionic to include@capacitor/browser as a dependency.
 * Although the mobile app would build, the functionality
 * would not work since the plugin couldn't be found.
 */
import { Browser } from "@capacitor/browser";

export async function openLinkInApp(fullUrl: string) {
  await Browser.open({ url: fullUrl });
}
