import { CSSProperties, useState } from "react";
import { Colors } from "swing-components";

import { colors } from "~utils";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  /**
   * TODO: Style the ::placeholder pseudo-element, mainly colors.gray500. Need a css-in-js solution first.
   */
  label: (isFocused: boolean) =>
    ({
      fontWeight: "600",
      color: isFocused ? Colors.blue500 : Colors.black,
      padding: "0 16px",
    }) as CSSProperties,
  inputWrapper: ({
    isDisabled,
    isFocused,
  }: Pick<TextInputProps, "isDisabled"> & { isFocused: boolean }) =>
    ({
      padding: "4px 14px 7px 0",
      borderBottom: `1px solid ${isDisabled ? "transparent" : isFocused ? Colors.blue500 : colors.gray100}`,
    }) as CSSProperties,
  nativeInput: ({ isDisabled }: Pick<TextInputProps, "isDisabled">) =>
    ({
      padding: "0 16px",
      border: 0,
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      color: isDisabled ? colors.inactiveGray200 : Colors.black,
      background: "transparent",
      outline: "none",
      appearance: "none",
      touchAction: "manipulation",
    }) as CSSProperties,
};

type TextInputProps = {
  type?: "text" | "tel" | "email" | "password" | "number";
  label?: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  pattern?: string;
  maxLength?: number;
  inputMode?: "text" | "numeric";
};

export function TextInput(props: TextInputProps) {
  const {
    type = "text",
    value,
    label,
    placeholder,
    onChange,
    isDisabled,
    pattern,
    maxLength,
    inputMode,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  /***** Constants *****/
  // Defaulting to am empty string to remove `controlled` input error.
  const value_ = value || "";

  /***** Render *****/
  return (
    <label style={styles.container}>
      {label && <div style={styles.label(isFocused)}>{label}</div>}
      <div style={styles.inputWrapper({ isDisabled, isFocused })}>
        <input
          onChange={(e) => {
            if (!isDisabled) onChange(e.target.value);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={styles.nativeInput({ isDisabled })}
          value={value_}
          type={type}
          disabled={isDisabled}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          placeholder={placeholder}
          spellCheck={false}
          aria-label={label}
          pattern={pattern}
          maxLength={maxLength}
          inputMode={inputMode}
        />
      </div>
    </label>
  );
}
