/**
 *  This file was created before the existence of swing-components/Colors.ts
 * These last remaining colors are not part of the design system but are still being referenced in the sub-app in a few
 * places. The goal is for them to be replaced with colors from the design system in the future.
 */
export const colors = {
  gray100: "var(--swing-gray-100)",
  gray500: "#92949C",
  inactiveGray200: "var(--ion-color-medium)",
};

export const fonts = {};
