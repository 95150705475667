import { CSSProperties } from "react";
import { AlertBox } from "swing-components";
import { generateSwingSupportURL } from "swing-utils";

import { msg } from "~utils";

type AlertBoxErrorProps = {
  margin?: CSSProperties["margin"];
};

export const AlertBoxError = (props: AlertBoxErrorProps) => {
  const { margin } = props;
  return (
    <div style={{ margin: margin ? margin : "16px 0px" }}>
      <AlertBox color="danger" showIcon title={msg("LABEL_SOMETHING_WENT_WRONG")}>
        <span>
          {msg("LABEL_CONTACT_SUPPORT_1")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={generateSwingSupportURL(msg("SWING_SUPPORT_LINK_GENERAL_REQUEST"))}
          >
            {msg("LABEL_CONTACT_SUPPORT")}
          </a>{" "}
          {msg("LABEL_CONTACT_SUPPORT_2")}
        </span>
      </AlertBox>
    </div>
  );
};
