import { AxiosError } from "axios";
import { http, HttpResponse } from "msw";

import {
  myRequestsWithNoDataResponse,
  pastRequestsWithDataResponse,
  paymentDataResponse,
  pendingConfirmRequestWithDataResponse,
  profileDataPartialResponse,
  profileDataResponse,
  referralDataResponse,
  requestsWithDataResponse,
  requestsWithNoDataResponse,
  schoolFeedback,
  subLocationDataWithoutSelections,
  subLocationDataWithSelections,
  subLocationErrorInvalidZip,
  subLocationErrorOutOfState,
  upcomingRequestsWithDataResponse,
} from "~mock";
import { ProfileData, RequestData, SWING_API_HOST } from "~utils";

type ResponseDataAmount = "empty" | "complete";

// MSW no longer supports rest in favor of http
// See migration guide for more details: https://mswjs.io/docs/migrations/1.x-to-2.x/
export const mockedGetRequests = (dataAmount?: ResponseDataAmount) =>
  http.get(`${SWING_API_HOST}/api/sub/request/openings*`, () => {
    if (!dataAmount || dataAmount === "complete") {
      return HttpResponse.json(requestsWithDataResponse);
    }

    if (dataAmount === "empty") {
      return HttpResponse.json(requestsWithNoDataResponse);
    }
  });

export const mockedFailedGetRequests = http.get(
  `${SWING_API_HOST}/api/sub/request/openings*`,
  () => {
    return new HttpResponse(null, { status: 500 });
  },
);

export const mockedGetSubRequestWithData = http.get(`${SWING_API_HOST}/api/sub/request/*`, () => {
  return HttpResponse.json(requestsWithDataResponse);
});

export const mockedGetSubRequestsWithData = (requestId: string, responseData: RequestData) => {
  return http.get(`${SWING_API_HOST}/api/sub/request/${requestId}`, () => {
    return HttpResponse.json({ data: responseData });
  });
};

export const mockedGetPendingConfirmRequests = (dataAmount?: ResponseDataAmount) =>
  http.get(`${SWING_API_HOST}/api/sub/request/pending-confirm`, () => {
    if (!dataAmount || dataAmount === "complete") {
      return HttpResponse.json(pendingConfirmRequestWithDataResponse);
    }

    if (dataAmount === "empty") {
      return HttpResponse.json(requestsWithNoDataResponse);
    }
  });

export const mockedGetRequestError = http.get(`${SWING_API_HOST}/api/sub/request/*`, () => {
  return new HttpResponse(null, { status: 500 });
});

export const mockedUpcomingRequests = (dataAmount?: ResponseDataAmount) =>
  http.get(`${SWING_API_HOST}/api/sub/request/upcoming`, () => {
    if (!dataAmount || dataAmount === "complete") {
      return HttpResponse.json(upcomingRequestsWithDataResponse);
    }

    if (dataAmount === "empty") {
      return HttpResponse.json(myRequestsWithNoDataResponse);
    }
  });

export const mockedPastRequests = (dataAmount?: ResponseDataAmount) =>
  http.get(`${SWING_API_HOST}/api/sub/request/past`, () => {
    if (!dataAmount || dataAmount === "complete") {
      return HttpResponse.json(pastRequestsWithDataResponse);
    }

    if (dataAmount === "empty") {
      return HttpResponse.json(myRequestsWithNoDataResponse);
    }
  });

export const mockedPostSchoolFeedback = (requestId: string, isError?: boolean) =>
  http.post(`${SWING_API_HOST}/api/sub/request/${requestId}/feedback`, () => {
    if (isError) {
      return new HttpResponse(null, { status: 500 });
    }
    return HttpResponse.json(schoolFeedback);
  });

export type MockSubLocationZipCode = "12345" | "90210" | "30721" | "95139";

export const mockedGetSubLocation = (isGenericError?: boolean) =>
  http.get(`${SWING_API_HOST}/api/sub/location`, ({ request }) => {
    const url = new URL(request.url);
    const zipCode = url.searchParams.get("zipCode") as MockSubLocationZipCode | null;

    if (isGenericError) {
      return new HttpResponse(null, { status: 500 });
    }

    if (zipCode === "12345") {
      return HttpResponse.json({ ...subLocationErrorInvalidZip }, { status: 400 });
    }

    if (zipCode === "30721") {
      return HttpResponse.json({ ...subLocationErrorOutOfState }, { status: 400 });
    }

    if (zipCode === "90210") {
      return HttpResponse.json(subLocationDataWithoutSelections);
    }

    // default response when no zipCode is provided
    return HttpResponse.json(subLocationDataWithSelections);
  });

export const mockedPostSubLocation = (isError?: boolean) =>
  http.post(`${SWING_API_HOST}/api/sub/location`, () => {
    if (isError) {
      return new HttpResponse(null, { status: 500 });
    }
    return HttpResponse.json(subLocationDataWithSelections);
  });

export const mockedGetPayment = (isSuccess?: boolean) =>
  http.get(`${SWING_API_HOST}/api/sub/me/payment`, () => {
    if (isSuccess) {
      return HttpResponse.json(paymentDataResponse);
    }

    return new HttpResponse(null, { status: 500 });
  });

type mockedGetProfileParams =
  | {
      isError: true;
    }
  | {
      isError: false;
      dataAmount: "full";
    }
  | {
      isError: false;
      dataAmount: "partial";
      dataOverride?: ProfileData;
    };

export const mockedGetProfile = (params: mockedGetProfileParams) =>
  http.get(`${SWING_API_HOST}/api/sub/profile`, () => {
    if (params.isError) {
      return new HttpResponse(null, { status: 500 });
    }
    if (params.dataAmount === "partial") {
      const partialProfileResponse = {
        data: {
          ...profileDataPartialResponse.data,
          ...params.dataOverride,
        },
      };
      return HttpResponse.json(partialProfileResponse);
    }
    return HttpResponse.json(profileDataResponse);
  });

export const mockedPutSubProfile = (isError?: boolean) =>
  http.put(`${SWING_API_HOST}/api/sub/profile`, () => {
    if (isError) {
      return new HttpResponse(null, { status: 500 });
    }
    return HttpResponse.json(profileDataResponse);
  });

// Currently can only delete education or work experience from sub profile
export const mockedDeleteSubProfileItem = (
  category: "education" | "experience",
  itemId: number,
  isError?: boolean,
) =>
  http.delete(`${SWING_API_HOST}/api/sub/profile`, () => {
    if (isError) {
      return new HttpResponse(null, { status: 500 });
    }
    const updatedProfileData = {
      data: {
        ...profileDataResponse.data,
        [category]: profileDataResponse?.data?.[category]?.filter((item) => item.id !== itemId),
      },
    };
    return HttpResponse.json(updatedProfileData);
  });

export const createMockAxiosError = (message?: string): AxiosError => {
  return {
    config: {},
    name: "AxiosError",
    message: message ?? "An error occurred",
    isAxiosError: true,
    toJSON: () => ({}),
    response: {
      status: 500,
      statusText: "Internal Server Error",
      data: { error: "Internal server error" },
    },
  } as AxiosError;
};

export const mockedPostPhoneVerification = (isError?: boolean) =>
  http.post(`${SWING_API_HOST}/api/sub/phone-verification`, () => {
    if (isError) {
      return new HttpResponse(
        JSON.stringify({
          error: "api-error",
          detail: {
            error: "incorrect-code",
          },
        }),
        { status: 400 },
      );
    }

    return HttpResponse.json("OK");
  });

export const mockedGetSkillBuilderError = http.get(`${SWING_API_HOST}/api/sub/skillbuilder`, () => {
  return new HttpResponse(null, { status: 500 });
});

export const mockedGetSkillBuilder = http.get(`${SWING_API_HOST}/api/sub/skillbuilder`, () => {
  return HttpResponse.json({ data: [] });
});

export const mockedGetReferral = (isError?: boolean) =>
  http.get(`${SWING_API_HOST}/api/sub/referral`, () => {
    if (isError) {
      return new HttpResponse(null, { status: 500 });
    }
    return HttpResponse.json(referralDataResponse);
  });
