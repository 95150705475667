/**
 * Given a string or Date object, return a formatted date string in the format "Month Day".
 */
export function formatAsMonthAndDay(_date: Date | string) {
  let date = _date;
  if (typeof date === "string") date = new Date(date);

  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString();

  return `${month} ${day}`;
}
