import { ReactNode } from "react";

import styles from "./ContentSingleColumn.module.css";

type ContentSingleColumnProps = {
  children: ReactNode;
};

// Should be used as the direct child component of the `Page` component
export function ContentSingleColumn(props: ContentSingleColumnProps) {
  const { children } = props;

  return <div className={styles["content-single-column"]}>{children}</div>;
}
