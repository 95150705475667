import { ReactNode } from "react";
import { ExtractRouteParams, matchPath, RedirectProps, Switch } from "react-router";
import { generatePath, Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { useAuth } from "~components";
import { MOCK_ONBOARDING_REGION } from "~mock";
/********** Pages **********/
import { MorePage, TasksPage } from "./[region]";
import { TaskPage } from "./[region]/[task]/[step]";
import { RejectionPage } from "./[region]/rejection";

/********** Constants ***********/
export const ONBOARDING_ROUTES = {
  tasks: "/onboarding/:region",
  rejection: "/onboarding/:region/rejection",
  task: "/onboarding/:region/:task/:step?",
  more: "/onboarding/:region/more",
} as const;

/********** Component ***********/
/**
 * Entry point for the onboarding experience. This components responsibility is
 * to get the sub details and render the appropriate route.
 */
export function Onboarding() {
  /***** Hooks *****/
  const { userInfo } = useAuth();

  /***** Render *****/
  return (
    <Switch>
      <Route exact path={ONBOARDING_ROUTES["more"]}>
        <MorePage />
      </Route>

      <OnboardingRoute
        exact
        path={ONBOARDING_ROUTES["tasks"]}
        rules={[
          {
            // Render this route when the sub IS NOT REJECTED
            rule: () => userInfo.onboardingStatus !== "REJECTED",
            // Otherwise redirect to the rejection page using the region.
            redirectProps: ({ match }) => ({
              to: generatePath(ONBOARDING_ROUTES["rejection"], {
                region: match.params.region,
              }),
            }),
          },
          {
            // Render this route when the `region` matches the sub `onboardingIdentifier`
            rule: (routeProps) => routeProps.match?.params?.region === MOCK_ONBOARDING_REGION,
            // Otherwise redirect to this page using the correct region.
            redirectProps: () => ({
              to: generatePath(ONBOARDING_ROUTES["tasks"], {
                region: MOCK_ONBOARDING_REGION,
              }),
            }),
          },
        ]}
        render={(routeProps) => <TasksPage {...routeProps} />}
      />

      <OnboardingRoute
        exact
        path={ONBOARDING_ROUTES["rejection"]}
        rules={[
          {
            // Render this route when the sub IS REJECTED
            rule: () => userInfo.onboardingStatus === "REJECTED",
            // Otherwise, redirect to the tasks page using the correct region.
            redirectProps: ({ match }) => ({
              to: generatePath(ONBOARDING_ROUTES["tasks"], {
                region: match.params.region,
              }),
            }),
          },
          {
            // Render this route when the `region` matches the sub onboardingIdentifier
            rule: (routeProps) => routeProps.match?.params?.region === MOCK_ONBOARDING_REGION,
            // Otherwise, redirect to this page using the correct region.
            redirectProps: () => ({
              to: generatePath(ONBOARDING_ROUTES["rejection"], {
                region: MOCK_ONBOARDING_REGION,
              }),
            }),
          },
        ]}
        render={(routeProps) => <RejectionPage {...routeProps} />}
      />

      <OnboardingRoute
        exact
        path={ONBOARDING_ROUTES["task"]}
        rules={[
          {
            // Render this route when the sub IS NOT REJECTED
            rule: () => userInfo.onboardingStatus !== "REJECTED",
            // Otherwise, redirect to the rejection page using the correct region.
            redirectProps: ({ match }) => ({
              to: generatePath(ONBOARDING_ROUTES["rejection"], {
                region: match.params.region,
              }),
            }),
          },
          {
            // Render this route when the `region` matches the sub onboardingIdentifier
            // FIXME: @KoltonG - Once we have `region` available on the user
            // object, use this.
            rule: (routeProps) => routeProps.match?.params?.region === MOCK_ONBOARDING_REGION,
            // Otherwise, redirect to this page using the correct region.
            redirectProps: ({ match }) => ({
              to: generatePath(ONBOARDING_ROUTES["task"], {
                region: MOCK_ONBOARDING_REGION,
                task: match.params.task,
              }),
            }),
          },
        ]}
        render={(routeProps) => <TaskPage {...routeProps} />}
      />

      {/* FALLBACK */}
      <Redirect
        // Generate the appropriate onboarding url for the sub.
        to={generatePath(ONBOARDING_ROUTES["tasks"], {
          region: MOCK_ONBOARDING_REGION,
        })}
      />
    </Switch>
  );
}

/********** Helper Functions **********/
type OnboardingRouteProps<Path extends string> = RouteProps<Path> & {
  // @ts-expect-error - @KoltonG having a bit of difficulty making this one happy
  render: (props: RouteComponentProps<ExtractRouteParams<Path, string>>) => ReactNode;
  rules?: Array<{
    // @ts-expect-error - @KoltonG having a bit of difficulty making this one happy
    rule: (routeProps: RouteComponentProps<ExtractRouteParams<Path, string>>) => boolean;
    redirectProps: (
      // @ts-expect-error - @KoltonG having a bit of difficulty making this one happy
      routeProps: RouteComponentProps<ExtractRouteParams<Path, string>>,
    ) => RedirectProps;
  }>;
};

/**
 * Call the `render` prop when all `rules` are met, otherwise calls the
 * redirects using teh `redirectProps` function.
 */
function OnboardingRoute<Path extends string>(props: OnboardingRouteProps<Path>) {
  const { rules, render: renderComponent, ...routeProps } = props;

  /***** Render ******/
  return (
    <Route
      {...routeProps}
      render={(_props) => {
        // NOTE: Upon redirect, the props.match.params seems to not be
        // calculating the params which causes a redirect loop. This const will
        // recompute the match params on each render to make sure it's the
        // latest.
        // @ts-expect-error - @KoltonG having a bit of difficulty making this
        // one happy
        const match = matchPath<ExtractRouteParams<Path, string>>(
          // @ts-expect-error - @KoltonG this field which is only defined at
          // runtime is the most consistent way to get the path. This is for
          // some reason not defined which is causing the type error.
          routeProps.computedMatch.url,
          routeProps,
        )!;
        const props = { ..._props, match };

        // NOTE: We are not calling `children` due to the above issue. We
        // noticed that when rendering `children` component, that components
        // hooks would not be capturing the latest `match.params` which would
        // cause issues. This approach allow us to pass in the latest
        // `match.params`.
        if (!rules) return renderComponent(props);

        // Iterate over all rules and redirect if it fails.
        for (const { rule, redirectProps } of rules) {
          if (!rule(props)) return <Redirect {...redirectProps(props)} />;
        }

        // When all rules pass, render the children
        // NOTE: We are not calling `children` due to the above issue. We noticed
        // that when rendering `children` component, that components hooks would
        // not be capturing the latest `match.params` which would cause issues.
        // This approach allow us to pass in the latest `match.params`.
        return renderComponent(props);
      }}
    />
  );
}
