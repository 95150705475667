import { Colors, Icon } from "swing-components";

import { MarkdownContent } from "~components";
import type { LinkAnswerType } from "~onboarding/utils";

type LinkAnswerProps = Omit<LinkAnswerType, "type"> & {
  /**
   * The function to be called when the link is clicked
   */
  onChange: (value: string) => void;
  /**
   * Disables the editability of the answer.
   */
  isReadOnly?: boolean;
};

export function LinkAnswer(props: LinkAnswerProps) {
  const { markdown, link, value, onChange, isReadOnly } = props;
  const { label, url } = link;

  const color = isReadOnly ? Colors.slate400 : Colors.blue500;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      {markdown && <MarkdownContent body={markdown} />}
      <a
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          fontWeight: 600,
          gap: "8px",
          color: color,
          width: "fit-content",
          pointerEvents: isReadOnly ? "none" : "auto",
        }}
        target="_blank"
        rel="noreferrer"
        href={url}
        onClick={() => {
          onChange(value);
          return true;
        }}
      >
        <Icon name="External Link" color={color} />
        {label}
      </a>
    </div>
  );
}
