import { useEffect } from "react";

import { MarkdownContent } from "~components";
import type { ContentAnswerType } from "~onboarding/utils";

type ContentAnswerProps = ContentAnswerType & {
  /**
   * When rendering the content, pass the `value` defined in the ContentAnswer type to the parent.
   */
  onChange: (value: string) => void;
  /**
   * When `true`, does not call `onChange` when the component mounts.
   * @default: false
   */
  isReadOnly?: boolean;
};

export function ContentAnswer(props: ContentAnswerProps) {
  const { markdown, onChange, isReadOnly, value } = props;

  useEffect(() => {
    if (isReadOnly === false) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MarkdownContent data-testid="markdown" body={markdown} style={{ width: "100%" }} />;
}
