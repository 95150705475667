import { jsx as n, jsxs as s } from "react/jsx-runtime";
import { IonItem as _ } from "@ionic/react";
import { I as m } from "../../../Icon-CcIP5yi-.js";
import "../../../AuthProvider-DO6tcIND.js";
import "react";
import "../../../react-paginate-Lh5zQzpz.js";
import '../../../css/DataItemView.css';const g = "_icon_7f3w7_54", f = "_label_7f3w7_58", h = "_children_7f3w7_70", a = {
  "data-item": "_data-item_7f3w7_1",
  "data-grid-icon": "_data-grid-icon_7f3w7_6",
  "data-grid-icon-stacked": "_data-grid-icon-stacked_7f3w7_22",
  "data-grid-no-icon": "_data-grid-no-icon_7f3w7_30",
  "data-grid-no-icon-stacked": "_data-grid-no-icon-stacked_7f3w7_46",
  icon: g,
  label: f,
  children: h
};
function u(d) {
  const {
    icon: i,
    iconColor: c,
    label: t,
    lines: e,
    stackLayout: o,
    children: r,
    style: l
  } = d;
  return /* @__PURE__ */ n(_, {
    lines: e || "none",
    className: `${a["data-item"]} ion-no-padding`,
    style: {
      ...l
    },
    children: /* @__PURE__ */ s("div", {
      className: `${i ? a["data-grid-icon"] : a["data-grid-no-icon"]} ${o && i ? a["data-grid-icon-stacked"] : o && !i ? a["data-grid-no-icon-stacked"] : ""}`,
      children: [i ? /* @__PURE__ */ n("div", {
        className: `${a.icon}`,
        children: /* @__PURE__ */ n(m, {
          name: i,
          color: c
        })
      }) : null, /* @__PURE__ */ n("div", {
        className: a.label,
        children: t
      }), /* @__PURE__ */ n("div", {
        className: a.children,
        children: r
      })]
    })
  });
}
export {
  u as DataItemView
};
