import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useHistory } from "react-router";
import { IconBlock } from "swing-components";

import { AlertBoxError, ButtonScoreWrapper, ContentSingleColumn } from "~components";
import { createEducationEditUrl, createEducationNewUrl } from "~pages";
import { GET, msg, ProfileData } from "~utils";
import { ScorePage } from "../../ScoreTemplates";
import styles from "./EducationLanding.module.css";

export function EducationLanding() {
  const { data, error, isLoading } = useQuery<{ data: ProfileData }, AxiosError>({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
  });

  return (
    <EducationLandingView education={data?.data.education} error={error} isLoading={isLoading} />
  );
}

export type Education = {
  id: number;
  name: string;
};

type EducationLandingViewProps = {
  education?: Education[];
  error?: AxiosError | null;
  isLoading?: boolean;
};

function EducationLandingView(props: EducationLandingViewProps) {
  const { education = [], error, isLoading } = props;

  /* Hooks */
  const history = useHistory();

  return (
    <ScorePage title={msg("PAGE_TITLE_EDUCATION_LANDING")} isLoading={isLoading} hasBack>
      {error ? (
        <AlertBoxError margin="0px" />
      ) : (
        <ContentSingleColumn>
          <div className={styles.educationLandingContainer}>
            <span>{msg("EDUCATION_LANDING_SUB_HEADER")}</span>
            {education.length > 0 && (
              <div className={styles.educationContent}>
                {education.map((edu, idx) => {
                  const { id, name } = edu;
                  const isLast = idx === education.length - 1;
                  return (
                    <div key={id}>
                      <IconBlock
                        content={<span>{name}</span>}
                        onClick={() => history.push(createEducationEditUrl(`${id}`))}
                        hasDivider={!isLast}
                      ></IconBlock>
                    </div>
                  );
                })}
              </div>
            )}
            {(!education || education.length < 3) && (
              <ButtonScoreWrapper
                buttonPrimary={{
                  label: `+ ${msg("PAGE_TITLE_EDUCATION_EDIT")}`,
                  expand: "block",
                  onClick: () => history.push(createEducationNewUrl()),
                }}
              />
            )}
          </div>
        </ContentSingleColumn>
      )}
    </ScorePage>
  );
}
