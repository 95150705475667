import { IonPage, IonRouterOutlet } from "@ionic/react";
import { generatePath, Redirect, Route, Switch } from "react-router";
import { FEATURE_FLAGS } from "src/utils/feature-flags";

import { LoadingInterstitial, useAuth } from "~components";
import {
  EducationLanding,
  EducationNewEdit,
  Help,
  LocationSettings,
  More,
  MyEarnings,
  MyRequests,
  Payment,
  PaymentSetup,
  Profile,
  Referral,
  RequestDetails,
  Requests,
  SchoolProfile,
  SkillBuilderTasksPage,
  WorkExperienceLanding,
  WorkExperienceNewEdit,
} from "~pages";
import { ValuesOf } from "~utils";
import { Onboarding } from "../onboarding";
import { SkillBuilderTaskPage } from "./more/skillBuilder/[version]/[task]/[step]";

/********* Constants **********/
export const AUTH_URLS = {
  root: "/",
  requests: "/requests",
  request: "/requests/:requestId",
  myRequests: "/my-requests",
  myRequestsDetails: "/my-requests/:requestId",
  profile: "/profile",
  educationLanding: "/profile/education",
  educationNew: "/profile/education/new",
  educationEdit: "/profile/education/:educationId",
  workExperienceLanding: "/profile/work-experience",
  workExperienceNew: "/profile/work-experience/new",
  workExperienceEdit: "/profile/work-experience/:workExperienceId",
  more: "/more",
  payment: "/more/payment",
  myEarnings: "/more/payment/my-earnings",
  paymentSetup: "/more/payment/payment-setup",
  help: "/more/help",
  settings: "/more/settings",
  locationSettings: "/more/location-settings",
  referralProgram: "/more/referral",
  openingsSchoolProfile: "/requests/:requestId/school/:schoolId",
  myRequestsSchoolProfile: "/my-requests/:requestId/school/:schoolId",
  skillBuilderTasks: "/more/skill-builder/:version",
  skillBuilderTask: "/more/skill-builder/:version/:task/:step?",
} as const;

/********** Component *********/
type AuthedRoutesProps = {
  /**
   * Used to expose path only for testing routes
   * TODO: @KoltonG - Wondering if one day we can get rid of this :P
   */
  testPath?: ValuesOf<typeof AUTH_URLS>;
};

export function AuthedRoutes(props: AuthedRoutesProps) {
  const { testPath } = props;

  /***** Hooks *****/
  const { userInfo } = useAuth();

  /***** Render *****/
  // Render a loading screen if the user info is not available
  if (!userInfo.onboardingStatus)
    return (
      <IonPage>
        <LoadingInterstitial />
      </IonPage>
    );

  // NOTE: The reason why the onboarding experience is not sharing the
  // `<IonTab />` component is due to non-onboarded users having restricted
  // navigation options.
  if (userInfo.onboardingStatus !== "COMPLETED") return <Onboarding />;

  // Otherwise render the authenticated experience
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        {/*
            using children approach based on 5.1 + version of react router
            https://reactrouter.com/en/main/upgrading/v5#upgrade-to-react-router-v51
          */}
        <Switch>
          <Route exact path={AUTH_URLS.requests}>
            <Requests isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.request}>
            <RequestDetails isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.myRequests}>
            <MyRequests isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.myRequestsDetails}>
            <RequestDetails isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.profile}>
            <Profile />
          </Route>
          <Route exact path={AUTH_URLS.more}>
            <More />
          </Route>
          <Route exact path={AUTH_URLS.payment}>
            {userInfo.isHourly ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <Payment />
            )}
          </Route>
          <Route exact path={AUTH_URLS.paymentSetup}>
            {userInfo.isHourly ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <PaymentSetup />
            )}
          </Route>
          <Route exact path={AUTH_URLS.myEarnings}>
            {userInfo.isHourly ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <MyEarnings />
            )}
          </Route>
          <Route exact path={AUTH_URLS.help}>
            <Help />
          </Route>
          <Route exact path={AUTH_URLS.locationSettings}>
            <LocationSettings />
          </Route>
          {FEATURE_FLAGS.REFERRAL && (
            <Route exact path={AUTH_URLS.referralProgram}>
              <Referral />
            </Route>
          )}
          {/* TODO: remove once feature is ready to be released */}
          {FEATURE_FLAGS.SKILL_BUILDER && (
            <Route
              exact
              path={AUTH_URLS.skillBuilderTasks}
              render={(routeProps) => <SkillBuilderTasksPage {...routeProps} />}
            />
          )}
          {/* TODO: remove once feature is ready to be released */}
          {FEATURE_FLAGS.SKILL_BUILDER && (
            <Route
              exact
              path={AUTH_URLS.skillBuilderTask}
              render={(routeProps) => <SkillBuilderTaskPage {...routeProps} />}
            />
          )}
          <Route exact path={AUTH_URLS.educationLanding}>
            <EducationLanding />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceLanding}>
            <WorkExperienceLanding />
          </Route>
          <Route exact path={AUTH_URLS.educationNew}>
            <EducationNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.educationEdit}>
            <EducationNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceNew}>
            <WorkExperienceNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceEdit}>
            <WorkExperienceNewEdit />
          </Route>
          {/* TODO: remove once feature is ready to be released */}
          {FEATURE_FLAGS.SCHOOL_PROFILE && (
            <Route exact path={AUTH_URLS.openingsSchoolProfile}>
              <SchoolProfile />
            </Route>
          )}
          {FEATURE_FLAGS.SCHOOL_PROFILE && (
            <Route exact path={AUTH_URLS.myRequestsSchoolProfile}>
              <SchoolProfile />
            </Route>
          )}
          {!testPath && <Redirect exact path={AUTH_URLS.root} to={createRequestsUrl()} />}
          {!testPath && (
            <Route
              render={() => {
                window.location.href = AUTH_URLS.root;
                return undefined;
              }}
            />
          )}
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
}

/********** Helper Functions **********/
export function createRootUrl() {
  return generatePath(AUTH_URLS.root);
}

export function createRequestsUrl() {
  return generatePath(AUTH_URLS.requests);
}

export function createRequestUrl(requestId: string) {
  return generatePath(AUTH_URLS.request, { requestId });
}

export function createMyRequestsUrl() {
  return AUTH_URLS.myRequests;
}

export function createMyRequestsDetailsUrl(requestId: string) {
  return generatePath(AUTH_URLS.myRequestsDetails, { requestId });
}

export function createProfileUrl() {
  return generatePath(AUTH_URLS.profile);
}

export function createMoreUrl() {
  return AUTH_URLS.more;
}

export function createPaymentUrl() {
  return generatePath(AUTH_URLS.payment);
}

export function createPaymentSetupUrl() {
  return generatePath(AUTH_URLS.paymentSetup);
}

export function createMyEarningsUrl() {
  return generatePath(AUTH_URLS.myEarnings);
}

export function createHelpUrl() {
  return generatePath(AUTH_URLS.help);
}

export function createSettingsUrl() {
  return generatePath(AUTH_URLS.settings);
}

export function createLocationSettingsUrl() {
  return generatePath(AUTH_URLS.locationSettings);
}

export function createEducationLandingUrl() {
  return generatePath(AUTH_URLS.educationLanding);
}

export function createEducationNewUrl() {
  return generatePath(AUTH_URLS.educationNew);
}

export function createEducationEditUrl(educationId: string) {
  return generatePath(AUTH_URLS.educationEdit, { educationId });
}

export function createWorkExperienceLandingUrl() {
  return generatePath(AUTH_URLS.workExperienceLanding);
}

export function createWorkExperienceNewUrl() {
  return generatePath(AUTH_URLS.workExperienceNew);
}

export function createWorkExperienceEditUrl(workExperienceId: string) {
  return generatePath(AUTH_URLS.workExperienceEdit, { workExperienceId });
}

export function createSchoolProfileUrl(
  isFromOpenings: boolean,
  requestId: string,
  schoolId: string,
) {
  return generatePath(
    isFromOpenings ? AUTH_URLS.openingsSchoolProfile : AUTH_URLS.myRequestsSchoolProfile,
    { requestId, schoolId },
  );
}

export function createSkillBuilderTasksURL(version: string) {
  return generatePath(AUTH_URLS.skillBuilderTasks, { version });
}

export function createReferralProgramUrl() {
  return generatePath(AUTH_URLS.referralProgram);
}

// export for testing
export function isTabSelected(testPath: string | undefined, pathName: string, authUrl: string) {
  return (testPath && testPath === authUrl) || pathName.startsWith(authUrl);
}
