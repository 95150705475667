import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";

import {
  ModalContentSchoolFeedbackAdditionalInfo,
  ModalContentSchoolFeedbackRating,
  ModalContentSchoolFeedbackReasons,
  ModalContentSchoolFeedbackSuccess,
} from "~components";
import { msg, POST, SchoolFeedbackPayload, SchoolFeedbackResponse } from "~utils";

type ModalContentStep = "rating" | "reasons" | "additional-info" | "success";

export type SchoolRatingValue = "POSITIVE" | "NEUTRAL" | "NEGATIVE";

type ModalSchoolFeedbackProps = {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  step?: ModalContentStep;
  schoolName?: string;
  requestId: string;
};

export type SchoolFeedbackForm = {
  experience: SchoolRatingValue;
  reasons: FeedbackReasons[];
  comments?: string;
  otherReason?: string;
  isFavorited?: boolean;
};

export type FeedbackReasons =
  | "LESSON_PLAN"
  | "CLASSROOM_MANAGEMENT"
  | "STAFF_SUPPORT"
  | "PARKING"
  | "ROUTINES_PROCEDURE"
  | "CHECK_IN"
  | "OTHER";

export type UpdatedReasons = {
  reasons: FeedbackReasons[];
  otherReason?: string;
};

export function ModalSchoolFeedback(props: ModalSchoolFeedbackProps) {
  const { onDismiss, schoolName, step, requestId } = props;
  const [_step, _setStep] = useState<ModalContentStep>(step ?? "rating");
  // TODO refactor later after figuring out how to not need these two fields to be required by the API
  const [_schoolFeedbackForm, _setSchoolFeedbackForm] = useState<SchoolFeedbackForm>({
    experience: "POSITIVE",
    reasons: ["OTHER"],
  });
  const queryClient = useQueryClient();

  const IN_PROGRESS_TITLE = msg("SCHOOL_FEEDBACK_TITLE");
  const SUBMITTED_TITLE = msg("SCHOOL_FEEDBACK_SUBMITTED_TITLE");

  const { mutate: saveReview, error: saveMutationError } = useMutation<
    SchoolFeedbackResponse,
    AxiosError,
    SchoolFeedbackPayload
  >({
    mutationFn: (payload: SchoolFeedbackPayload) => {
      return POST("/api/sub/request/{requestId}/feedback", payload, {
        pathParams: { requestId },
      });
    },
    onSuccess: async () => {
      _setStep("success");
    },
  });

  function handleOnRatingChange(rating: SchoolRatingValue) {
    _setSchoolFeedbackForm((prev) => ({
      ...prev,
      experience: rating,
    }));
    _setStep("reasons");
  }

  function handleOnReasonsChange(updatedReasons: UpdatedReasons) {
    _setSchoolFeedbackForm((prev) => ({
      ...prev,
      ...updatedReasons,
    }));
    _setStep("additional-info");
  }

  function onAdditionalInfoSubmit(info: string) {
    _setSchoolFeedbackForm((prev) => ({
      ...prev,
      comments: info,
    }));

    saveReview({ ..._schoolFeedbackForm, comments: info, isFavorited: false });
  }

  function handleOnSubmitClose() {
    queryClient.invalidateQueries({ queryKey: ["fetchRequestDetails"] });
    onDismiss();
  }

  switch (_step) {
    case "rating":
      return (
        <ModalContentSchoolFeedbackRating
          onDismiss={onDismiss}
          schoolName={schoolName ?? ""}
          title={IN_PROGRESS_TITLE}
          onNext={(rating: SchoolRatingValue) => handleOnRatingChange(rating)}
        />
      );
    case "reasons":
      return (
        <ModalContentSchoolFeedbackReasons
          onDismiss={onDismiss}
          title={IN_PROGRESS_TITLE}
          onNext={(updatedReasons: UpdatedReasons) => handleOnReasonsChange(updatedReasons)}
          rating={_schoolFeedbackForm?.experience}
        />
      );
    case "additional-info":
      return (
        <ModalContentSchoolFeedbackAdditionalInfo
          onDismiss={onDismiss}
          title={IN_PROGRESS_TITLE}
          onAdditionalInfoSubmit={(info: string) => onAdditionalInfoSubmit(info)}
          error={saveMutationError}
        />
      );
    case "success":
      return (
        <ModalContentSchoolFeedbackSuccess
          onDismiss={() => handleOnSubmitClose()}
          title={SUBMITTED_TITLE}
        />
      );
    default:
      // TODO once Phone number is completed, rename this to be more generic
      throw new Error(msg("PROFILE_PHONE_NUMBER_STEP_ERROR_MESSAGE", _step));
  }
}
