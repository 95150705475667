import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useHistory } from "react-router";
import { Experience, IconBlock } from "swing-components";

import { AlertBoxError, ButtonScoreWrapper, ContentSingleColumn } from "~components";
import { createWorkExperienceEditUrl, createWorkExperienceNewUrl } from "~pages";
import { GET, msg, ProfileData } from "~utils";
import { ScorePage } from "../../ScoreTemplates";
import styles from "./WorkExperienceLanding.module.css";

export function WorkExperienceLanding() {
  const { data, error, isLoading } = useQuery<{ data: ProfileData }, AxiosError>({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
  });

  return (
    <WorkExperienceLandingView
      workExperience={data?.data.experience}
      error={error}
      isLoading={isLoading}
    />
  );
}

type WorkExperienceLandingViewProps = {
  workExperience?: Experience[];
  error?: AxiosError | null;
  isLoading?: boolean;
};

function WorkExperienceLandingView(props: WorkExperienceLandingViewProps) {
  const { workExperience = [], error, isLoading } = props;

  /* Hooks */
  const history = useHistory();

  return (
    <ScorePage title={msg("PAGE_TITLE_WORK_EXPERIENCE_LANDING")} isLoading={isLoading} hasBack>
      {error ? (
        <AlertBoxError margin="0px" />
      ) : (
        <ContentSingleColumn>
          <div className={styles.workExperienceLandingContainer}>
            <span>{msg("PAGE_TITLE_WORK_EXPERIENCE_LANDING_SUB_HEADER")}</span>
            {workExperience.length > 0 && (
              <div className={styles.workExperienceContent}>
                {workExperience.map((exp, idx) => {
                  const { id, organization } = exp;
                  const isLastExperience = idx === workExperience.length - 1;
                  return (
                    <div key={id}>
                      <IconBlock
                        content={<span>{organization}</span>}
                        onClick={() => history.push(createWorkExperienceEditUrl(`${id}`))}
                        hasDivider={!isLastExperience}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {(!workExperience || workExperience.length < 5) && (
              <div style={{ backgroundColor: "var(--swing-white300)" }}>
                <ButtonScoreWrapper
                  buttonPrimary={{
                    label: `+ ${msg("PAGE_TITLE_WORK_EXPERIENCE_EDIT")}`,
                    expand: "block",
                    onClick: () => history.push(createWorkExperienceNewUrl()),
                  }}
                />
              </div>
            )}
          </div>
        </ContentSingleColumn>
      )}
    </ScorePage>
  );
}
