import { Colors } from "swing-components";

import { WebinarConfirmationAnswerType } from "~onboarding/utils";
import { WebinarOption } from "~utils";
import { formatWebinarDate } from "../WebinarDatesAnswer";

type WebinarConfirmationAnswerProps = Omit<WebinarConfirmationAnswerType, "type"> & {
  /**
   * This answer will always have a `value` because the Question associated with
   * this will use the same `attribute` as the Question associated to the
   * `WEBINAR_DATES` answer. Due to this, no `onChange` is needed.
   */
  value: WebinarOption;
  /**
   * NOTE: Due to the above comment, there is no `onChange` or `isReadOnly`
   * property since this is just displaying the value of the `WEBINAR_DATES`
   */
};

export function WebinarConfirmationAnswer(props: WebinarConfirmationAnswerProps) {
  const { value } = props;

  /***** Render *****/
  return (
    <p style={{ margin: 0, color: Colors.black, fontWeight: 400, fontSize: 16 }}>
      {formatWebinarDate(value.date)}
    </p>
  );
}
