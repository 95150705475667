import { jsxs as n, jsx as r } from "react/jsx-runtime";
import { Colors as m } from "../../foundations/Colors/Colors.js";
import "@ionic/react";
import "../../../AuthProvider-DO6tcIND.js";
import "react";
import "../../../react-paginate-Lh5zQzpz.js";
function a(i) {
  const {
    children: t,
    title: o
  } = i;
  return /* @__PURE__ */ n("div", {
    children: [!!o && /* @__PURE__ */ r("h2", {
      style: {
        margin: 0,
        marginBottom: "16px",
        fontSize: "var(--swing-font-md)"
      },
      children: o
    }), /* @__PURE__ */ r("div", {
      style: {
        borderRadius: "8px",
        backgroundColor: m.white200
      },
      children: t
    })]
  });
}
export {
  a as IconBlockGroup
};
