import { useEffect } from "react";
import { Colors, Icon } from "swing-components";

import { Chip } from "~components";
import { AnswerTypeOutline } from "~onboarding/utils";

type OutlineAnswerProps = AnswerTypeOutline & {
  /** Handler called when mounting the component */
  onChange: (value: string) => void;
  /**
   * When `true`, does not call `onChange` when mounting
   * @default false
   */
  isReadOnly?: boolean;
};

export function OutlineAnswer(props: OutlineAnswerProps) {
  const { isReadOnly = false, onChange, value, content } = props;

  /***** Effects *****/
  useEffect(() => {
    if (isReadOnly === false) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***** Render *****/
  return (
    <div style={{ display: "flex", gap: 32, flexDirection: "column" }}>
      {content.map(({ header, outlines }) => (
        <div key={header.label}>
          <div style={{ marginBottom: 16, display: "flex", gap: 8, alignItems: "center" }}>
            {/* Title */}
            <h3 style={{ fontSize: 16, lineHeight: "24px", fontWeight: 600, margin: 0 }}>
              {header.label}
            </h3>
            <Chip {...header.chipProps} />
          </div>

          {/* Contents */}
          <div style={{ display: "flex", flexDirection: "column", gap: 16, paddingLeft: 16 }}>
            {outlines.map(({ label, decorations }) => (
              <div key={label} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                <p style={{ fontSize: 16, lineHeight: "24px", fontWeight: 400, margin: 0 }}>
                  {label}
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    paddingLeft: 24,
                    margin: 0,
                    listStyle: "none",
                  }}
                >
                  {decorations.map(({ icon, label }) => (
                    <li key={label} style={{ display: "flex", gap: 16 }}>
                      <Icon name={icon} />
                      <span
                        style={{
                          fontSize: 16,
                          lineHeight: "24px",
                          fontWeight: 400,
                          color: Colors.black,
                        }}
                      >
                        {label}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
