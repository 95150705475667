import { useEffect, useRef, useState } from "react";

import {
  AlertBoxError,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditHeader,
} from "~components";
import { CloudinaryResult, openUploadWidget, ProfilePUTPayload } from "~utils";

type ModalProfileImageEditProps = {
  onDismiss: () => void;
  onConfirm: (payload: ProfilePUTPayload) => void;
};

export function ModalProfileImageEdit(props: ModalProfileImageEditProps) {
  const { onDismiss, onConfirm } = props;
  const [error, setError] = useState(false);
  // Used to ensure that the iframe doesn't render multiple times. Specifically a problem in dev
  // with StrictMode enabled but also useful as the widget provided by cloudinary is not good at cleaning
  // itself up
  const iframeRendered = useRef(false);

  useEffect(() => {
    if (!iframeRendered.current) {
      iframeRendered.current = true;
      openUploadWidget((error: object, result: CloudinaryResult) => {
        if (error) {
          setError(true);
        }
        if (result && result.event === "success") {
          onConfirm({ imageUrl: result.info.secure_url });
        }
      });
    }
  }, [onConfirm]);

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title="" />
      <ModalSubProfileEditBody>
        {error && <AlertBoxError margin="8px" />}
        <div id="profileImageEditModal" />
      </ModalSubProfileEditBody>
    </ModalSubProfileEdit>
  );
}
