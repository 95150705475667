import React, { ChangeEvent } from "react";
import { Colors } from "swing-components";

const iconSize = 18;

const styles = {
  container: ({
    isDisabled,
    descriptionText,
  }: {
    isDisabled: boolean | undefined;
    descriptionText: string | undefined;
  }) =>
    ({
      display: "flex",
      alignItems: !descriptionText ? "center" : "end",
      justifyContent: "flex-start",
      background: "#fff",
      cursor: isDisabled ? "not-allowed" : "pointer",
      paddingBottom: descriptionText ? "16px" : "0",
    }) as React.CSSProperties,
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "16px 16px 16px 24px",
    cursor: "inherit",
  } as React.CSSProperties,
  icon: ({ isDisabled, isChecked }: { isDisabled?: boolean; isChecked?: boolean }) => {
    const borderColor = isDisabled ? Colors.slate300 : Colors.blue500;

    const backgroundColor = isDisabled
      ? Colors.slate300
      : isChecked
        ? Colors.blue500
        : Colors.white200;

    return {
      width: iconSize,
      height: iconSize,
      position: "relative",
      transition: "background 180ms cubic-bezier(0.4, 0, 0.2, 1)",
      borderRadius: `calc(${iconSize}px * 0.125)`,
      border: `2px solid ${borderColor}`,
      background: backgroundColor,
      boxSizing: "border-box",
    } as React.CSSProperties;
  },
  iconPath: ({ isChecked = false }) =>
    ({
      fill: "none",
      stroke: Colors.white200,
      strokeWidth: "3",
      opacity: isChecked ? "1" : "0",
      strokeDasharray: "30",
      strokeDashoffset: isChecked ? "0" : "30",
      ...(isChecked && { transition: "stroke-dashoffset 90ms linear 90ms" }),
    }) as React.CSSProperties,
  nativeInput: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: 0,
    outline: 0,
    clip: "rect(0px, 0px, 0px, 0px)",
    opacity: 0,
    overflow: "hidden",
    appearance: "none",
  } as React.CSSProperties,
};

type CheckboxScoreProps = {
  isChecked?: boolean;
  label?: string;
  isDisabled?: boolean;
  descriptionText?: string;
  descriptionTextState?: "primary" | "secondary";
  hasInputField?: boolean;
  onChange?: (value: boolean) => void;
};

function CheckIconScore(props: CheckboxScoreProps) {
  return (
    <svg viewBox="0 0 24 24" style={styles.icon(props)}>
      <path style={styles.iconPath(props)} d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
    </svg>
  );
}

export function CheckboxScore(props: CheckboxScoreProps) {
  const {
    isDisabled,
    isChecked,
    label,
    onChange = () => {},
    descriptionText,
    descriptionTextState = "primary",
    hasInputField = false,
  } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) onChange(e.target.checked);
  };
  return (
    <div style={{ position: "relative", background: Colors.white200, width: "100%" }}>
      <label key={label} style={styles.container({ isDisabled, descriptionText })}>
        <input
          data-testid={`checkbox-${label?.toLowerCase().replaceAll(" ", "-")}`}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleOnChange(e)}
          style={styles.nativeInput}
        />
        <div style={styles.iconWrapper}>
          <CheckIconScore {...props}></CheckIconScore>
        </div>
        <div>
          {!descriptionText ? (
            <>{label}</>
          ) : (
            <>
              {label}
              <div
                style={{
                  color: descriptionTextState === "secondary" ? Colors.slate400 : Colors.black,
                  fontSize: "12px",
                }}
              >
                {descriptionText}
              </div>
            </>
          )}
          {hasInputField && (
            <div>
              <input type="text" />
            </div>
          )}
        </div>
      </label>
    </div>
  );
}
