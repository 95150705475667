import { AxiosError } from "axios";
import { useState } from "react";

import {
  AlertBoxError,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  TextAreaInput,
} from "~components";
import { msg } from "~utils";

type ModalContentSchoolFeedbackAdditionalInfoProps = {
  onAdditionalInfoSubmit: (info: string) => void;
  onDismiss: () => void;
  title: string;
  error?: AxiosError | null;
};

export function ModalContentSchoolFeedbackAdditionalInfo(
  props: ModalContentSchoolFeedbackAdditionalInfoProps,
) {
  const { error, onAdditionalInfoSubmit, onDismiss, title } = props;
  const [additionalInfo, setAdditionalInfo] = useState<string>("");

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        {!!error && <AlertBoxError />}
        <TextAreaInput
          onChange={(info: string) => setAdditionalInfo(info)}
          value={additionalInfo}
          label={msg("SCHOOL_FEEDBACK_ADDITIONAL_INFO_LABEL")}
          placeholder={msg("SCHOOL_FEEDBACK_ADDITIONAL_INFO_PLACEHOLDER")}
        />
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: false,
          text: msg("SCHOOL_FEEDBACK_ADDITIONAL_INFO_SUBMIT_BUTTON_LABEL"),
          onClick: () => {
            onAdditionalInfoSubmit(additionalInfo.trim());
          },
        }}
      />
    </ModalSubProfileEdit>
  );
}
