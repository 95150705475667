import { CSSProperties, ReactNode } from "react";
import { Colors, Icon } from "swing-components";

export type AccordionProps = {
  title: ReactNode;
  isOpen?: boolean;
  onClick: () => void;
  children: ReactNode;
};

const styles: Record<string, CSSProperties> = {
  outerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    background: Colors.white200,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 16px",
    gap: "10px",
    borderBottom: `1px solid ${Colors.slate300}`,
    cursor: "pointer",
  },
  childContainer: {
    overflow: "hidden",
    transition: "all 0.35s ease-in-out",
  },
  childInnerContainer: {
    padding: "24px 16px",
  },
};

export function Accordion({ title, isOpen, children, onClick }: AccordionProps) {
  return (
    <div style={styles.outerContainer}>
      <div style={styles.rowContainer} onClick={onClick}>
        {title}
        <div style={{ width: 24, height: 24, display: "flex", placeItems: "center" }}>
          <Icon name={isOpen ? "Chevron Up" : "Chevron Down"} />
        </div>
      </div>
      <div style={{ ...styles.childContainer, maxHeight: isOpen ? "" : 0 }} aria-expanded={isOpen}>
        <div style={styles.childInnerContainer}>{children}</div>
      </div>
    </div>
  );
}
