import { computeTaskState, Task, TaskComputed, UnsyncedSubAttribute } from "~onboarding/utils";
import { Require, SubAttribute } from "~utils";

/********** Types **********/
export type TaskPageReducerState = {
  /***** Initial States *****/
  /** Represents the state needed to compute which Step to render */
  stepIndex?: {
    /**
     * Set this value initially if you want to attempt to render this Step on
     * mount. This is used when refreshing the page, and having the URL set
     * this value so that the user stays on the last Step they were on.
     *
     * NOTE: This value will be set to `undefined` once it's used as the
     * presence of a value here is a trigger to render a specific Step vs the
     * next logical step (e.g. the next step directly after clicking the
     * "Next" button).
     */
    initial?: number;
    /**
     * Stores the current Step index and is used by the TaskPageBase component
     * to determine which Step to render.
     */
    current?: number;
  };

  /**
   * Represents the sub attributes which come from the API. We call these
   * "synced sub attributes" since they are know to the API.
   *
   * These values and the `task` field are used to computed the `taskComputed`
   * value.
   */
  syncedSubAttributes?: Array<SubAttribute>;
  /**
   * The static Task definition which is used to compute the `taskComputed`
   * field
   */
  task?: Task;

  /***** Runtime / Working State *****/
  /**
   * Represents the computed Task which is used by the TaskPageBase and it's
   * children component for rendering.
   */
  taskComputed?: TaskComputed;

  /**
   * Represents local changes to Question, we call them "answers", which means
   * they are not synced with the API. We call these "unsynced sub attributes"
   * since they are in the same shape as the synced sub attributes except they
   * don't have `updatedAt`, `status`, and `actionRequiredMarkdown` fields.
   */
  unsyncedSubAttributes?: Array<UnsyncedSubAttribute>;

  /***** Flags *****/
  /**
   * Flag representing the loading state which can happen:
   *
   * - For the `taskComputed` field to be computed which required the `task` and `syncedSubAttributes` fields.
   * - When submitting an answer, or change, to the API and waiting for the
   * updated `syncedSubAttributes`.
   *
   * @default false
   */
  isLoading?: boolean;
  /**
   * Flag representing when we can no longer change a Question's answer which
   * happens:
   *
   * - When we have submitted an answer and waiting for the updated
   *   `syncedSubAttributes`
   * - When the Task is `COMPLETE` since we can no longer change answers
   * - When the Task is `UNDER_REVIEW` since we lock the answers until they are
   *   reviewed.
   *
   * @default false
   */
  isReadOnly?: boolean;

  /***** Handlers *****/
  // TODO: Can the diffing happen in here?
  /**
   * Callback triggered when there are changes that need to be synced with the
   * API
   */
  onSubmit: (
    // We are only calling this handler when there are unsynced changes,
    // therefore we can defined this
    state: Require<TaskPageReducerState, "unsyncedSubAttributes">,
  ) => void;
  /**
   * Triggered when the Task should be closed (e.g. clicking "Next" on the
   * last Step)
   */
  onClose: () => void;
  /** Triggered when there is step change. */
  onStepChange: (stepIndex: number) => void;
};

type TaskPageReducerAction =
  /**
   * Should be called when receiving a new Task definition.
   */
  | { type: "SET_TASK"; payload: { task: Task } }
  /**
   * Should be called when receiving a new list of Sub attributes.
   */
  | { type: "SET_SUB_ATTRIBUTES"; payload: { subAttributes: Array<SubAttribute> } }
  /**
   * Should be called when there are any changes to a Question answer.
   */
  | {
      type: "UPDATE_ANSWER";
      payload: {
        subAttribute: UnsyncedSubAttribute;
      };
    }
  /**
   * Should be called when the "Next" button is clicked.
   * NOTE: The `payload` field is only defined for reduce TypeScript complexity.
   * This field is not used.
   */
  | { type: "NEXT_BUTTON"; payload?: Record<string, never> }
  /**
   * Should be called when the "Previous" button is clicked.
   * NOTE: The `payload` field is only defined for reduce TypeScript complexity.
   * This field is not used.
   */
  | { type: "PREV_BUTTON"; payload?: Record<string, never> };

export function TaskPageReducer(
  state: TaskPageReducerState,
  action: TaskPageReducerAction,
): TaskPageReducerState {
  const { type, payload } = action;

  switch (type) {
    case "SET_TASK": {
      if (state.syncedSubAttributes === undefined) {
        // Return only the `task` since we cannot compute the `taskComputed`.
        return { ...state, task: payload.task };
      }

      // Compute the Task
      const taskComputed = computeTaskState({
        task: payload.task,
        syncedSubAttributes: state.syncedSubAttributes,
      });

      // Determine the Step to render and trigger the `onStepChange` callback
      const newStepIndex = {
        current: getNextStepIndex({ initialStepIndex: state.stepIndex?.initial, taskComputed }),
      };
      state.onStepChange(newStepIndex.current);

      return {
        ...state,
        isLoading: false, // Remove the loading state as `taskComputed` is defined.
        isReadOnly: taskComputed.status === "COMPLETED" || taskComputed.status === "UNDER_REVIEW",
        stepIndex: newStepIndex,
        task: payload.task,
        taskComputed,
      };
    }
    case "SET_SUB_ATTRIBUTES": {
      if (!state.task) {
        // Return just the `syncedSubAttributes` since we cannot compute the
        // `taskComputed`
        return { ...state, syncedSubAttributes: payload.subAttributes };
      }

      // Re-computed the Task state using the latest sub attributes, we are '
      // ignoring any local changes since any local changes would have been
      // submitted and cleared by now.
      const taskComputed = computeTaskState({
        task: state.task,
        syncedSubAttributes: payload.subAttributes,
      });

      // Determine the Step to render and trigger the `onStepChange` callback
      const newStepIndex = {
        current: getNextStepIndex({ initialStepIndex: state.stepIndex?.initial, taskComputed }),
      };
      state.onStepChange(newStepIndex.current);

      return {
        ...state,
        isLoading: false, // Remove the loading state as `taskComputed` is defined.
        isReadOnly: taskComputed.status === "COMPLETED" || taskComputed.status === "UNDER_REVIEW",
        stepIndex: newStepIndex,
        // Clear the unsyncedSubAttributes since this happens after submitting the answers
        unsyncedSubAttributes: [],
        // Update the syncedSubAttributes with the payload
        syncedSubAttributes: payload.subAttributes,
        taskComputed,
      };
    }
    case "UPDATE_ANSWER": {
      // Bail when missing the required data to update the answer.
      if (state.task === undefined || state.syncedSubAttributes === undefined) {
        return state;
      }

      const newSubAttribute = payload.subAttribute;
      const { syncedSubAttributes, unsyncedSubAttributes = [] } = state;

      let newUsa = updateUnsyncedSubAttributes({ unsyncedSubAttributes, newSubAttribute });
      const newSsa = [...syncedSubAttributes];

      // Remove any unsynced sub attributes which have reverted to the original
      // value, which is the value of the synced sub attributes.
      // NOTE: We ignore matching on synced sub attributes which have a status
      // of `ACTION_REQUIRED` since any change must be re-synced with the API.
      // TODO: Future enhancement will be to move to a map data structure to
      // reduce the search complexity of an array.
      newUsa = newUsa.reduce((acc, usa) => {
        const matchingSsaIndex = newSsa.findIndex((ssa) => ssa.attribute === usa.attribute);

        // When there is a match
        if (matchingSsaIndex !== -1) {
          // And and the `value` is the same but the `status` is not `ACTION_REQUIRED`
          // since we always want to take the value of an `ACTION_REQUIRED`
          // local change
          if (
            newSsa[matchingSsaIndex].status !== "ACTION_REQUIRED" &&
            newSsa[matchingSsaIndex].value === usa.value
          ) {
            // Then ignore the unsynced sub attribute so that we don't submit
            // the same answer again.
            return acc;
          }

          // At this point, we know that the unsynced sub attribute has a
          // matching synced sub attribute and that the local change (usa)
          // `value` should be taken in priority.

          // Get the matching synced sub attribute and remove that element
          // from newSsa array to remove duplicates.
          const matchingSsa = newSsa.splice(matchingSsaIndex, 1)[0];

          // Then pass along all other fields except the `value`.
          // This is to keep the alert message appearing until submitting
          // the answer.
          return [
            ...acc,
            {
              ...usa,
              // NOTE: We are not passing the `COMPLETED` status since this
              // change has not been synced yet, thus we will let the API
              // determine this.
              status: matchingSsa.status === "COMPLETED" ? undefined : matchingSsa.status,
              actionRequiredMarkdown: matchingSsa.actionRequiredMarkdown,
              updatedAt: matchingSsa.updatedAt,
            } satisfies UnsyncedSubAttribute,
          ];
        }

        // When the local change that has no matching synced sub attribute
        // then keep the change.
        return [...acc, usa];
      }, [] as Array<UnsyncedSubAttribute>);

      // Compute the new reality using the new sub attributes
      const taskComputed = computeTaskState({
        task: state.task,
        syncedSubAttributes: newSsa,
        unsyncedSubAttributes: newUsa,
      });

      return {
        ...state,
        // Update the unsyncedSubAttributes with the new values so that it
        // remains filtered of duplicates.
        unsyncedSubAttributes: newUsa,

        // NOTE: We are not updating the syncedSubAttributes since we want to
        // keep the original values until the API has confirmed the changes.
        // The code above is removing duplicates.

        // Recompute the `taskComputed` state
        taskComputed,
      };
    }
    case "NEXT_BUTTON": {
      // Bail when missing required data for this case.
      if (state.stepIndex?.current === undefined || state.taskComputed === undefined) {
        return state;
      }

      // If the Task is not complete and we have unsynced changes
      if (
        state.taskComputed?.status !== "COMPLETED" &&
        (state.unsyncedSubAttributes?.length ?? 0) > 0
      ) {
        // Then submit the answers to the API
        state.onSubmit(state as Require<TaskPageReducerState, "unsyncedSubAttributes">);
        // And add the loading flag
        return {
          ...state,
          isLoading: true,
          // Disabling changing answers while we are submitting
          isReadOnly: true,
        };
      }

      // Move to the next step if it's not the last step
      if (state.stepIndex.current < state.taskComputed.steps.length - 1) {
        const newStepIndex = {
          ...state.stepIndex,
          current: state.stepIndex.current + 1,
        };

        state.onStepChange(newStepIndex.current);
        return { ...state, stepIndex: newStepIndex };
      }

      // Go back to the Tasks page when we are on the last step
      state.onClose();
      // FIXME: Wondering if there is a cleaner way of doing this?
      return state;
    }
    case "PREV_BUTTON": {
      // Bail when missing required data
      if (
        state.stepIndex?.current === undefined ||
        state.task == undefined ||
        state.syncedSubAttributes === undefined
      ) {
        return state;
      }

      // Bail when on the first step
      if (state.stepIndex.current === 0) {
        return state;
      }

      const newStepIndex = {
        ...state.stepIndex,
        current: state.stepIndex.current - 1,
      };

      // Recompute the Task without the local changes
      const newTaskComputed = computeTaskState({
        task: state.task,
        syncedSubAttributes: state.syncedSubAttributes,
      });

      state.onStepChange(newStepIndex.current);
      return {
        ...state,
        stepIndex: newStepIndex,
        // Clear local changes when going to the previous step
        unsyncedSubAttributes: [],
        taskComputed: newTaskComputed,
      };
    }
  }
}

/**
 * Merge the new sub attributes into the unsynced sub attribute removing any
 * duplications.
 *
 * We need this function since we cannot simply append the new sub attributes
 * to the unsynced sub attributes since there may have duplicate attribute
 * fields
 */
function updateUnsyncedSubAttributes(params: {
  newSubAttribute: UnsyncedSubAttribute;
  unsyncedSubAttributes: Array<UnsyncedSubAttribute>;
}): Array<UnsyncedSubAttribute | SubAttribute> {
  const { newSubAttribute, unsyncedSubAttributes } = params;

  const isMatching = unsyncedSubAttributes.find(
    (usa) => usa.attribute === newSubAttribute.attribute,
  );

  if (!isMatching) return [...unsyncedSubAttributes, newSubAttribute];

  return unsyncedSubAttributes.map((usa) => {
    if (usa.attribute === newSubAttribute.attribute) return newSubAttribute;
    return usa;
  });
}

/**
 * Returns the next Step index to render, and if given an `initialStepIndex`
 * this will be chosen only if it's valid (e.g. not an index which would be
 * skipping a Step).
 */
function getNextStepIndex(params: { initialStepIndex?: number; taskComputed: TaskComputed }) {
  const { initialStepIndex, taskComputed } = params;

  // Determine the next Step index if the `initialStepIndex` was not given
  // which is whichever Step that is not `COMPLETE`.
  let nextStepIndex = taskComputed.steps.findIndex((step) => step.status !== "COMPLETED");
  // If none are found, chose the last Step since we want to bring the user
  // always to the last Step so that they can see the Completion alert.
  if (nextStepIndex === -1) nextStepIndex = taskComputed.steps.length - 1;

  // Return the initialStepIndex only if its not greater than the nextStepIndex
  if (initialStepIndex !== undefined && initialStepIndex <= nextStepIndex) {
    return initialStepIndex;
  }
  return nextStepIndex;
}
