import { Colors } from "swing-components";

export type BadgeStatus =
  | "secondary"
  | "tertiary"
  | "success"
  | "warning"
  | "danger"
  | "light"
  | "medium"
  | "dark";

type BadgeColors = {
  backgroundColor: string;
  textColor: string;
};

type BadgeProps = {
  status: BadgeStatus;
  text: string;
};

export const BadgeStatusColors: Record<BadgeStatus, BadgeColors> = {
  secondary: { backgroundColor: Colors.yellow200, textColor: Colors.yellow600 },
  tertiary: { backgroundColor: Colors.purple200, textColor: Colors.purple500 },
  success: { backgroundColor: Colors.green200, textColor: Colors.green500 },
  warning: { backgroundColor: Colors.yellow500, textColor: Colors.black },
  danger: { backgroundColor: Colors.red200, textColor: Colors.red500 },
  light: { backgroundColor: Colors.white200, textColor: Colors.slate400 },
  medium: { backgroundColor: Colors.slate400, textColor: Colors.white200 },
  dark: { backgroundColor: Colors.black, textColor: Colors.white200 },
};

export function Badge(props: BadgeProps) {
  const { status, text } = props;
  const { backgroundColor, textColor } = BadgeStatusColors[status];
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 4px",
        height: "17px", // This is based on the design system
        backgroundColor: backgroundColor,
        fontSize: "12px",
      }}
    >
      <span style={{ color: textColor, fontWeight: 600 }}>{text}</span>
    </div>
  );
}
