import { jsxs as i, jsx as n } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-DO6tcIND.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { ButtonEdit as c } from "../ButtonEdit/ButtonEdit.js";
import '../../../../../css/Section.css';const r = "_section_fjl6j_1", l = {
  section: r
};
function j(e) {
  const {
    children: o,
    title: s,
    onEditClick: t
  } = e;
  return /* @__PURE__ */ i("div", {
    className: l.section,
    children: [/* @__PURE__ */ i("div", {
      style: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 24
      },
      children: [/* @__PURE__ */ n("h2", {
        style: {
          margin: 0,
          fontSize: "var(--swing-font-xl)"
        },
        children: s
      }), t && /* @__PURE__ */ n(c, {
        onClick: () => t()
      })]
    }), o]
  });
}
export {
  j as Section
};
