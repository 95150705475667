import { useHistory } from "react-router";

import { ContentSingleColumn, useAuth } from "~components";
import { OnboardingPage } from "~onboarding/components";
import { MorePageBase } from "./MorePageBase";

export function MorePage() {
  const { signOut, userInfo } = useAuth();
  const { name } = userInfo;

  const history = useHistory();

  return (
    <OnboardingPage>
      <ContentSingleColumn>
        <MorePageBase userName={name} onSignOutClick={() => signOut({ history })} />
      </ContentSingleColumn>
    </OnboardingPage>
  );
}
