import { IonCardHeader, IonCardTitle, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { ButtonScore } from "src/components/atoms";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { Colors, IconName } from "swing-components";

import { createSchoolProfileUrl } from "~pages";
import { msg, RequestData, useIsDesktop } from "~utils";
import { Chip, ChipColor } from "../Chip";
import styles from "./CardHeader.module.css";

export enum StatusDisplayKey {
  CANCELED = "STATUS_CANCELLED",
  COMPLETED = "STATUS_COMPLETED",
  FILLED = "STATUS_FILLED",
  NO_SHOW = "STATUS_NO_SHOW",
  OPEN = "STATUS_OPEN",
  PENDING_REVIEW = "STATUS_PENDING",
  SIGNED_UP = "STATUS_CONFIRMED",
}

type CardHeaderProps = {
  schoolName: string;
  requestId: string;
  schoolId: string;
  status: RequestData["status"];
};

export const cardHeaderData: Record<RequestData["status"], CardHeaderContentProps> = {
  [StatusDisplayKey.OPEN]: {
    chipColor: "PURPLE",
    chipLabel: msg("REQUEST_CARD_LABEL_TEXT"),
    icon: "Apple",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_OPEN"),
  },
  [StatusDisplayKey.SIGNED_UP]: {
    chipColor: "GREEN",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_SIGNED_UP"),
    icon: "Check",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_SIGNED_UP"),
  },
  [StatusDisplayKey.FILLED]: {
    chipColor: "SLATE",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_FILLED"),
    icon: "Person",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_FILLED"),
  },
  [StatusDisplayKey.COMPLETED]: {
    chipColor: "TEAL",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_COMPLETED"),
    icon: "Check",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_COMPLETED"),
  },
  [StatusDisplayKey.PENDING_REVIEW]: {
    chipColor: "YELLOW",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_PENDING"),
    icon: "Pending Actions",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_PENDING"),
  },
  [StatusDisplayKey.CANCELED]: {
    chipColor: "RED",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_CANCELED"),
    icon: "Delete",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_CANCELED"),
  },
  [StatusDisplayKey.NO_SHOW]: {
    chipColor: "RED",
    chipLabel: msg("REQUEST_CARD_DETAIL_LABEL_NO_SHOW"),
    icon: "Person Off",
    content: msg("REQUEST_CARD_DETAIL_CONTENT_NO_SHOW"),
  },
};

export function CardHeader(props: CardHeaderProps) {
  const { schoolName, requestId, schoolId, status } = props;
  const headerData = cardHeaderData[status];

  const isDesktop = useIsDesktop();
  const history = useHistory();

  const isFromOpenings = status === "STATUS_OPEN";

  /* Ordered in 'most likely to happen to least likely to happen'
   * Open, Signed Up (formerly Confirmed), Already Filled,
   * Completed, Pending Review, Canceled, No Show
   */
  return (
    <IonCardHeader style={{ paddingBottom: 0, backgroundColor: Colors.white200 }}>
      <IonCardTitle>
        {isDesktop ? (
          <DesktopCardHeaderView
            headerData={{ ...headerData }}
            schoolName={schoolName}
            profileOnClick={() =>
              history.push(createSchoolProfileUrl(isFromOpenings, requestId, schoolId))
            }
          />
        ) : (
          <MobileCardHeaderView
            headerData={{ ...headerData }}
            schoolName={schoolName}
            profileOnClick={() =>
              history.push(createSchoolProfileUrl(isFromOpenings, requestId, schoolId))
            }
          />
        )}
      </IonCardTitle>
    </IonCardHeader>
  );
}

type CardHeaderContentProps = {
  chipColor: ChipColor;
  chipLabel: string;
  icon: IconName;
  content: string;
};

function CardHeaderContent(props: CardHeaderContentProps) {
  const { chipColor, chipLabel, icon, content } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Chip color={chipColor} label={chipLabel} icon={icon} />
      <IonText
        style={{
          color: Colors.slate600,
          fontSize: "var(--swing-font-sm)",
          fontWeight: "var(--swing-font-normal)",
          marginTop: "var(--spacing-sm)",
        }}
      >
        {content}
      </IonText>
    </div>
  );
}

type CardHeaderViewProps = {
  schoolName: string;
  headerData: CardHeaderContentProps;
  profileOnClick: () => void;
};

function DesktopCardHeaderView(props: CardHeaderViewProps) {
  const { schoolName, headerData, profileOnClick } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {schoolName}
        <div className={styles["card-header"]}>
          {headerData && <CardHeaderContent {...headerData} />}
        </div>
      </div>
      <div>
        {FEATURE_FLAGS.SCHOOL_PROFILE && (
          <ButtonScore fill="outline" label="School Profile" onClick={() => profileOnClick()} />
        )}
      </div>
    </div>
  );
}

function MobileCardHeaderView(props: CardHeaderViewProps) {
  const { schoolName, headerData, profileOnClick } = props;
  return (
    <div>
      <div>
        {schoolName}
        <div className={styles["card-header"]}>
          {headerData && <CardHeaderContent {...headerData} />}
        </div>
      </div>
      <div style={{ margin: "16px auto" }} className={styles["divider-mobile"]}></div>
      <div>
        {FEATURE_FLAGS.SCHOOL_PROFILE && (
          <ButtonScore fill="outline" label="School Profile" onClick={() => profileOnClick()} />
        )}
      </div>
    </div>
  );
}
