declare global {
  interface Window {
    zE: (arg1: string, arg2: string) => void;
  }
}

export const openZendeskSupport = () => {
  window.zE("messenger", "show");
  window.zE("messenger", "open");
};
