import { IonText, SegmentValue } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  ButtonScore,
  CommitmentCard,
  ContentSingleColumn,
  Section,
  Segment,
  type Segments,
} from "~components";
import { ConfirmRequests, createRequestsUrl } from "~pages";
import {
  GET,
  msg,
  PastCommitmentRequestsData,
  PendingConfirmData,
  UpcomingCommitmentRequestsData,
} from "~utils";
import { ScorePage } from "../ScoreTemplates/ScorePage";
import styles from "./MyRequests.module.css";

const segments: Segments = [
  { value: "upcoming", label: msg("COMMITMENTS_SEGMENT_UPCOMING") },
  { value: "past", label: msg("COMMITMENTS_SEGMENT_PAST") },
];

// TODO: find a better way to avoid the refetchInterval
// from running when running tests (Storybook)
type MyRequestsProps = {
  isTest?: boolean;
};

export function MyRequests(props: MyRequestsProps) {
  const { isTest } = props;

  // Using sessionStorage as a simpler implementation vs. query params
  // as this meets the immediate need to allow a user to navigate away
  // and back to My Requests keeping their currently selected segment
  const [activeSegment, setActiveSegment] = useSessionStorage(
    "my-requests-active-segment",
    "upcoming",
  );

  const { data: upcomingRequests, isLoading: isUpcomingLoading } = useQuery({
    queryKey: ["fetchUpcoming"],
    queryFn: () => GET("/api/sub/request/upcoming"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchInterval: isTest ? false : 30 * 1000, // time in ms - seconds * milliseconds = 30 seconds
  });

  const { data: confirmRequests, isLoading: isConfirmPendingLoading } = useQuery({
    queryKey: ["fetchPendingConfirm"],
    queryFn: () => GET("/api/sub/request/pending-confirm"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchInterval: isTest ? false : 60 * 1000, // time in ms - seconds * milliseconds = 60 seconds
  });

  const { data: pastRequests, isLoading: isPastLoading } = useQuery({
    queryKey: ["fetchPast"],
    queryFn: () => GET("/api/sub/request/past"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchInterval: isTest ? false : 5 * 60 * 1000, // time in ms - minutes * seconds * milliseconds = 5 minutes
  });

  const hasUpcomingData =
    upcomingRequests?.data.later?.length ||
    upcomingRequests?.data.nextFiveDays?.length ||
    upcomingRequests?.data.ongoing?.length ||
    upcomingRequests?.data.pending?.length;
  const hasPastData = pastRequests?.data.past?.length;

  function handleSegmentChange(val: SegmentValue) {
    setActiveSegment(val);
  }

  return (
    <ScorePage
      title={msg("PAGE_TITLE_MY_REQUESTS")}
      isLoading={isUpcomingLoading || isConfirmPendingLoading || isPastLoading}
    >
      <ContentSingleColumn>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles["segment-wrapper"]}>
            <Segment
              segments={segments}
              initialValue={activeSegment}
              onSegmentChange={handleSegmentChange}
            />
          </div>
          {/* height offset based on segment height */}
          <div
            style={{
              height: "calc(100% - 48px)",
              maxWidth: "900px",
            }}
          >
            {activeSegment === "past" ? (
              <div style={{ width: "100%" }}>
                {hasPastData ? (
                  <MyRequestsPastView requests={pastRequests.data} />
                ) : (
                  <MyRequestsEmptyView
                    title={msg("EMPTY_UPCOMING_REQUESTS_TITLE")}
                    description={msg("EMPTY_UPCOMING_REQUESTS_DESCRIPTION")}
                  />
                )}
              </div>
            ) : (
              <>
                {hasUpcomingData ? (
                  <MyRequestsUpcomingView
                    upcomingRequests={upcomingRequests.data}
                    confirmRequests={confirmRequests?.data}
                  />
                ) : (
                  <MyRequestsEmptyView
                    title={msg("EMPTY_PAST_REQUESTS_TITLE")}
                    description={msg("EMPTY_PAST_REQUESTS_DESCRIPTION")}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}

type MyRequestsUpcomingViewProps = {
  upcomingRequests: UpcomingCommitmentRequestsData;
  confirmRequests?: PendingConfirmData[];
};

export function MyRequestsUpcomingView(props: MyRequestsUpcomingViewProps) {
  const { upcomingRequests, confirmRequests } = props;

  return (
    <div style={{ margin: "0 auto", alignContent: "center" }}>
      {confirmRequests && <ConfirmRequests confirmRequests={confirmRequests} />}
      <div style={{ paddingBottom: "16px" }}>
        {upcomingRequests.ongoing?.length > 0 && (
          <div style={{ margin: "16px 16px 24px 16px" }}>
            <Section
              header={msg("MY_REQUESTS_SECTION_HEADER_ONGOING")}
              items={upcomingRequests.ongoing.map((request) => (
                <CommitmentCard key={request.id} request={request} />
              ))}
            />
          </div>
        )}
        {upcomingRequests.nextFiveDays?.length > 0 && (
          <div style={{ margin: "16px 16px 24px 16px" }}>
            <Section
              header={msg("MY_REQUESTS_SECTION_HEADER_NEXT_FIVE_DAYS")}
              items={upcomingRequests.nextFiveDays.map((request) => (
                <CommitmentCard key={request.id} request={request} />
              ))}
            />
          </div>
        )}
        {upcomingRequests.later?.length > 0 && (
          <div style={{ margin: "16px 16px 24px 16px" }}>
            <Section
              header={msg("MY_REQUESTS_SECTION_HEADER_LATER")}
              items={upcomingRequests.later.map((request) => (
                <CommitmentCard key={request.id} request={request} />
              ))}
            />
          </div>
        )}
        {upcomingRequests.pending?.length > 0 && (
          <div style={{ margin: "16px 16px 24px 16px" }}>
            <Section
              header={msg("MY_REQUESTS_SECTION_HEADER_PENDING")}
              description={`${msg("MY_REQUESTS_SECTION_DESCRIPTION_PENDING")}.`}
              items={upcomingRequests.pending.map((request) => (
                <CommitmentCard key={request.id} request={request} />
              ))}
            />
          </div>
        )}
        <span style={{ display: "flex", justifyContent: "center", fontStyle: "italic" }}>
          {msg("LABEL_END_OF_PAGE")}!
        </span>
      </div>
    </div>
  );
}

type MyRequestsPastViewProps = {
  requests: PastCommitmentRequestsData;
};

export function MyRequestsPastView(props: MyRequestsPastViewProps) {
  const { requests } = props;

  return (
    <div style={{ paddingBottom: "16px" }}>
      {requests.past?.length > 0 && (
        <div className={styles["section-wrapper"]}>
          <Section
            description={msg("MY_REQUESTS_SECTION_DESCRIPTION_PAST")}
            items={requests.past.map((request) => (
              <CommitmentCard key={request.id} request={request} />
            ))}
          />
        </div>
      )}
      <span style={{ display: "flex", justifyContent: "center", fontStyle: "italic" }}>
        {msg("LABEL_END_OF_PAGE")}!
      </span>
    </div>
  );
}

type MyRequestsEmptyViewProps = {
  title: string;
  description: string;
};

export function MyRequestsEmptyView(props: MyRequestsEmptyViewProps) {
  const { title, description } = props;

  const history = useHistory();

  const handleNavigateToOpenings = () => {
    history.push(createRequestsUrl());
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "var(--spacing-lg)",
        width: "100%",
        padding: "104px 16px 0 16px",
      }}
    >
      <img
        src="assets/setup-illustration.png"
        alt={msg("SETUP_ILLUSTRATION_IMAGE_ALT")}
        style={{ maxWidth: "200px" }}
      />
      <IonText
        style={{ fontSize: "var(--swing-font-md)", fontWeight: "var(--swing-font-semibold)" }}
      >
        {title}
      </IonText>
      <IonText style={{ fontSize: "var(--swing-font-md)" }}>{description}</IonText>
      <ButtonScore
        onClick={handleNavigateToOpenings}
        fill="solid"
        label={msg("LABEL_EXPLORE_OPENINGS")}
      />
    </div>
  );
}

function getStorageValue(key: string, defaultValue: string) {
  // avoid error if ran on server
  if (typeof window !== "undefined") {
    // attempt to retrieve value
    const savedKey = sessionStorage.getItem(key);
    if (savedKey) {
      return JSON.parse(savedKey);
    }
    return defaultValue;
  }
}

function useSessionStorage(key: string, defaultValue: string) {
  const [storageValue, setStorageValue] = useState(() => getStorageValue(key, defaultValue));
  // keep value in sync
  useEffect(() => {
    if (storageValue === undefined) return;
    sessionStorage.setItem(key, JSON.stringify(storageValue));
  }, [key, storageValue]);
  // return tuple to use as useState
  return [storageValue, setStorageValue];
}
