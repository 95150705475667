import { TextInput } from "~components";
import type { TextAnswerType } from "~onboarding/utils";

type TextAnswerProps = Omit<TextAnswerType, "type"> & {
  /**
   * The value of the entered text.
   */
  value?: string;
  /**
   * When entering text, pass the `value` to the parent.
   */
  onChange: (value?: string) => void;
  /**
   * Disables the editability of the answer.
   */
  isReadOnly?: boolean;
};

export function TextAnswer(props: TextAnswerProps) {
  const { value, onChange, isReadOnly, label, placeholder } = props;

  return (
    <TextInput
      type="text"
      value={value}
      // An empty string is considered an unanswered question
      onChange={(value) => onChange(value === "" ? undefined : value)}
      isDisabled={isReadOnly}
      label={label}
      placeholder={placeholder}
    />
  );
}
