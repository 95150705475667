import { CSSProperties } from "react";
import { ColorName, Colors, Icon, IconName } from "swing-components";

import { ValuesOf } from "~utils";

const styles = {
  container: ({
    isActive,
    isDisabled,
  }: {
    isActive: boolean | undefined;
    isDisabled: boolean | undefined;
  }) =>
    ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
      background: isDisabled ? Colors.slate200 : Colors.white300,
      padding: "12px 16px",
      cursor: isDisabled ? "not-allowed" : "pointer",
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: isDisabled ? Colors.slate300 : isActive ? Colors.blue500 : Colors.black,
      borderRadius: 8,
    }) as CSSProperties,
  iconWrapper: {
    width: 24,
    height: 24,
  } as CSSProperties,
  label: ({ color }: { color: ValuesOf<typeof Colors> }) =>
    ({
      margin: 0,
      fontSize: 14,
      lineHeight: "normal",
      textAlign: "center",
      color,
    }) as CSSProperties,
};

export type ChoiceButtonIcon = {
  name: IconName;
  color: ColorName;
};

type ChoiceButtonProps = {
  icon: ChoiceButtonIcon;
  isDisabled?: boolean;
  isSelected?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  value: string;
};

export function ChoiceButton(props: ChoiceButtonProps) {
  const { icon, isDisabled, isSelected, label, onChange = () => {}, value } = props;

  function getColor() {
    if (isDisabled && !isSelected) {
      return Colors.slate300;
    }
    // TODO: no mock available for "disabled and isSelected"
    // Note: only used in skill builder so can update in the future as needed
    if ((isDisabled && isSelected) || isSelected) {
      return Colors.blue500;
    }
    return Colors[icon.color];
  }

  return (
    <button
      data-testid="choice-button"
      style={styles.container({ isActive: isSelected, isDisabled })}
      aria-checked={isSelected}
      tabIndex={0}
      onClick={() => {
        if (!isDisabled && !isSelected) {
          onChange(value);
        }
      }}
    >
      <div style={styles.iconWrapper}>
        <Icon name={icon.name} color={getColor()} />
      </div>
      <p style={styles.label({ color: getColor() })}>{label}</p>
    </button>
  );
}
