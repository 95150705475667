import { useQuery } from "@tanstack/react-query";
import { ExtractRouteParams, RouteComponentProps } from "react-router";

import { ContentSingleColumn } from "~components";
import { OnboardingPage } from "~onboarding/components";
import { ONBOARDING_ROUTES } from "~onboarding/index";
import { getConfiguration } from "~onboarding/utils";
import { RejectionPageBase } from "./RejectionPageBase";

type RejectionPageProps = RouteComponentProps<
  ExtractRouteParams<(typeof ONBOARDING_ROUTES)["rejection"], string>
>;

/***** Component *****/
export function RejectionPage(props: RejectionPageProps) {
  const { match } = props;
  const { region } = match.params;

  /***** Hooks *****/
  const { data: configuration } = useQuery({
    queryKey: ["configuration", region],
    queryFn: () => getConfiguration(region),
    // Cache the configuration for the lifetime of the application since this
    // only changes on new deployments which will require an application refresh.
    staleTime: Infinity,
  });

  /***** Render *****/
  if (!configuration) return <OnboardingPage isLoading={true} />;

  return (
    <OnboardingPage>
      <ContentSingleColumn>
        <RejectionPageBase rejectionMessageMarkdown={configuration.rejectionMessageMarkdown} />
      </ContentSingleColumn>
    </OnboardingPage>
  );
}
