import { jsx as o, Fragment as g } from "react/jsx-runtime";
import "@ionic/react";
import { d as m, e as c } from "../../../../../AuthProvider-DO6tcIND.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
function b(n) {
  const {
    subDaysWorked: i,
    score: r,
    isSwingHero: s,
    isHourly: e,
    onClick: t
  } = n, l = i < 5 || r === void 0;
  return /* @__PURE__ */ o(g, {
    children: l ? /* @__PURE__ */ o(m, {
      subDaysWorked: i,
      isHourly: e,
      onClick: t
    }) : /* @__PURE__ */ o(c, {
      score: r,
      isSwingHero: s,
      isHourly: e,
      onClick: t
    })
  });
}
export {
  b as SwingHero
};
