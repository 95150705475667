import { CSSProperties, useEffect, useState } from "react";
import { Colors, Icon } from "swing-components";

import { MarkdownContent } from "~components";
import type { AccordionAnswerType } from "~onboarding/utils";

type AccordionAnswerProps = Omit<AccordionAnswerType, "type"> & {
  onChange: (value: string) => void;
  /**
   * When `true`, does not call `onChange` when the component mounts.
   * @default: false
   */
  isReadOnly?: boolean;
};

export function AccordionAnswer(props: AccordionAnswerProps) {
  const { isReadOnly = false, onChange, value, accordions } = props;

  useEffect(() => {
    if (isReadOnly === false) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {accordions.map((accordion) => (
        <Accordion label={accordion.label} markdown={accordion.markdown} key={accordion.label} />
      ))}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    boxShadow: "0px 2px 4px 0px #283c5d16",
    borderRadius: "8px",
    padding: 16,
    background: Colors.white300,
    color: Colors.black,
    textAlign: "left",
  },
  header: {
    width: "100%",
    fontSize: 16,
    fontWeight: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
  },
} satisfies Record<string, CSSProperties>;

type AccordionProps = {
  label: string;
  markdown: string;
  isReadOnly?: boolean;
};

function Accordion(props: AccordionProps) {
  const { label, markdown } = props;

  /***** State *****/
  const [isOpen, setIsOpen] = useState(false);

  /***** Render *****/
  return (
    <button
      style={styles.container}
      data-testid="accordion-toggle"
      onClick={() => setIsOpen(!isOpen)}
    >
      <header style={styles.header}>
        {label}
        <Icon name={isOpen ? "Chevron Up" : "Chevron Down"} />
      </header>
      {/* Divider */}
      {isOpen && <hr style={{ width: "100%", background: Colors.slate300, margin: 0 }} />}
      {/* Content */}
      {isOpen && (
        <div style={{ width: "100%", overflow: "hidden" }} aria-expanded={isOpen}>
          <MarkdownContent body={markdown} style={{ fontSize: 16 }} />
        </div>
      )}
    </button>
  );
}
