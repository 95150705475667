import { jsx as i, jsxs as p } from "react/jsx-runtime";
import { I as m } from "../../../../../Icon-CcIP5yi-.js";
import "../../../../../AuthProvider-DO6tcIND.js";
import "@ionic/react";
import e from "../../../../string-table.js";
import { IconBlock as s } from "../../../../molecules/IconBlock/IconBlock.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as l } from "../Section/Section.js";
function E(t) {
  const {
    qualifications: n,
    isSwingHero: a,
    skillBuilderCompletedTasks: c = []
  } = t;
  return /* @__PURE__ */ i(l, {
    title: e("SUB_PROFILE_QUALIFICATIONS_HEADER"),
    children: /* @__PURE__ */ p("div", {
      style: {
        marginTop: "var(--spacing-sm)"
      },
      children: [a && /* @__PURE__ */ i(s, {
        icon: /* @__PURE__ */ i(m, {
          name: "Swing Hero"
        }),
        content: /* @__PURE__ */ i("span", {
          children: `${e("SUB_PROFILE_CONTACT_SWING")} ${e("SUB_PROFILE_CONTACT_HERO")}`
        }),
        hasDivider: !0
      }), n.map((r, o) => /* @__PURE__ */ i("div", {
        children: /* @__PURE__ */ i(s, {
          icon: /* @__PURE__ */ i(m, {
            name: "Verified"
          }),
          content: /* @__PURE__ */ i("span", {
            children: r
          }),
          hasDivider: !0
        })
      }, `${o}-${r}`)), c.length > 0 && c.map((r, o) => /* @__PURE__ */ i("div", {
        children: /* @__PURE__ */ i(s, {
          icon: /* @__PURE__ */ i(m, {
            name: "Lesson"
          }),
          content: /* @__PURE__ */ i("span", {
            children: `${I(r)} (${e("SUB_PROFILE_QUALIFICATIONS_SKILL_BUILDER_SUB_LABEL")})`
          }),
          hasDivider: !0
        })
      }, `${o}-${r}`))]
    })
  });
}
function I(t) {
  return t.split(".")[0].split("-").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ");
}
export {
  E as Qualifications,
  I as formatSkillName
};
