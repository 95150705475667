import "./ModalDatesTimes.css";

import { DateTime } from "luxon";
import { Colors, Icon } from "swing-components";

import { msg } from "~utils";

// This is needed due to transforming the intervals into DateTimes outside of this component
export type IntervalWithTimeZone = {
  startDate: DateTime;
  endDate: DateTime;
};

type ModalDatesTimesProps = {
  // The intervals should be passed in with the new timezone
  intervals: IntervalWithTimeZone[];
  message: string;
  onDismiss: () => void;
};

export function ModalDatesTimes(props: ModalDatesTimesProps) {
  const { intervals, message, onDismiss } = props;

  return (
    <div className="modal-wrapper">
      <div className="modal-header">
        <h2>{msg("ALL_DATES_TIMES_LABEL")}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={onDismiss} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <div className="modal-body">
        <div className="dates-times-modal-disclaimer">
          <span>{message}</span>
        </div>
        <div className="grid-container">
          <div className="grid-header">
            <span>{msg("LABEL_DATE")}</span>
            <span className="grid-header-start">{msg("LABEL_START")}</span>
            <span>{msg("LABEL_END")}</span>
          </div>
          {intervals.map((interval: IntervalWithTimeZone, idx: number) => (
            <DatesTimesContent key={idx} interval={interval} />
          ))}
        </div>
      </div>
    </div>
  );
}

type DatesTimesContentProps = {
  interval: IntervalWithTimeZone;
};

function DatesTimesContent(props: DatesTimesContentProps) {
  const { interval } = props;

  return (
    <div className="grid-row">
      <div className="grid-date">{interval.startDate.toFormat("ccc',' LLL dd")}</div>
      <div className="grid-start">{interval.startDate.toFormat("t").toLowerCase()}</div>
      <div className="grid-end">{interval.endDate.toFormat("t").toLowerCase()}</div>
    </div>
  );
}
