import { DateTime } from "luxon";
import { Colors, Icon } from "swing-components";

import { AlertBoxError, ButtonScore } from "~components";
import { RequestData } from "~utils";

import "./ModalConflicts.css"; // Note .css as ionic overrides needed

import { msg } from "~utils";

type ModalConflictsProps = {
  conflicts: RequestData["conflicts"];
  message: string;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  onConfirm: () => void;
  title: string;
  hasApiError?: boolean;
};

// Note: controlled by `useModal` hook via useIonModal `hook`.
export function ModalConflicts(props: ModalConflictsProps) {
  const { conflicts, message, onDismiss, onConfirm, title, hasApiError = false } = props;

  return (
    <div className="modal-wrapper">
      <div className="modal-header">
        <h2>{title}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={() => onDismiss()} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <div className="modal-body">
        {hasApiError && <AlertBoxError />}
        <p>{message}</p>
        <ul className="modal-conflicts-list">
          {conflicts?.map((conflict) =>
            conflict.intervals.map((interval) => (
              <li
                key={`${conflict.schoolName}-${interval.startDate}`}
                className="modal-conflicts-list-item"
              >
                {msg("MODAL_CONFLICTING_COMMITMENTS_ROW", {
                  schoolName: conflict.schoolName,
                  date: DateTime.fromISO(interval.startDate).toFormat("ccc',' LLL dd"),
                })}
              </li>
            )),
          )}
        </ul>
      </div>
      <div className="modal-footer">
        <div className="modal-footer-content">
          <span>{msg("MODAL_CONFLICTING_COMMITMENTS_SIGN_UP_QUESTION")}</span>
        </div>
        <div className="modal-footer-buttons">
          <div className="modal-footer-button-spacing">
            <ButtonScore
              label={msg("MODAL_CONFLICTING_COMMITMENTS_SIGN_UP_LABEL")}
              onClick={onConfirm}
            />
          </div>
          <ButtonScore
            label={msg("MODAL_CONFLICTING_COMMITMENTS_DECLINE_LABEL")}
            fill="outline"
            onClick={onDismiss}
          />
        </div>
      </div>
    </div>
  );
}
