import { subPhotoPNG } from "swing-assets";

import {
  LocationSettingsData,
  LocationSettingsErrorResponse,
  PastCommitmentRequestData,
  PendingConfirmData,
  ProfileData,
  ProfilePUTPayload,
  ReferralData,
  RequestData,
  SchoolProfileData,
  UpcomingCommitmentRequestData,
} from "~utils";

// HELPERS

const loremIpsumLongText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse posuere blandit pellentesque. Vivamus et eleifend neque. Quisque et ipsum ac augue gravida condimentum at nec dolor. Ut vitae mi lectus. Vivamus ex est, sagittis et aliquam sed, sollicitudin quis eros. Nunc eu tellus eget tortor viverra dictum. Sed luctus lacinia enim ut tincidunt.
Curabitur nunc quam, scelerisque sed dignissim et, ullamcorper quis purus. Aliquam in venenatis quam. Nam congue scelerisque sem, in finibus ipsum rutrum at. Morbi malesuada eros id felis gravida, non aliquet velit laoreet. Cras iaculis, massa id maximus interdum, magna mi euismod nibh, dignissim efficitur lorem purus ut ex. Quisque finibus ligula sed sem condimentum condimentum. Proin mollis suscipit ex sit amet varius. Nunc commodo dolor dapibus felis suscipit viverra. Suspendisse ultricies congue vulputate. Donec ac tellus gravida, aliquet quam ac, mattis mauris. Etiam vitae neque auctor, lobortis sem in, tempor felis. Sed eu felis sapien. Sed non turpis dui. Nam fermentum blandit diam, ut tincidunt tortor finibus et. Vestibulum tincidunt viverra sapien ac fringilla. Nullam et gravida leo.
Nullam nec risus finibus, varius eros vel, fringilla erat. Donec et nisl nec lacus gravida pulvinar. Morbi sollicitudin tempus maximus. Proin convallis purus augue, vitae fermentum felis auctor pharetra. Aliquam feugiat sem id ipsum mattis molestie. Integer accumsan ultrices ex, sit amet porttitor dolor laoreet sit amet. Curabitur leo turpis, gravida eget venenatis a, accumsan in purus. Curabitur ut turpis tempus, suscipit elit in, luctus elit. Pellentesque eget neque vel enim pharetra porttitor non quis purus. In hac habitasse platea dictumst. Mauris tincidunt nibh et mi finibus sagittis. Vestibulum sed ultrices felis. Sed nec ultrices enim. Nullam faucibus consectetur malesuada. Quisque eu quam suscipit nisl ultrices luctus non vitae quam. Quisque fermentum tempor sollicitudin.
Donec tempor tortor sit amet elementum molestie. Etiam sodales in odio at hendrerit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper ipsum nisi, mattis vestibulum magna ultrices eu. Proin hendrerit, lectus id vestibulum cursus, velit massa vulputate odio, at commodo eros turpis et justo. Morbi quis ante erat. Etiam venenatis faucibus commodo. Curabitur eu ex vel nunc dignissim tincidunt. Phasellus ut aliquam erat. Donec sit amet varius libero. Suspendisse hendrerit risus ut felis vehicula, eget dapibus magna hendrerit.
Cras in augue tempor, dictum eros sodales, vestibulum justo. Nunc mollis convallis posuere. Nullam est nisi, dictum eget nunc ut, accumsan convallis arcu. Vivamus et arcu lectus. Nunc eu lacinia arcu. Aenean sollicitudin sed magna eget congue. Nullam tempus pretium mi, eu vestibulum risus posuere vitae. Nunc a tempor lacus. Ut nisi augue, mollis sed sodales a, commodo et sem. Donec sit amet placerat nunc. Suspendisse euismod convallis luctus. Nunc elementum finibus leo eget finibus. Ut at dignissim enim.`;

export const schoolName = "Swing High School";

const schoolAddress = {
  street: "1 East Grand Ave",
  city: "Dallas",
  state: "TX",
  zip: "75223",
};

export const school: RequestData["school"] = {
  id: "d746976b-f802-4388-a09a-5b2e37f24cc4",
  name: schoolName,
  address: schoolAddress,
  timeZone: "US/Pacific",
};

export const contact: RequestData["contact"] = {
  name: "Contact Name",
  email: "contactname@example.com",
  phoneNumber: "1234567890",
};

// SINGLE DAY

export const singleDayRequest: RequestData = {
  id: "65537a83-d3d9-4d9f-8e8f-94e871585073",
  status: "STATUS_CONFIRMED",
  school: {
    ...school,
    name: `${schoolName} - SD`,
  },
  startDate: "2024-04-08T16:00:00.000Z",
  endDate: "2024-04-09T00:30:00.000Z",
  intervals: [
    {
      endDate: "2024-04-09T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-08T16:00:00.000Z",
      startOffset: 480,
    },
  ],
  isMultiDay: false,
  isMultiWeek: false,
  grades: ["2nd grade"],
  memo: "req-2",
  subjects: ["Science"],
  payment: {
    isHourly: false,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
    paymentDate: "2024-05-02T20:41:30.787Z",
  },
  displayDate: "Mon, Apr 08",
  displayTime: "8:00AM - 4:30PM",
  action: "ACTION_SUB_CAN_CANCEL",
  contact,
  teacher: "Test Teacher Name",
  hasFeedback: false,
};

export const singleDayRequestSignUp: RequestData = {
  ...singleDayRequest,
  id: "65537a83-d3d9-4d9f-8e8f-94e871585075",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
};

export const singleDayRequestRequiredValuesOnly: RequestData = {
  id: "65537a83-d3d9-4d9f-8e8f-94e871585074",
  status: singleDayRequest.status,
  startDate: singleDayRequest.startDate,
  endDate: singleDayRequest.endDate,
  intervals: singleDayRequest.intervals,
  school: {
    id: "d746976b-f802-4388-a09a-5b2e37f24cc4",
    name: schoolName,
    timeZone: "US/Pacific",
  },
  isMultiDay: singleDayRequest.isMultiDay,
  isMultiWeek: singleDayRequest.isMultiWeek,
  action: singleDayRequest.action,
  payment: singleDayRequest.payment,
  hasFeedback: false,
};

export const singleDayRequestHourly: RequestData = {
  ...singleDayRequest,
  id: "65537a83-d3d9-4d9f-8e8f-94e871585076",
  payment: {
    isHourly: true,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
    paymentDate: "2024-05-02T20:41:30.787Z",
  },
};

export const singleDayRequestHourlySignUp: RequestData = {
  ...singleDayRequestSignUp,
  payment: {
    isHourly: true,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
  },
};

// MULTI DAY

export const multiDayRequest: RequestData = {
  id: "65527788-b791-4d7d-9654-d828c5ff98d1",
  status: "STATUS_CONFIRMED",
  school: {
    id: "65527ae3-029a-4033-8425-b99e2da1b53c",
    name: `${schoolName} - MD`,
    address: {
      street: "5955 Blossom Ave",
      city: "San Jose",
      state: "CA",
      zip: "95123",
    },
    timeZone: "US/Pacific",
  },
  startDate: "2024-04-05T16:00:00.000Z",
  endDate: "2024-04-10T00:30:00.000Z",
  intervals: [
    {
      endDate: "2024-04-10T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-09T16:00:00.000Z",
      startOffset: 480,
    },
    {
      endDate: "2024-04-09T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-08T16:00:00.000Z",
      startOffset: 480,
    },
    {
      endDate: "2024-04-08T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-07T16:00:00.000Z",
      startOffset: 480,
    },
    {
      endDate: "2024-04-07T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-06T16:00:00.000Z",
      startOffset: 480,
    },
    {
      endDate: "2024-04-06T00:30:00.000Z",
      endOffset: 480,
      startDate: "2024-04-05T16:00:00.000Z",
      startOffset: 480,
    },
  ],
  isMultiDay: true,
  isMultiWeek: false,
  grades: ["1st grade", "2nd grade"],
  memo: loremIpsumLongText,
  subjects: ["Science"],
  payment: {
    isHourly: false,
    payValue: 500000,
    fullDayPayValue: 100000,
    halfDayPayValue: 50000,
    paymentDate: "2024-05-02T20:41:30.787Z",
  },
  displayDate: "Fri, Apr 05 - Tue, Apr 09",
  displayTime: "MULTI-DAY",
  hasFeedback: true,
  action: "ACTION_SUB_CAN_CANCEL",
  contact,
  teacher: "Test Teacher Name",
  fillDate: "2024-03-25T16:00:00.000Z",
};

export const multiDayRequestSignUp: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98g1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
};

export const multiDayRequestJoinWaitlist: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98h1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_JOIN_WAITLIST",
};

export const multiDayRequestRequiredValuesOnly: RequestData = {
  id: "65527788-b791-4d7d-9654-d828c5ff98i1",
  status: multiDayRequest.status,
  startDate: multiDayRequest.startDate,
  endDate: multiDayRequest.endDate,
  intervals: multiDayRequest.intervals,
  school: {
    id: "d746976b-f802-4388-a09a-5b2e37f24cc4",
    name: schoolName,
    timeZone: "US/Pacific",
  },
  isMultiDay: multiDayRequest.isMultiDay,
  isMultiWeek: multiDayRequest.isMultiWeek,
  action: multiDayRequest.action,
  payment: multiDayRequest.payment,
  hasFeedback: false,
};

export const multiDayRequestHourly: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98j1",
  payment: {
    isHourly: true,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
    paymentDate: "2024-05-02T20:41:30.787Z",
  },
};

export const multiDayRequestHourlySignUp: RequestData = {
  ...multiDayRequestSignUp,
  payment: {
    isHourly: true,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
  },
};

export const multiDayRequestNOOP: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98k1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_NOOP",
};

export const multiDayRequestSignUpUnknownGrade: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98l1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  grades: ["unknown"],
};

export const multiDayRequestSignUpLotsOfGrades: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98m1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  grades: ["First Grade", "Second Grade", "Third Grade", "Fourth Grade", "Fifth Grade"],
};

export const multiDayRequestSignUpUnknownSubject: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98n1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  subjects: ["unknown"],
};

export const multiDayRequestSignUpLotsOfSubjects: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98o1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  subjects: ["Science", "Reading", "Math", "PE", "General Education", "History"],
};

export const multiDayRequestSignUpUnknownGradeAndSubject: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98p1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  grades: ["unknown"],
  subjects: ["unknown"],
};

export const multiDayRequestSignUpNotProvidedGradeAndSubject: RequestData = {
  ...multiDayRequest,
  id: "65527788-b791-4d7d-9654-d828c5ff98q1",
  status: "STATUS_OPEN",
  action: "ACTION_SUB_CAN_SIGN_UP",
  grades: [],
  subjects: [],
};

// Long Term / Waitlist found below Extras so that they can be used for request formation

// EXTRAS

// The start and end values come back as ISO strings WITH UTC attached
export const twoIntervals: RequestData["intervals"] = [
  {
    startOffset: 480,
    endOffset: 480,
    startDate: "2024-05-01T11:35:00.000Z",
    endDate: "2024-05-01T20:35:00.000Z",
  },
  {
    startOffset: 480,
    endOffset: 480,
    startDate: "2024-05-02T11:35:00.000Z",
    endDate: "2024-05-02T20:35:00.000Z",
  },
];

export const fourteenIntervals: RequestData["intervals"] = [
  {
    endDate: "2024-05-01T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-01T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-02T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-02T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-03T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-03T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-06T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-06T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-07T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-07T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-08T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-08T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-09T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-09T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-10T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-10T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-13T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-13T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-14T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-14T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-15T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-15T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-16T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-16T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-17T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-17T11:35:00.000Z",
    startOffset: 480,
  },
  {
    endDate: "2024-05-20T20:35:00.000Z",
    endOffset: 480,
    startDate: "2024-05-20T11:35:00.000Z",
    startOffset: 480,
  },
];

export const conflictsSingle: RequestData["conflicts"] = [
  {
    schoolName: schoolName,
    intervals: [
      {
        endDate: "2023-11-14T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-14T14:30:00Z",
        startOffset: 420,
      },
    ],
  },
];

export const conflictsMultiple: RequestData["conflicts"] = [
  {
    schoolName: schoolName,
    intervals: [
      {
        endDate: "2023-11-14T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-14T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-15T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-15T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-16T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-16T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-17T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-17T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-18T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-18T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-19T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-19T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-20T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-20T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-21T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-21T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-22T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-22T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-23T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-23T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-24T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-24T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-25T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-25T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-26T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-26T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-27T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-27T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-28T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-28T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-29T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-29T14:30:00Z",
        startOffset: 420,
      },
      {
        endDate: "2023-11-30T23:30:00Z",
        endOffset: 420,
        startDate: "2023-11-30T14:30:00Z",
        startOffset: 420,
      },
    ],
  },
];

// Long Term / Waitlist

export const waitlistRequestConfirmWithoutConflicts: RequestData = {
  id: "65527788-b791-4d7d-9654-d828c5ff98d3",
  status: "STATUS_CONFIRMED",
  school: {
    id: "65527ae3-029a-4033-8425-b99e2da1b53c",
    name: `${schoolName} - MD`,
    address: {
      street: "5955 Blossom Ave",
      city: "San Jose",
      state: "CA",
      zip: "95123",
    },
    timeZone: "US/Pacific",
  },
  startDate: "2024-04-05T16:00:00.000Z",
  endDate: "2024-04-10T00:30:00.000Z",
  intervals: fourteenIntervals,
  isMultiDay: true,
  isMultiWeek: true,
  grades: ["1st grade", "2nd grade"],
  memo: loremIpsumLongText,
  subjects: ["Science"],
  payment: {
    isHourly: false,
    payValue: 5000,
    fullDayPayValue: 10000,
    halfDayPayValue: 5000,
  },
  displayDate: "Fri, Apr 05 - Tue, May 09",
  displayTime: "MULTI-DAY",
  hasFeedback: true,
  action: "ACTION_SUB_CAN_CANCEL",
  contact,
  teacher: "Test Teacher Name",
};

export const waitlistRequestConfirmWithConflicts: RequestData = {
  ...waitlistRequestConfirmWithoutConflicts,
  id: "65527788-b791-4d7d-9654-d828c5ff98d2",
  status: "STATUS_PENDING",
  conflicts: conflictsMultiple,
  action: "ACTION_SUB_CAN_CONFIRM",
};

// Commitment Data
export const singleDayCommitmentHourly: UpcomingCommitmentRequestData = {
  id: "1",
  status: "STATUS_CONFIRMED",
  isMultiDay: false,
  displayDate: "Fri, May 03",
  displayTime: "7:00 am - 2:30 pm",
  school: {
    name: schoolName,
    address: schoolAddress,
    id: "1",
    timeZone: "US/Pacific",
  },
  payment: {
    isHourly: true,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
  hasConflict: false,
};

export const singleDayCommitmentW2: UpcomingCommitmentRequestData = {
  ...singleDayCommitmentHourly,
  id: "2",
  displayDate: "Fri, Apr 05",
  payment: {
    isHourly: false,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
};

export const multiDayCommitmentW2NoConflict: UpcomingCommitmentRequestData = {
  id: "3",
  status: "STATUS_CONFIRMED",
  school: {
    name: schoolName,
    address: schoolAddress,
    id: "1",
    timeZone: "US/Pacific",
  },
  displayDate: "Mon, Apr 08 - Tue, Apr 09",
  displayTime: "7:00 am - 2:30 pm",
  isMultiDay: true,
  payment: {
    isHourly: false,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
  hasConflict: false,
};

export const additionalMultiDayCommitmentW2NoConflict: UpcomingCommitmentRequestData = {
  ...multiDayCommitmentW2NoConflict,
  id: "4",
  displayDate: "Mon, Apr 15 - Tue, Apr 16",
};

export const multiDayCommitmentW2Later: UpcomingCommitmentRequestData = {
  id: "5",
  status: "STATUS_CONFIRMED",
  school: {
    name: schoolName,
    address: schoolAddress,
    id: "1",
    timeZone: "US/Pacific",
  },
  displayDate: "Fri, Apr 19 - Tue, Apr 29",
  displayTime: "7:00 am - 2:30 pm",
  isMultiDay: true,
  payment: {
    isHourly: false,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
  hasConflict: false,
};

export const singleDayCommitmentW2Later: UpcomingCommitmentRequestData = {
  ...singleDayCommitmentW2,
  id: "6",
  displayDate: "Fri, Jul 06",
  displayTime: "7:30 am - 3:00 pm",
};

export const multiDayCommitmentW2Conflict: UpcomingCommitmentRequestData = {
  id: "7",
  status: "STATUS_PENDING",
  school: {
    name: schoolName,
    address: schoolAddress,
    id: "1",
    timeZone: "US/Pacific",
  },
  displayDate: "Fri, Apr 05 - Tue, Apr 09",
  displayTime: "7:30 am - 3:00 pm",
  isMultiDay: true,
  payment: {
    isHourly: false,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
  hasConflict: true,
};

export const additionalMultiDayCommitmentW2Conflict: UpcomingCommitmentRequestData = {
  ...multiDayCommitmentW2Conflict,
  id: "8",
  displayDate: "Fri, Jul 06 - Tue, Jul 09",
};

export const multiDayCommitmentW2Completed: PastCommitmentRequestData = {
  id: "9",
  status: "STATUS_COMPLETED",
  school: {
    name: schoolName,
  },
  displayDate: "Fri, Apr 05 - Tue, Apr 09",
  displayTime: "7:30 am - 3:00 pm",
  isMultiDay: true,
  payment: {
    isHourly: false,
    payValue: 2000,
  },
  hasFeedback: false,
};

export const additionalMultiDayCommitmentW2Completed: PastCommitmentRequestData = {
  ...multiDayCommitmentW2Completed,
  id: "10",
  displayDate: "Fri, Jan 12 - Mon, Jan 15",
};

export const singleDayCommitmentW2Completed: PastCommitmentRequestData = {
  ...multiDayCommitmentW2Completed,
  id: "11",
  displayDate: "Mon, Jan 08",
};

// Pending Confirm Request data
export const pendingConfirmRequestsData: PendingConfirmData[] = [
  {
    id: "65527788-b791-4d7d-9604-234234554fds4",
    schoolName: schoolName,
    startDate: "2024-08-15T14:30:00Z",
    endDate: "2024-08-24T14:30:00Z",
    confirmExpirationDate: "2024-08-14T14:30:00Z",
  },
  {
    id: "653w7788-b791-4d7d-9654-d828c5fktt76",
    schoolName: schoolName,
    startDate: "2024-11-14T14:30:00Z",
    endDate: "2024-11-30T14:30:00Z",
    confirmExpirationDate: "2024-11-13T14:30:00Z",
  },
];

// Past Request with feedback

export const schoolFeedback = {
  date: "2024-08-15T14:30:00Z",
  experience: "POSITIVE",
  reasons: ["LESSON_PLAN"],
  comments: "The bathrooms were clean.",
};

export const multiDayW2CompletedWithoutFeedback: RequestData = {
  id: "1234235467890",
  status: "STATUS_COMPLETED",
  action: "ACTION_SUB_CAN_NOOP",
  school: {
    id: "65527ae3-029a-4033-8425-b99e2da1b53c",
    name: `${schoolName} - MD`,
    address: {
      street: "5955 Blossom Ave",
      city: "San Jose",
      state: "CA",
      zip: "95123",
    },
    timeZone: "US/Pacific",
  },
  startDate: "2024-04-05T16:00:00.000Z",
  endDate: "2024-04-10T00:30:00.000Z",
  intervals: fourteenIntervals,
  isMultiDay: true,
  isMultiWeek: true,
  grades: ["1st grade", "2nd grade"],
  memo: loremIpsumLongText,
  subjects: ["Science"],
  displayDate: "Fri, Apr 05 - Tue, Apr 09",
  displayTime: "7:30 am - 3:00 pm",
  payment: {
    isHourly: false,
    payValue: 2000,
    fullDayPayValue: 2000,
    halfDayPayValue: 1000,
  },
  hasFeedback: false,
};

export const multiDayW2CompletedWithPositiveFeedback: RequestData = {
  ...multiDayW2CompletedWithoutFeedback,
  hasFeedback: true,
  schoolFeedback: {
    date: "2024-08-15T14:30:00Z",
    experience: "POSITIVE",
    reasons: ["LESSON_PLAN"],
    comments: "The bathrooms were clean.",
  },
};

export const multiDayW2CompletedWithNeutralFeedback: RequestData = {
  ...multiDayW2CompletedWithoutFeedback,
  hasFeedback: true,
  schoolFeedback: {
    date: "2024-08-15T14:30:00Z",
    experience: "NEUTRAL",
    reasons: ["LESSON_PLAN"],
    comments: "The bathrooms were clean.",
  },
};

export const multiDayW2CompletedWithNegativeFeedback: RequestData = {
  ...multiDayW2CompletedWithoutFeedback,
  hasFeedback: true,
  schoolFeedback: {
    date: "2024-08-15T14:30:00Z",
    experience: "NEGATIVE",
    reasons: ["LESSON_PLAN"],
    comments: "The bathrooms were clean.",
  },
};

// MOCKED REQUEST DATA

export const requestsWithNoDataResponse = {
  data: [],
};

export const requestsWithDataResponse = {
  data: [multiDayRequest, singleDayRequest, multiDayRequestHourly, singleDayRequestHourly],
};

export const pendingConfirmRequestWithDataResponse = {
  data: pendingConfirmRequestsData,
};

export const upcomingRequestsWithDataResponse = {
  data: {
    ongoing: [singleDayCommitmentW2],
    nextFiveDays: [
      singleDayCommitmentHourly,
      multiDayCommitmentW2NoConflict,
      additionalMultiDayCommitmentW2NoConflict,
    ],
    later: [multiDayCommitmentW2Later, singleDayCommitmentW2Later],
    pending: [multiDayCommitmentW2Conflict, additionalMultiDayCommitmentW2Conflict],
  },
};

export const myRequestsWithNoDataResponse = {
  data: {},
};

export const pastRequestsWithDataResponse = {
  data: {
    past: [
      multiDayCommitmentW2Completed,
      additionalMultiDayCommitmentW2Completed,
      singleDayCommitmentW2Completed,
    ],
  },
};

export const subLocationDataWithSelections: { data: LocationSettingsData } = {
  data: {
    zipCode: "95139",
    locationTags: [
      {
        locationId: "location/ca.marin",
        locationName: "Marin",
        schoolCount: 2,
      },
      {
        locationId: "location/ca.south-bay",
        locationName: "South Bay",
        schoolCount: 4,
      },
      {
        locationId: "location/ca.san-francisco",
        locationName: "San Francisco",
        schoolCount: 3,
      },
      {
        locationId: "location/ca.peninsula",
        locationName: "Peninsula",
        schoolCount: 1,
      },
      {
        locationId: "location/ca.santa-cruz",
        locationName: "Santa Cruz",
        schoolCount: 5,
      },
      {
        locationId: "location/ca.east-bay",
        locationName: "East Bay",
        schoolCount: 0,
      },
    ],
    subLocationTags: ["location/ca.south-bay", "location/ca.san-francisco"],
  },
};

export const subLocationDataWithoutSelections: { data: LocationSettingsData } = {
  data: {
    zipCode: "95132",
    locationTags: [
      {
        locationId: "location/ca.peninsula",
        locationName: "Peninsula",
        schoolCount: 0,
      },
      {
        locationId: "location/ca.santa-cruz",
        locationName: "Santa Cruz",
        schoolCount: 0,
      },
      {
        locationId: "location/ca.east-bay",
        locationName: "East Bay",
        schoolCount: 3,
      },
    ],
    subLocationTags: [],
  },
};

export const subLocationErrorInvalidZip: LocationSettingsErrorResponse = {
  error: "Invalid request",
  details: {
    type: "invalid-zip",
    zip: "12345",
  },
};

export const subLocationErrorOutOfState: LocationSettingsErrorResponse = {
  error: "Invalid request",
  details: {
    type: "out-of-state-zip",
    zip: "30721",
  },
};

export const paymentDataResponse = {
  data: {
    setupUrl: "https://swingsubs.swingeducation.com",
    paymentHistoryUrl: "https://swingsubs.swingeducation.com/more",
  },
};

export const profileDataPartialResponse: {
  data: ProfileData & { email: string; name: string };
} = {
  data: {
    email: "demouserexample@example.com",
    name: "Demo User",
    stats: {
      daysWorked: 123,
      schoolsWorked: 45,
    },
    education: [
      { id: 11111, name: "Evergreen University of Wisdom" },
      { id: 22222, name: "Stellaris Institute of Innovation" },
    ],
  },
};

export const profileDataResponse: { data: ProfileData } = {
  data: {
    ...profileDataPartialResponse.data,
    phoneNumber: "1234567890",
    imageUrl: subPhotoPNG,
    about:
      "This is some information about me who is testing out the content for the about section to make sure there is placeholder content in here.",
    qualifications: ["Emergency 30 Day Permit", "Englewood Teacher Training", "Bachelor's Degree"],
    stats: {
      daysWorked: 173,
      schoolsWorked: 95,
      timesPreferred: 6,
      positiveReviews: 78,
    },
    feedback: [
      {
        schoolName: "MeadowBrook Elementary",
        content: "Kids love her! Would have her back!",
      },
      {
        schoolName: "Adrendale Elementary",
        content: "What more can I say. Wonderful",
      },
      {
        schoolName: "Eastlake Academy",
        content: "One of our favorites. We feel very comfortable with her.",
      },
    ],
    experience: [
      {
        id: 11111,
        endDate: undefined, // present job,
        organization:
          "Rainbow Ridge Elementary School of California for the Gifted, Science Campus",
        role: "Teacher",
        startDate: "2023-08-01T00:00:00",
        tags: ["EXP_TAG_PRE_K", "EXP_TAG_K_5", "EXP_TAG_6_8"],
        type: "CLASSROOM_LEAD",
      },
      {
        id: 22222,
        endDate: "2023-08-01T00:00:00",
        organization: "Willowbrook Elementary Academy",
        role: "Teacher",
        startDate: "2023-01-01T00:00:00",
        tags: ["EXP_TAG_K_5", "EXP_TAG_6_8"],
        type: "SUBSTITUTE_TEACHER",
      },
      {
        id: 33333,
        endDate: "2021-09-01T00:00:00",
        organization: "Swing Summer Camp",
        role: "Camp Counselor",
        startDate: "2018-11-01T00:00:00",
        tags: ["EXP_TAG_6_8"],
        type: "SMALL_GROUP_INSTRUCTION",
      },
    ],
    skillbuilder: ["classroom-management.skill-check/outro"],
  },
};

export const profileDataPartialPayload: {
  data: ProfilePUTPayload;
} = {
  data: {
    about: "This is an updated about me section.",
  },
};

export const schoolProfileData: SchoolProfileData = {
  name: "Arendelle Elementary",
  address: {
    street: "1234 Rainbow Lane",
    city: "Harmony Hills",
    state: "CA",
    zip: "98765",
  },
  dayOfContact: "Ghostbusters",
  dayOfPhone: "123-456-7891",
  dayOfEmail: "ghost@busters.com",
  parkingCost: "$12",
  parkingLocation: "100 meters away",
  checkinLocation: "Front door",
  checkinProcedure: "Knock",
  checkoutLocation: "Side door",
  checkoutProcedure: "Irish Goodbye",
  subPolicies: {
    emergencyProtocol: "See emergency procedure manual, map and go-bag all located in classrooms. ",
    lockdownProtocol: "See Emergency Procedure Manual. ",
    leavingClassroom: "There must be a teacher in the classroom at all times. ",
    lunchProtocol: "30 minute lunch should be taken during designated time.",
    dressCode: "Business casual. Jeans on Friday are acceptable. ",
    escalation: "Contact the front office by phone/radio if you need assistance or panic button. ",
    electronics: "Phones are accessible. Cell phones should not be used in classrooms. ",
    takingAttendance:
      "Take attendance on a FOCUS generated attendance roster report. This will be given to you when you check in.\n\n                                                                  *Always use ink.\n*Take attendance at the start of class.\n\n                                                                  *Clearly call out the names of each student on attendance roster.\n\n                                                                  *If student is present leave blank.\n*If the student is absent from class, place an A on line next to absent student.\n\n                                                                  *Attendance roster must be signed and dated by the substitute teacher.\n\n                                                                  *Get the attendance roster to the attendance office as soon as possible.\n\n                                                                  *Questions? Refer to the attendance office with questions or concerns\nPlease Note:\n\n                                                                  *At no time ever is attendance be taken by a student.\n\n                                                                  *Only a classroom attendance roster may be used for taking attendance.\n",
    codeWords: "N/A",
  },
  studentPolicies: {
    leavingClassroom: "Procedures vary by grade",
    lunchProtocol: "Students should be escorted to and from cafeteria",
    dressCode: "See student handbook",
    electronics: "Student should not have electronics out during the school day. ",
  },
  websiteUrl: "swingeducation.com",
  summary: "This is Arendelle Univeristy. We have lovely staff and we have winter all year round.",
};

export const referralDataResponse: { data: ReferralData } = {
  data: {
    id: "abc123",
    accountId: "abc123",
    firstName: "Demo",
    lastName: "User",
    email: "demouser@example.com",
    locale: "en_US",
    referable: false,
    customFields: {
      city: "San Jose",
      utmTerm: "term",
      state: "CA",
      region: ":region/ca.bay-area",
      zip: "95139",
      utmContent: "content",
      utmCampaign: "campaign",
      utmMedium: "medium",
      utmSource: "indeed",
    },
  },
};
