import { jsxs as n } from "react/jsx-runtime";
function r() {
  return /* @__PURE__ */ n("span", {
    style: {
      color: "var(--ion-color-danger)",
      fontSize: "var(--swing-font-lg)",
      fontWeight: "var(--swing-font-medium)"
    },
    children: [" ", "*"]
  });
}
export {
  r as Asterisk
};
