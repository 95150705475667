import { IonChip, IonLabel } from "@ionic/react";
import { CSSProperties } from "react";
import { Colors, Icon, IconName } from "swing-components";

import { ValuesOf } from "~utils";

/***** Types *****/
export type ChipColor = "PURPLE" | "RED" | "TEAL" | "SLATE" | "GREEN" | "YELLOW" | "WHITE";

export type ChipProps = {
  color: ChipColor;
  label: string;
  icon?: IconName;
};

/***** Constants *****/
// Maps the chips status to the Ion-Chip color (these colors are named vs being
// a hex). See https://ionicframework.com/docs/api/chip#colors

const CHIP_COLOR: Record<ChipColor, ValuesOf<typeof Colors>> = {
  PURPLE: Colors.purple200,
  SLATE: Colors.slate200,
  TEAL: Colors.teal200,
  RED: Colors.red200,
  GREEN: Colors.green200,
  YELLOW: Colors.yellow200,
  WHITE: Colors.white300,
};

const ICON_COLOR: Record<ChipColor, ValuesOf<typeof Colors>> = {
  PURPLE: Colors.purple500,
  SLATE: Colors.slate600,
  TEAL: Colors.teal500,
  RED: Colors.red500,
  GREEN: Colors.green500,
  YELLOW: Colors.yellow600,
  WHITE: Colors.white300,
};

/**
 * Component | Chip
 *
 * Renders a chip/tag/badge to handle various statuses
 *
 * @Figma https://www.figma.com/file/GPTogwtUYLEcr9yz9skF5M/Swing-Ionic-Design-System?type=design&node-id=656-18368
 */
export function Chip(props: ChipProps) {
  const { color, label, icon } = props;

  return (
    <IonChip
      style={
        {
          width: "max-content", // To make sure that the chip doesn't stretch
          // Removes the default margin which throws off alignment when used
          // inline
          margin: 0,
          gap: 4,
          // The padding used by IonChip is 7px which on a 32px height chip, will
          // leave less than 20px for the content. This fixes that.
          paddingTop: 4,
          paddingBottom: 4,
          // Removes the pointer cursor since this is not clickable anymore.
          cursor: "auto",
          pointerEvents: "none",
          // Remove the ability for users to select the text within this
          // container. When attempting to double click on the chip,
          // nothing will be highlighted.
          userSelect: "none",
          backgroundColor: CHIP_COLOR[color],
          color: Colors.black,
        } as CSSProperties
      }
    >
      {/* Left Icon */}
      {icon ? <Icon name={icon} color={ICON_COLOR[color]} /> : null}

      {/* Label */}
      <IonLabel style={{ fontWeight: "var(--swing-font-normal)" }}>{label}</IonLabel>
    </IonChip>
  );
}
