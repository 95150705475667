type SectionProps = {
  header?: string;
  description?: string;
  items: React.ReactNode[];
};

export function Section(props: SectionProps) {
  const { header, description, items } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--spacing-lg)" }}>
      {header && (
        <div style={{ fontWeight: "var(--swing-font-semibold)" }}>
          {header} ({items.length})
        </div>
      )}
      {description && <div>{description}</div>}
      {items}
    </div>
  );
}
