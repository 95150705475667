import { IonText } from "@ionic/react";
import { Colors, Icon } from "swing-components";

import { Card } from "~components";
import { createRequestUrl } from "~pages";
import {
  formatDisplayTimes,
  formatGradesAndSubjects,
  formatPay,
  generateGoogleMapsLink,
  msg,
  OpeningsData,
} from "~utils";
import styles from "./OpeningsCard.module.css";

export type OpeningsCardProps = {
  request: OpeningsData;
};

export function OpeningsCard(props: OpeningsCardProps) {
  const { request } = props;

  const requestUrl = createRequestUrl(request.id);
  const requestDuration = request.intervals.length;

  return (
    <Card isBorderRadius isBoxShadow href={requestUrl}>
      <div className={styles["openings-card-wrapper"]}>
        <div className={styles["info-grid-row"]} style={{ paddingBottom: "8px" }}>
          <Icon name={request.isMultiDay ? "Calendar Overlap" : "Calendar"} />
          <div>
            <span className={styles["date-row"]}>{request.displayDate}</span>
            <span style={{ color: Colors.slate400, fontSize: "12px" }}>{requestDuration}</span>
            {!request.isMultiDay ? (
              <span style={{ color: Colors.slate400, fontSize: "12px" }}>
                {" "}
                {msg("OPENINGS_CARD_DATE_SINGLE_DAY")}
                {", "}
                {formatDisplayTimes(request.displayTime || "")}
              </span>
            ) : (
              <span style={{ color: Colors.slate400, fontSize: "12px" }}>
                {" "}
                {msg("OPENINGS_CARD_DATE_MULTI_DAY")}
              </span>
            )}
          </div>
          <div className={styles["pay-wrapper"]}>
            {request.payment && (
              <IonText className={styles["date-row"]}>
                {formatOpeningsPay(formatPay(request.payment.isHourly, request.payment.payValue))}
              </IonText>
            )}
            {!request.payment?.isHourly ? (
              <span style={{ color: Colors.slate400 }} className={styles["pay-descriptor"]}>
                {msg("OPENINGS_CARD_PAY_NON_HOURLY")}
              </span>
            ) : (
              <span style={{ color: Colors.slate400 }} className={styles["pay-descriptor"]}>
                {msg("OPENINGS_CARD_PAY_HOURLY")}
              </span>
            )}
          </div>
        </div>
        <div className={styles.divider}></div>
        <div>
          <div className={styles["info-row"]}>
            <Icon name="School" />
            <div>
              <span
                style={{ fontWeight: "var(--swing-font-semibold)" }}
                className={styles["truncate-two-lines"]}
              >
                {request.school.name}
              </span>
              {request.school.address && (
                <a
                  className={styles["school-address"]}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={generateGoogleMapsLink(request.school.address)}
                >
                  {request.school.address.street && (
                    <IonText className={styles["truncate-one-line"]}>
                      {request.school.address.street}
                    </IonText>
                  )}
                  <IonText className={styles["truncate-one-line"]}>
                    {formatCityStateZip(request.school.address)}
                  </IonText>
                </a>
              )}
            </div>
          </div>
          <div className={`${styles["info-row"]}`}>
            <IonText className={`${styles["truncate-two-lines"]} ${styles["grade-subject-row"]}`}>
              {formatGradesAndSubjects(request.grades || [], request.subjects || [])}
            </IonText>
          </div>
        </div>
      </div>
    </Card>
  );
}

const formatOpeningsPay = (pay: string) => {
  return pay.replace("/hr", "");
};

export function formatCityStateZip(address?: OpeningsData["school"]["address"]) {
  if (!address) return "";
  const { city, state, zip } = address;
  /**
   * city | state | zip
   *  -      -      -
   *  x      -      -
   *  -      x      -
   *  -      -      x
   *  x      x      -
   *  x      -      x
   *  -      x      x
   *  x      x      x
   */
  if (!city && !state && !zip) return "";
  if (city && !state && !zip) return city;
  if (!city && state && !zip) return state;
  if (!city && !state && zip) return zip;
  if (city && state && !zip) return `${city}, ${state}`;
  if (city && !state && zip) return `${city}, ${zip}`;
  if (!city && state && zip) return `${state} ${zip}`;
  if (city && state && zip) return `${city}, ${state} ${zip}`;
}
