import { ReactNode } from "react";

import styles from "./ContentDualColumn.module.css";

type ContentDualColumnProps = {
  contentA: ReactNode;
  contentB: ReactNode;
};

// Should be used as the direct child component of the `Page` component
export function ContentDualColumn(props: ContentDualColumnProps) {
  const { contentA, contentB } = props;

  return (
    <div className={styles["content-dual-column"]}>
      <div>{contentA}</div>
      <div>{contentB}</div>
    </div>
  );
}
