import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Colors, Icon, IconName } from "swing-components";

import { ValuesOf } from "~utils";
import styles from "./Toast.module.css";

export type ToastType = "neutral" | "positive" | "negative";

type ToastProps = {
  text: string;
  type: ToastType;
  isVisible: boolean;
  onDismiss: () => void;
};

type IconProps = {
  name: IconName;
  color: ValuesOf<typeof Colors>;
};

export const ToastIcon: Record<ToastType, IconProps> = {
  neutral: { name: "Info", color: Colors.blue500 },
  positive: { name: "Check Circle", color: Colors.green500 },
  negative: { name: "Alert", color: Colors.red500 },
};

export function Toast(props: ToastProps) {
  const { text, type, isVisible, onDismiss } = props;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const TOAST_TIMEOUT = 5000;

  useEffect(() => {
    if (isVisible) {
      timeoutRef.current = setTimeout(onDismiss, TOAST_TIMEOUT);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [isVisible, onDismiss]);

  const handleDismissToast = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    onDismiss();
  };

  if (!isVisible) return null;

  const toastContent = (
    <div className={styles.container} onClick={handleDismissToast} role="status">
      <div className={styles.iconContainer}>
        <Icon {...ToastIcon[type]} />
      </div>
      <span style={{ color: Colors.white200 }}>{text}</span>
    </div>
  );

  return createPortal(toastContent, document.body);
}
