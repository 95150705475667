import { useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import type { ExtractRouteParams, RouteComponentProps } from "react-router";
import { generatePath } from "react-router-dom";

import { ContentSingleColumn, useAuth } from "~components";
import { OnboardingPage } from "~onboarding/components";
import { computeGroupsState, getConfiguration } from "~onboarding/utils";
import { GET, SubAttribute } from "~utils";
import { ONBOARDING_ROUTES } from "../index";
import { TasksPageBase } from "./TasksPageBase";

/***** Component *****/
type TasksPageProps = RouteComponentProps<
  ExtractRouteParams<(typeof ONBOARDING_ROUTES)["tasks"], string>
>;

/**
 * A page which shows the onboarding sub all the tasks they must complete
 * before to finish the onboarding process
 *
 * Assumptions:
 * - The sub is authenticated via `useAuth`
 * - The sub is not rejected via `onboardingStatus === "STARTED"`
 */
export function TasksPage(props: TasksPageProps) {
  const { history, match } = props;
  const { region } = match.params;

  /***** Hooks *****/
  const { refreshUser } = useAuth();

  /***** Queries ******/
  const { data: groupsComputed } = useQueries({
    queries: [
      {
        queryKey: ["configuration", region],
        queryFn: () => getConfiguration(region),
        // Cache the configuration for the lifetime of the application since this
        // only changes on new deployments which will require an application refresh.
        // This will make all future calls using this key to not make a network request.
        staleTime: Infinity,
      },
      {
        queryKey: ["attributes"],
        queryFn: () => GET("/api/sub/attributes"),
        // Since the response is `{ data: [...subAttribute] }` we can clean up the response
        select: (data) =>
          // @ts-expect-error - @KoltonG for some reason, the types are not
          // passing through when using `useQueries`.
          data?.data.map((attribute) => ({
            ...attribute,
            updatedAt: new Date(attribute.updatedAt),
          })) as Array<SubAttribute>,
      },
    ],
    combine: (results) => {
      const configuration = results[0].data;
      const subAttributes = results[1].data;

      if (!configuration || !subAttributes) {
        return { data: undefined };
      }

      return {
        data: computeGroupsState({
          groups: configuration.groups,
          syncedSubAttributes: subAttributes,
        }),
      };
    },
  });

  /***** Effects *****/
  /**
   * When the page mounts, refresh the sub's authentication details in case
   * they were rejected via `onboardingStatus === "REJECTED"`.
   */
  useEffect(() => {
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***** Handlers *****/
  /** Routes to the Task page */
  function handleTaskClick(taskIndex: number) {
    history.push(generatePath(ONBOARDING_ROUTES["task"], { region, task: taskIndex }));
  }

  /***** Render *****/
  return (
    <OnboardingPage isLoading={!groupsComputed}>
      <ContentSingleColumn>
        {groupsComputed && (
          <TasksPageBase groupsComputed={groupsComputed} onTaskClick={handleTaskClick} />
        )}
      </ContentSingleColumn>
    </OnboardingPage>
  );
}
