import type {
  Group,
  GroupComputed,
  GroupStatus,
  Question,
  QuestionComputed,
  Step,
  StepComputed,
  Task,
  TaskComputed,
} from "~onboarding/utils";
import type { SubAttribute } from "~utils";

/********** Types **********/
/**
 * Represents a local change in the shape of a Sub Attribute where only the
 * `attribute` field is required, while all others are optional.
 *
 * NOTE:
 * - `value` field is optional, and omission of this field means that the value
 * has been removed.
 */
export type UnsyncedSubAttribute = Pick<SubAttribute, "attribute"> &
  Partial<Omit<SubAttribute, "attribute">>;

/********** Group Computations **********/
/** Given static Groups and attributes, return the computed Groups */
export function computeGroupsState(params: {
  groups: Group[];
  syncedSubAttributes?: Array<SubAttribute>;
}): GroupComputed[] {
  const { groups, syncedSubAttributes = [] } = params;

  return groups.reduce<GroupComputed[]>((groupComputed, group) => {
    const tasks = group.tasks.map((task) => computeTaskState({ task, syncedSubAttributes }));

    const previousGroupStatus = groupComputed.at(-1)?.status;
    let status: GroupStatus = "IN_PROGRESS"; // Default each group to IN_PROGRESS
    // The Group is blocked when the previous Group is not completed
    if (previousGroupStatus === "IN_PROGRESS" || previousGroupStatus === "BLOCKED")
      status = "BLOCKED";
    // The Group is completed when all Tasks are complete
    else if (tasks.every((task) => task.status === "COMPLETED")) status = "COMPLETED";

    return [
      ...groupComputed,
      {
        tasks,
        status,
      },
    ];
  }, [] as GroupComputed[]);
}

/********** Task Computations **********/
export function computeTaskState(params: {
  task: Task;
  syncedSubAttributes?: Array<SubAttribute>;
  unsyncedSubAttributes?: Array<UnsyncedSubAttribute>;
}): TaskComputed {
  const { task, syncedSubAttributes = [], unsyncedSubAttributes = [] } = params;

  const steps = task.steps
    .map((step) => computeStepState({ step, syncedSubAttributes, unsyncedSubAttributes }))
    // Filtering is required since Steps which are no longer valid due to
    // branching will be returned as `null`.
    .filter((step) => step !== null);

  // Compute the remaining fields based on the filtered Steps
  const status = computeStepOrTaskStatus(steps);
  const progress = computeTaskProgress({ steps, status });
  const updatedAt = computeStepOrTaskUpdatedAt(steps);

  return { ...task, steps, status, progress, updatedAt };
}

/**
 * Returns the Task progress based on the answered questions / total questions.
 *
 * NOTE: We are using answered question instead of completed questions since
 * this progress calculation is performed with unsynced changes as well.
 */
export function computeTaskProgress(params: {
  steps: Array<StepComputed>;
  status?: TaskComputed["status"];
}): number {
  const { steps, status } = params;

  // If the status is `UNDER_REVIEW` then the progress should be 1 (100%)
  if (status === "UNDER_REVIEW") return 1;

  // Otherwise, the progress is the answered questions / total questions
  const questions = steps.flatMap((step) => step.questions);
  const totalQuestions = questions.length;
  const answeredQuestions = questions.filter((question) => question.value !== undefined).length;

  return answeredQuestions / totalQuestions;
}

/********** Step Computations **********/
/** Returns a StepComputed type */
export function computeStepState(params: {
  step: Step;
  syncedSubAttributes?: Array<SubAttribute>;
  unsyncedSubAttributes?: Array<UnsyncedSubAttribute>;
}): StepComputed | null {
  const { step, syncedSubAttributes = [], unsyncedSubAttributes = [] } = params;

  // When `appearsWhen` is defined
  if (step.appearsWhen) {
    // For each identifier in `appearsWhen`, if any identifier match and there
    // are no matching values, then return false.
    const result = Object.entries(step.appearsWhen).every(([identifier, values]) => {
      const matchingSubAttribute = [...syncedSubAttributes, ...unsyncedSubAttributes].find(
        (subAttribute) => subAttribute.attribute === identifier,
      );
      // If there is matching sub attribute with a value (since `undefined` is
      // a valid for inputs when deleting all the typed values and in this case
      // we would not want to filter out any Steps) and there is no matching
      // Step `appearWhen` value, then return false.
      if (
        matchingSubAttribute &&
        matchingSubAttribute.value &&
        !values.includes(
          // NOTE: We need to handle objects values. Since we don't officially
          // have a use case, we setup the code to support this in the future.
          typeof matchingSubAttribute.value === "string"
            ? matchingSubAttribute.value
            : JSON.stringify(matchingSubAttribute.value),
        )
      ) {
        return false;
      }
      return true;
    });

    // If there are any mismatches, return null
    if (result === false) return null;
  }

  const questions = step.questions.map((question) =>
    computeQuestionState({
      question,
      syncedSubAttribute: syncedSubAttributes.find((ssa) => ssa.attribute === question.attribute),
      unsyncedSubAttribute: unsyncedSubAttributes.find(
        (usa) => usa.attribute === question.attribute,
      ),
    }),
  );

  // Compute the remaining fields based on the computed Questions
  const status = computeStepOrTaskStatus(questions);
  const updatedAt = computeStepOrTaskUpdatedAt(questions);

  return { ...step, questions, status, updatedAt };
}

/** Computes the status of the a Step or Task */
export function computeStepOrTaskStatus<TItem extends QuestionComputed | StepComputed>(
  items: Array<TItem>,
): TItem["status"] {
  const incompleteItems = items.filter((item) => item.status !== "COMPLETED");

  // When all Questions or Steps are `COMPLETED`, then the Step or Task is
  // `COMPLETED`
  if (incompleteItems.length === 0) return "COMPLETED";
  // Otherwise, return the first Question or Step's status which can either be
  // `undefined`, `UNDER_REVIEW`, or `ACTION_REQUIRED`.
  return incompleteItems[0].status;
}

/** Computes the `updatedAt` field for the computed Step or Task */
export function computeStepOrTaskUpdatedAt<TItem extends QuestionComputed | StepComputed>(
  items: Array<TItem>,
): TItem["updatedAt"] {
  const answeredItems = items.filter((item) => item.status !== undefined);

  // Bail when there are no answered items
  if (answeredItems.length === 0) return undefined;

  // Sort the `updatedAt` values in DESC order and return the last value
  const sortedUpdatedAts = answeredItems
    .filter((item) => item.updatedAt !== undefined)
    .map<Date>((item) => item.updatedAt!)
    .sort((a, b) => (a.toISOString() > b.toISOString() ? -1 : 1));

  return sortedUpdatedAts.at(0);
}

/********** Question Computations **********/
/**
 * Given a static definition of a Question and maybe Sub Attributes, return
 * the computed version of the Question.
 *
 * The one rule that this follows, is:
 * - If a Question is in a `ACTION_REQUIRED`, strip the value as it is not valid. This helps the UI
 *   reset.
 */
export function computeQuestionState(params: {
  question: Question;
  syncedSubAttribute?: SubAttribute;
  unsyncedSubAttribute?: UnsyncedSubAttribute;
}): QuestionComputed {
  const { question, syncedSubAttribute, unsyncedSubAttribute } = params;

  /** Map computed question fields between synced or unsynced sub attribute **/
  const status = syncedSubAttribute?.status ?? unsyncedSubAttribute?.status;

  // Ignoring the value when the synced sub attribute status is
  // `ACTION_REQUIRED` since the API has deemed this value to not be valid and
  // we don't want to show the invalid value to the user.
  const value =
    syncedSubAttribute?.status === "ACTION_REQUIRED"
      ? undefined
      : syncedSubAttribute?.value ?? unsyncedSubAttribute?.value;

  const actionRequiredMarkdown =
    syncedSubAttribute?.actionRequiredMarkdown ?? unsyncedSubAttribute?.actionRequiredMarkdown;

  const updatedAt = syncedSubAttribute?.updatedAt ?? unsyncedSubAttribute?.updatedAt;

  return {
    ...question,
    status,
    value,
    actionRequiredMarkdown,
    updatedAt,
  };
}
