import { IconBlockGroup } from "swing-components";
import { generateSwingRootURL, generateSwingSupportURL } from "swing-utils";

import { ContentNavigationItem, ContentSingleColumn } from "~components";
import { msg, openLinkInApp, openZendeskSupport, useIsDesktop } from "~utils";
import { ScorePage } from "../../ScoreTemplates";
import styles from "./Help.module.css";

export function Help() {
  const isDesktop = useIsDesktop();

  return (
    <ScorePage title={msg("MORE_HELP")} hasBack={!isDesktop}>
      <ContentSingleColumn>
        <div className={styles["content-wrapper"]}>
          <IconBlockGroup>
            <ContentNavigationItem
              icon={{ name: "Help" }}
              label={msg("HELP_HELP_CENTER")}
              navigationIcon="External Link"
              onClick={() => {
                openLinkInApp(generateSwingSupportURL(msg("SWING_SUPPORT_LINK_HELP_CENTER")));
              }}
              hasWhiteBackground={false}
            />
            <ContentNavigationItem
              icon={{ name: "Chat Bubble Active" }}
              label={msg("HELP_CHAT_WITH_SUPPORT")}
              navigationIcon="Chevron Right"
              onClick={openZendeskSupport}
              hasWhiteBackground={false}
            />
            <ContentNavigationItem
              icon={{ name: "Phone" }}
              label={`${msg("HELP_CALL_OR_TEXT")}: ${msg("HELP_SUPPORT_SUB_NUMBER")}`}
              hasWhiteBackground={false}
            />
            <ContentNavigationItem
              icon={{ name: "Policy" }}
              label={msg("HELP_PRIVACY_POLICY")}
              navigationIcon="External Link"
              onClick={() => {
                openLinkInApp(generateSwingRootURL(msg("SWING_LINK_TERMS_OF_SERVICE")));
              }}
              hasWhiteBackground={false}
            />
          </IconBlockGroup>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}
