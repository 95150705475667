import { IonInput, IonItem, IonRadio, IonRadioGroup, RadioGroupCustomEvent } from "@ionic/react";
import { useState } from "react";
import { AlertBox, Asterisk, Colors, Icon } from "swing-components";

import { AlertBoxError, ButtonScore } from "~components";
import { CancelPenaltyType, msg } from "~utils";

import "./ModalCancelCommitments.css"; // Note .css as ionic overrides needed

export const CancelReason: Record<CancelReasonType, string> = {
  CANCEL_REASON_SCHEDULE_CONFLICT: msg("CANCEL_REASON_SCHEDULE_CONFLICT"),
  CANCEL_REASON_SICKNESS: msg("CANCEL_REASON_SICKNESS"),
  CANCEL_REASON_CLICKED_BY_ACCIDENT: msg("CANCEL_REASON_CLICKED_BY_ACCIDENT"),
  CANCEL_REASON_OTHER: msg("LABEL_OTHER"),
};

export type CancelReasonType =
  | "CANCEL_REASON_SCHEDULE_CONFLICT"
  | "CANCEL_REASON_SICKNESS"
  | "CANCEL_REASON_CLICKED_BY_ACCIDENT"
  | "CANCEL_REASON_OTHER";

export type ModalCancelCommitmentsProps = {
  message: string;
  hasApiError: boolean;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  onConfirm: (cancelPayload: CancelFormState) => void;
  penaltyType: CancelPenaltyType;
  title: string;
};

export type CancelFormState = {
  reason?: CancelReasonType;
  explanation?: string | null | undefined;
};

export function ModalCancelCommitments(props: ModalCancelCommitmentsProps) {
  const { message, hasApiError, onDismiss, onConfirm, penaltyType, title } = props;
  const [cancelFormData, setCancelFormData] = useState<CancelFormState>({});

  function onReasonChange(value: RadioGroupCustomEvent) {
    const val = value.target.value;
    setCancelFormData((prevState) => ({
      ...prevState,
      reason: val,
    }));
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-header">
        <h2>{title}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={() => onDismiss()} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <div className="modal-body">
        {hasApiError && <AlertBoxError />}
        <PenaltyAlertBox penaltyType={penaltyType} />
        <form
          id="cancel-form"
          onSubmit={(e) => {
            e.preventDefault();
            // clear the explanation field if option isn't "Other"
            // Note: clear / remove explanation field here so user can easily jump between
            // options and keep any provided explanation intact prior to submitting
            if (!isOther(cancelFormData.reason)) {
              // do not include in payload
              delete cancelFormData.explanation;
            }
            onConfirm(cancelFormData);
          }}
        >
          <p>
            {message}
            <Asterisk />
          </p>
          <IonRadioGroup value={cancelFormData.reason} onIonChange={(e) => onReasonChange(e)}>
            {Object.keys(CancelReason).map((reason) => {
              return (
                <div key={reason}>
                  <IonRadio
                    className="cancel-radio-answer"
                    style={{ "--color": Colors.blue500 }}
                    value={reason}
                    labelPlacement="end"
                  >
                    {CancelReason[reason as CancelReasonType]}
                  </IonRadio>
                </div>
              );
            })}
          </IonRadioGroup>
          {isOther(cancelFormData.reason) && (
            <IonItem lines="inset" className="ion-no-padding modal-cancel-other-input">
              <IonInput
                placeholder={msg("CANCEL_COMMITMENT_MODAL_OTHER_PLACEHOLDER")}
                onIonInput={(e) =>
                  setCancelFormData({ ...cancelFormData, explanation: e.detail.value })
                }
              />
            </IonItem>
          )}
        </form>
      </div>

      <div className="modal-footer">
        <div className="modal-footer-button-spacing">
          <ButtonScore
            disabled={isDisabled(cancelFormData)}
            fill="solid"
            type="submit"
            form="cancel-form"
            label={msg("CANCEL_COMMITMENT_MODAL_BUTTON_SUBMIT")}
          />
        </div>

        <ButtonScore
          expand="block"
          fill="outline"
          onClick={() => onDismiss()}
          label={msg("CANCEL_COMMITMENT_MODAL_BUTTON_CANCEL")}
        />
      </div>
    </div>
  );
}

/*
 * Return `true` when there's no reason OR when reason is 'other' AND no explanation; otherwise `false`.
 */
export function isDisabled(cancelFormData: CancelFormState) {
  return (
    !cancelFormData.reason ||
    (isOther(cancelFormData.reason) && !cancelFormData.explanation?.trim())
  );
}

function isOther(cancelReason: CancelReasonType | undefined): boolean {
  return !!cancelReason && CancelReason[cancelReason] === CancelReason.CANCEL_REASON_OTHER;
}

type penaltyAlertBoxProps = {
  penaltyType: CancelPenaltyType;
};

function PenaltyAlertBox(props: penaltyAlertBoxProps) {
  const { penaltyType } = props;

  switch (penaltyType) {
    case "PENALTY_REQUEST_NOT_STARTED":
      return (
        <AlertBox
          className="penalty-alert-box"
          color="warning"
          showIcon
          title={msg("CANCEL_LAST_MINUTE_TITLE")}
        >
          {msg("CANCEL_LAST_MINUTE_WITHIN_DAY")}. {msg("CANCEL_LAST_MINUTE_IMPACT_MESSAGE")}.
        </AlertBox>
      );
    case "PENALTY_REQUEST_STARTED":
      return (
        <AlertBox
          className="penalty-alert-box"
          color="warning"
          showIcon
          title={msg("CANCEL_LAST_MINUTE_TITLE")}
        >
          {msg("CANCEL_LAST_MINUTE_ALREADY_STARTED")}. {msg("CANCEL_LAST_MINUTE_IMPACT_MESSAGE")}.
        </AlertBox>
      );
    default:
      return <></>;
  }
}
