import { ReactNode } from "react";
import { Colors, Icon } from "swing-components";

import { AlertBoxError, ButtonScore } from "~components";

import "./ModalSubProfileEdit.css"; // Note .css as ionic overrides needed

type ModalSubProfileEditProps = {
  children: ReactNode;
};

export function ModalSubProfileEdit(props: ModalSubProfileEditProps) {
  const { children } = props;

  return <div className="modal-wrapper">{children}</div>;
}

type ModalSubProfileEditHeaderProps = {
  onDismiss: (data?: string | number, role?: string) => void;
  title: string;
};

export function ModalSubProfileEditHeader(props: ModalSubProfileEditHeaderProps) {
  const { onDismiss, title } = props;

  return (
    <div className="modal-header">
      <h2>{title}</h2>
      {/* FIXME: when design system button is available */}
      <button onClick={() => onDismiss()} style={{ backgroundColor: "transparent" }}>
        <Icon name="Close" color={Colors.blue500} />
      </button>
    </div>
  );
}

type ModalSubProfileEditBodyProps = {
  children: ReactNode;
  hasApiError?: boolean;
};

export function ModalSubProfileEditBody(props: ModalSubProfileEditBodyProps) {
  const { children, hasApiError } = props;

  return (
    <div className="modal-body">
      {hasApiError && <AlertBoxError />}
      {children}
    </div>
  );
}

type ModalSubProfileEditFooterProps = {
  buttonPrimary: {
    onClick?: () => void;
    isDisabled: boolean;
    text: string;
  };
  buttonSecondary?: {
    onClick?: () => void;
    isDisabled: boolean;
    text: string;
  };
};

export function ModalSubProfileEditFooter(props: ModalSubProfileEditFooterProps) {
  const { buttonPrimary, buttonSecondary } = props;

  // handle layout based on existence of secondary button
  const layoutClassName = buttonSecondary ? "two-buttons" : "";

  return (
    <div className={`modal-footer ${layoutClassName}`}>
      {buttonSecondary && (
        <div className="action-button">
          <ButtonScore
            fill="outline"
            disabled={buttonSecondary.isDisabled}
            label={buttonSecondary.text}
            onClick={!buttonSecondary.isDisabled ? buttonSecondary.onClick : undefined}
          />
        </div>
      )}
      {buttonPrimary && (
        <div className="action-button">
          <ButtonScore
            disabled={buttonPrimary.isDisabled}
            label={buttonPrimary.text}
            expand="block"
            onClick={!buttonPrimary.isDisabled ? buttonPrimary.onClick : undefined}
          />
        </div>
      )}
    </div>
  );
}
