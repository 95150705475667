import { Colors } from "swing-components";

import { Checkbox, MarkdownContent } from "~components";
import type { CheckboxAnswerType } from "~onboarding/utils/types";
import { isArrayOfString } from "~utils";

type CheckboxAnswerProps = Omit<CheckboxAnswerType, "type"> & {
  /**
   * The value of the answer which will be de-serialized into an array of
   * values.
   */
  value?: string;
  /**
   * When an option is selected, pass the serialized array of values to the
   * parent.
   */
  onChange: (value?: string) => void;
  /**
   * Disables the editability of the answer.
   */
  isReadOnly?: boolean;
};

function parseValueToSet(value: string | undefined) {
  if (value) {
    try {
      const values = JSON.parse(value);
      isArrayOfString(values);
      return new Set(values);
    } catch (e) {
      return new Set();
    }
  }
  return new Set();
}

export function CheckboxAnswer(props: CheckboxAnswerProps) {
  const { options, value, onChange, isReadOnly } = props;
  const valuesSet = parseValueToSet(value);

  function handleOnChange(value: string) {
    valuesSet.has(value) ? valuesSet.delete(value) : valuesSet.add(value);

    const values = Array.from(valuesSet);

    // When there are no values selected, send `undefined` to the parent.
    if (values.length === 0) return onChange(undefined);
    // When there are values, stringify them and send them to the parent.
    onChange(JSON.stringify(values));
  }

  return (
    <div>
      {options.map(({ label, value }) => (
        <div key={value} style={{ borderBottom: `1px solid ${Colors.slate200}` }}>
          <Checkbox
            isDisabled={isReadOnly}
            label={<MarkdownContent body={label} />}
            isChecked={valuesSet.has(value)}
            onChange={() => handleOnChange(value)}
          />
        </div>
      ))}
    </div>
  );
}
