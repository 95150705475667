import { useEffect, useState } from "react";

import { CheckboxScore } from "~components";
import styles from "./LocationsList.module.css";

export type LocationProps = {
  isSelected: boolean;
  label: string;
  value: string;
  descriptionText?: string;
};

export type LocationsListProps = {
  locations: LocationProps[];
  /**
   * When an option is selected, pass the array of values to the
   * parent.
   */
  onChange: (locations: LocationProps[]) => void;
};

export function LocationsList(props: LocationsListProps) {
  const { locations, onChange } = props;
  const [_locations, _setLocations] = useState<LocationProps[]>(locations);
  // keep state in sync - avoid weird edge case of stale data from useState initial state
  useEffect(() => _setLocations(locations), [locations]);

  function handleOnChange(isChecked: boolean, value: LocationProps) {
    const tempLocations = _locations.map((location) => {
      if (location.value === value.value) {
        location.isSelected = isChecked;
      }
      return location;
    });
    onChange(tempLocations);
    _setLocations(tempLocations);
  }

  return (
    <div className={styles["location-list-wrapper"]}>
      {_locations.map((location, index) => (
        <div key={location.value}>
          <CheckboxScore
            label={location.label}
            isChecked={location.isSelected}
            onChange={(isChecked) => handleOnChange(isChecked, location)}
            descriptionText={location.descriptionText}
            descriptionTextState="secondary"
          />
          {index !== locations.length - 1 && <div className={styles["location-divider"]} />}
        </div>
      ))}
    </div>
  );
}
