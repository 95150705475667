/**
 * This file contains `Questions` that are shared between onboarding and post-onboarding
 * Changes in this file will be reflected in both of these experiences
 */
import { Question } from "..";

export const QUESTION_GRADE_LEVELS: Question = {
  attribute: "persona/grade-level",
  label: "What grade levels are you interested in?",
  answerType: {
    type: "CHECKBOX",
    options: [
      { label: "Pre-K to 2nd", value: "k-2" },
      { label: "3rd to 5th", value: "3-5" },
      { label: "6th to 8th", value: "6-8" },
      { label: "9th to 12th", value: "9-12" },
    ],
  },
};

export const QUESTION_LANGUAGES: Question = {
  attribute: "persona/language",
  label: "Which of these languages are you comfortable teaching in?",
  answerType: {
    type: "CHECKBOX",
    options: [
      { label: "English", value: "english" },
      { label: "Spanish", value: "spanish" },
      { label: "Chinese (Mandarin)", value: "mandarin" },
      { label: "Chinese (Cantonese)", value: "cantonese" },
      { label: "French", value: "french" },
      { label: "Tagalog", value: "tagalog" },
      { label: "Vietnamese", value: "vietnamese" },
      { label: "Arabic", value: "arabic" },
      { label: "Korean", value: "korean" },
      { label: "Japanese", value: "japanese" },
      { label: "German", value: "german" },
      { label: "American Sign Language", value: "asl" },
      { label: "Other", value: "other" },
    ],
  },
};

export const QUESTION_SUBJECTS: Question = {
  attribute: "persona/subject",
  label: "What subjects are you interested in?",
  answerType: {
    type: "CHECKBOX",
    options: [
      {
        label: "General Education",
        value: "gen-ed",
      },
      {
        label: "Electives (Music, Art, etc.)",
        value: "elective",
      },
      {
        label: "English/Language Arts",
        value: "english",
      },
      {
        label: "Health/Physical Education",
        value: "physical-education",
      },
      {
        label: "History/Social Studies",
        value: "social-studies",
      },
      {
        label: "Math",
        value: "math",
      },
      {
        label: "Science",
        value: "science",
      },
      {
        label: "Special Education",
        value: "special-education",
      },
      {
        label: "World Languages",
        value: "world-languages",
      },
      {
        label: "Other",
        value: "other",
      },
      {
        label: "I'm not sure yet",
        value: "not-sure",
      },
    ],
  },
};
