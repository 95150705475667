import { jsx as e, jsxs as r } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-DO6tcIND.js";
import c from "../../../../string-table.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as a } from "../Section/Section.js";
import '../../../../../css/Feedback.css';const i = "_quote_86dvx_1", m = "_quoteContent_86dvx_8", d = "_schoolName_86dvx_15", _ = "_noFeedback_86dvx_20", o = {
  quote: i,
  quoteContent: m,
  schoolName: d,
  noFeedback: _
};
function b(s) {
  const {
    feedbacks: t
  } = s;
  return /* @__PURE__ */ e(a, {
    title: c("SUB_PROFILE_FEEDBACK_FROM_SCHOOLS_HEADER"),
    children: /* @__PURE__ */ e("div", {
      children: t && t.length > 0 ? t.map((n, l) => /* @__PURE__ */ e("blockquote", {
        className: o.quote,
        children: /* @__PURE__ */ r("div", {
          className: o.quoteContent,
          children: [/* @__PURE__ */ e("p", {
            style: {
              margin: 0
            },
            children: /* @__PURE__ */ e("span", {
              children: /* @__PURE__ */ e("q", {
                children: /* @__PURE__ */ e("i", {
                  children: n.content
                })
              })
            })
          }), /* @__PURE__ */ e("footer", {
            className: o.schoolName,
            children: `- ${n.schoolName}`
          })]
        })
      }, `${n.schoolName}-${l}`)) : /* @__PURE__ */ e("span", {
        className: o.noFeedback,
        children: c("SUB_PROFILE_NO_INFORMATION")
      })
    })
  });
}
export {
  b as Feedback
};
