import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { AlertBoxError, ButtonScoreWrapper, ContentSingleColumn, TextAreaInput } from "~components";
import { createEducationLandingUrl, Education } from "~pages";
import {
  DELETE,
  GET,
  msg,
  ProfileData,
  ProfileDeletePayload,
  ProfilePUTPayload,
  PUT,
} from "~utils";
import { ScorePage } from "../../ScoreTemplates";
import styles from "./EducationNewEdit.module.css";

export function EducationNewEdit() {
  const params = useParams<{ educationId: string }>();

  const { data, error, isLoading } = useQuery<{ data: ProfileData }, AxiosError>({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
    enabled: !!params.educationId,
  });

  const selectedEducation = data?.data?.education?.find(
    (edu: Education) => edu.id === parseInt(params.educationId),
  );

  return <EducationNewEditView education={selectedEducation} isLoading={isLoading} error={error} />;
}

type EducationNewEditViewProps = {
  education?: Education;
  error?: AxiosError | null;
  isLoading?: boolean;
};

export function EducationNewEditView(props: EducationNewEditViewProps) {
  const { education, error, isLoading } = props;

  /* Hooks */
  const history = useHistory();
  const queryClient = useQueryClient();
  const [schoolName, setSchoolName] = useState<string | undefined>(education?.name);

  // Sync schoolName with props so the component is initialized with education on refresh
  useEffect(() => {
    if (education?.name) {
      setSchoolName(education.name);
    }
  }, [education?.name]);

  /* Updates */
  const handleUpdateSuccess = (updatedProfile: { data: ProfileData }) => {
    queryClient.setQueryData(["fetchProfile"], updatedProfile);
    history.push(createEducationLandingUrl());
  };

  const { mutate: updateProfileEducation, error: updateMutationError } = useMutation<
    { data: ProfileData },
    AxiosError,
    ProfilePUTPayload
  >({
    mutationFn: (payload: ProfilePUTPayload) => {
      return PUT("/api/sub/profile", payload);
    },
    onSuccess: handleUpdateSuccess,
  });

  const { mutate: deleteEducation, error: deleteMutationError } = useMutation<
    { data: ProfileData },
    AxiosError,
    ProfileDeletePayload
  >({
    mutationFn: (payload: ProfileDeletePayload) => {
      return DELETE("/api/sub/profile", payload);
    },
    onSuccess: handleUpdateSuccess,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!schoolName || schoolName.trim() === "") return;

    const newEducation = {
      name: schoolName.trim(),
      ...(education?.id && { id: education.id }),
    };

    updateProfileEducation({ education: newEducation });
  };

  const handleOnChange = (schoolName: string) => {
    setSchoolName(schoolName === "" ? undefined : schoolName);
  };

  const handleDelete = () => {
    if (!education?.id) return;
    deleteEducation({ education: { id: education.id } });
  };

  const isSaveDisabled = !schoolName || schoolName.trim() === education?.name;

  const PAGE_TITLE = education
    ? msg("PAGE_TITLE_EDUCATION_LANDING")
    : msg("PAGE_TITLE_EDUCATION_EDIT");

  return (
    <ScorePage title={PAGE_TITLE} isLoading={isLoading} hasBack>
      <ContentSingleColumn>
        {(error || updateMutationError || deleteMutationError) && <AlertBoxError />}
        {!error ? (
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.inputContainer}>
              <TextAreaInput
                placeholder={msg("PROFILE_EDIT_EDUCATION_INPUT_LABEL_AND_PLACEHOLDER")}
                value={schoolName}
                onChange={(value: string) => handleOnChange(value)}
                label={msg("PROFILE_EDIT_EDUCATION_INPUT_LABEL_AND_PLACEHOLDER")}
              />
            </div>
            <ButtonScoreWrapper
              buttonPrimary={{
                expand: "block",
                label: msg("LABEL_SAVE"),
                disabled: isSaveDisabled,
                type: "submit",
              }}
              {...(education && {
                buttonSecondary: {
                  expand: "block",
                  label: msg("LABEL_DELETE"),
                  onClick: handleDelete,
                  disabled: false,
                },
              })}
            />
          </form>
        ) : null}
      </ContentSingleColumn>
    </ScorePage>
  );
}
