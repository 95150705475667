declare global {
  interface Window {
    cloudinary: {
      openUploadWidget: (
        config: object,
        resultFunction: (error: object, result: CloudinaryResult) => void,
      ) => void;
    };
  }
}

export type CloudinaryResult = {
  event: string;
  info: { secure_url: string };
} | null;

export const openUploadWidget = (
  resultFunction: (error: object, result: CloudinaryResult) => void,
) => {
  try {
    window.cloudinary.openUploadWidget(
      // Reference docs for accepted arguments: https://cloudinary.com/documentation/upload_widget_reference
      {
        cloud_name: "swingeducation",
        upload_preset: "unsigned",
        cropping: "server",
        sources: ["local", "camera"],
        multiple: false,
        cropping_aspect_ratio: 1.0,
        cropping_show_dimensions: true,
        cropping_default_selection_ratio: 1.0,
        resource_type: "image",
        client_allowed_formats: ["jpg", "jpeg", "png"],
        theme: "minimal",
        inlineContainer: "#profileImageEditModal",
        styles: {
          frame: {
            background: "transparent",
          },
        },
      },
      (error: object, result: CloudinaryResult) => {
        resultFunction(error, result);
      },
    );
  } catch (e) {
    resultFunction({ message: "Failed to load cloudinary widget" }, null);
  }
};
