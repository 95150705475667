import {
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
} from "~components";
import { msg } from "~utils";

type ModalContentSchoolFeedbackSuccessProps = {
  onDismiss: () => void;
  title: string;
};

export function ModalContentSchoolFeedbackSuccess(props: ModalContentSchoolFeedbackSuccessProps) {
  const { onDismiss, title } = props;

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>{msg("SCHOOL_FEEDBACK_SUBMITTED_MSG")}</ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: false,
          text: msg("LABEL_CLOSE"),
          onClick: () => onDismiss(),
        }}
      />
    </ModalSubProfileEdit>
  );
}
