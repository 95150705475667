import { msg } from "~utils";

type SchoolProfileBaseProps = {
  value?: string;
  label?: string;
  className?: string;
};

export function SchoolProfileTextField(props: SchoolProfileBaseProps) {
  const { value, label, className } = props;
  return (
    <div style={{ padding: "0 16px" }} className={className}>
      <div style={{ display: "flex", width: "100%", gap: "16px" }}>
        <div>
          <p style={{ fontWeight: "var(--swing-font-semibold)" }}>{label}</p>
          <span>{value || <span className="italic">{msg("NOT_PROVIDED")}</span>}</span>
        </div>
      </div>
    </div>
  );
}
