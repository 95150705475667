import { TextInput } from "~components";
import { msg } from "~utils";

type VerificationCodeInputProps = {
  isDisabled: boolean;
  onChange: (val: string) => void;
  value?: string;
};

export function VerificationCodeInput(props: VerificationCodeInputProps) {
  const { value, onChange, isDisabled } = props;

  /***** Render *****/
  return (
    <div style={{ paddingTop: "8px" }}>
      <TextInput
        label={msg("VERIFICATION_CODE_INPUT_LABEL")}
        value={value}
        onChange={onChange}
        inputMode="numeric"
        pattern="[0-9]{4}"
        maxLength={4}
        placeholder={msg("VERIFICATION_CODE_INPUT_PLACEHOLDER")}
        isDisabled={isDisabled}
      />
    </div>
  );
}
