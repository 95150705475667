import { IonLabel, IonToggle } from "@ionic/react";
import { Colors } from "swing-components";

import { msg } from "~utils";

type ToggleProps = {
  label: string;
  isSelected?: boolean;
  onToggleChange: (e: boolean) => void;
};

export function Toggle(props: ToggleProps) {
  const { label, isSelected = false, onToggleChange } = props;
  // TODO try to remove Ionic components
  return (
    <div
      style={{
        display: "flex",
        padding: "16px 0",
        alignItems: "center",
        width: "inherit",
        justifyContent: "space-between",
        borderBottom: `1px solid ${Colors.slate200}`,
      }}
    >
      <IonLabel>
        <span style={{ fontWeight: "var(--swing-font-semibold" }}>{label}</span>
      </IonLabel>
      <IonToggle
        aria-checked={true}
        aria-label={
          isSelected
            ? `${label} ${msg("ARIA_LABEL_SELECTED")}`
            : `${label} ${msg("ARIA_LABEL_DESELECTED")}`
        }
        checked={isSelected}
        onIonChange={(e: CustomEvent) => {
          onToggleChange(e.detail.checked);
        }}
        slot="end"
      />
    </div>
  );
}
