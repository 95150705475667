import { IonContent, IonPage } from "@ionic/react";
import { ReactComponentElement, Ref, RefObject } from "react";
import { Colors } from "swing-components";

import {
  AliasBanner,
  ContentDualColumn,
  ContentSingleColumn,
  DesktopNavigation,
  DesktopNavigationProps,
  LoadingInterstitial,
  MobileNavigation,
  MobileNavigationProps,
} from "~components";
import { msg } from "~utils";
import styles from "./Page.module.css";

export type PageProps = {
  /**
   * When true, show a loading animation and hide the children content.
   * The navigation will still be showing.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Props for the desktop navigation.
   */
  desktopNavigationProps: DesktopNavigationProps;
  /**
   * Props for the mobile navigation
   */
  mobileNavigationProps: MobileNavigationProps;
  /**
   * Ref for scroll to top of page
   */
  reference: Ref<HTMLIonContentElement>;
  /**
   * Children to render when `isLoading` is false.
   */
  children?: ReactComponentElement<typeof ContentSingleColumn | typeof ContentDualColumn>;
  /**
   * When true, add alias banner and styling
   */
  isAliased?: boolean;
  /**
   * Email for the alias banner, only used when isAliased is true
   * */
  aliasEmail?: string;
};

/**
 * Responsible for rendering the navigation with the content following the
 * appropriate layout.
 */
export function Page(props: PageProps) {
  const {
    isLoading,
    desktopNavigationProps,
    mobileNavigationProps,
    reference,
    children,
    isAliased = false,
    aliasEmail,
  } = props;

  /***** Render *****/
  return (
    <IonPage
      className={styles["page"]}
      style={{
        display: "flex",
        backgroundColor: Colors.white300,
      }}
    >
      {/* Navigations */}
      <div className={styles["desktop-nav"]}>
        <DesktopNavigation {...desktopNavigationProps} />
      </div>
      <div className={styles["mobile-nav"]}>
        <MobileNavigation {...mobileNavigationProps} />
      </div>

      {/* Content */}
      <IonContent
        style={{
          flexGrow: 1,
          display: "flex",
        }}
        ref={reference}
      >
        {isLoading ? (
          <div className={styles["loading-interstitial"]}>
            <LoadingInterstitial />
          </div>
        ) : (
          <>
            {isAliased && (
              <AliasBanner email={aliasEmail ? aliasEmail : msg("UNPROVIDED_DATA_MESSAGE")} />
            )}
            <div style={{ marginTop: isAliased ? "32px" : "0px" }}>{children}</div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
}

export const goToTop = async (ref: RefObject<HTMLIonContentElement>) => {
  if (ref.current) {
    await ref.current.scrollToTop(500);
  }
};
