import { BUILD_PROFILE } from "./build";

/**
 * These define the the possible environments that this code can be running in.
 * These are currently used for Sentry's `environment` configuration.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/environments
 */
type Environment = "Local" | "Staging" | "Production";

/** Returns the JavaScript environment that the code is running in. */
export function getEnvironment(): Environment | undefined {
  // Default to `Local` since Capacitor doesn't use `localhost`
  let env: Environment = "Local";

  if (BUILD_PROFILE === "staging") {
    env = "Staging";
  } else if (BUILD_PROFILE === "prod") {
    env = "Production";
  }

  return env;
}
