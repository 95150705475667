import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  ErrorMessage,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  PhoneInput,
} from "~components";
import {
  msg,
  PhoneVerificationError,
  PhoneVerificationPayload,
  PhoneVerificationSuccess,
  POST,
} from "~utils";

type ModalContentPhoneNumberRequestCodeProps = {
  isPhoneNumberVerified?: boolean;
  phoneNumber: string;
  originalPhoneNumber: string;
  onStepChange: () => void;
  onDismiss: () => void;
  title: string;
  onPhoneNumberChange: (phoneNumber: string) => void;
};

export function ModalContentPhoneNumberRequestCode(props: ModalContentPhoneNumberRequestCodeProps) {
  const {
    onStepChange,
    onDismiss,
    title,
    phoneNumber,
    isPhoneNumberVerified = false,
    onPhoneNumberChange,
    originalPhoneNumber,
  } = props;

  const { mutate: requestCode, error: mutationError } = useMutation<
    PhoneVerificationSuccess | PhoneVerificationError,
    AxiosError,
    PhoneVerificationPayload
  >({
    mutationFn: (payload) => POST("/api/sub/phone-verification", payload),
    onSuccess: () => onStepChange(),
  });
  const showPhoneNumberError = phoneNumber.trim().length === 0 || mutationError;
  const isSecondaryButtonDisabled = phoneNumber === originalPhoneNumber && isPhoneNumberVerified;

  const isRequestCodeButtonDisabled =
    (phoneNumber === originalPhoneNumber && isPhoneNumberVerified) || phoneNumber.length != 10;
  const phoneNumberErrorMessage =
    mutationError || AxiosError ? (
      <span>
        {msg("PROFILE_PHONE_NUMBER_INVALID_ERROR_MESSAGE")}
        {", "}
        <a href="#">{msg("LABEL_CONTACT_SUPPORT")}</a>
      </span>
    ) : (
      msg("PROFILE_PHONE_NUMBER_EMPTY_INPUT_ERROR")
    );
  const phoneNumberError = (
    <ErrorMessage>
      <span>{phoneNumberErrorMessage}.</span>
    </ErrorMessage>
  );

  function handleRequestCode() {
    requestCode({ phoneNumber: phoneNumber });
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <div style={{ paddingBottom: "8px" }}>
          <span>{msg("PROFILE_PHONE_NUMBER_REQUEST_CODE_MESSAGE")}.</span>
        </div>
        <PhoneInput
          error={showPhoneNumberError ? phoneNumberError : null}
          label={msg("PROFILE_PHONE_NUMBER_INPUT_LABEL")}
          onChange={(phoneNumber: string) => onPhoneNumberChange(phoneNumber)}
          phoneNumber={originalPhoneNumber}
        />
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isRequestCodeButtonDisabled,
          text: msg("PROFILE_PHONE_NUMBER_REQUEST_CODE_BUTTON_LABEL"),
          onClick: () => {
            handleRequestCode();
          },
        }}
        buttonSecondary={{
          isDisabled: isSecondaryButtonDisabled,
          text: isPhoneNumberVerified
            ? msg("PROFILE_PHONE_NUMBER_UPDATE_NEVERMIND_LABEL")
            : msg("PROFILE_PHONE_NUMBER_VERIFY_LATER_LABEL"),
          onClick: () => {
            onDismiss();
          },
        }}
      />
    </ModalSubProfileEdit>
  );
}
