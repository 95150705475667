import { ModalOptions } from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ComponentType, useRef, useState } from "react";
import { useParams } from "react-router";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { AlertBox, Colors, Icon } from "swing-components";

import {
  AlertBoxError,
  ButtonScoreWrapper,
  CancelReason,
  CancelReasonType,
  ContentSingleColumn,
  ModalCancelCommitments,
  ModalCancelPolicy,
  ModalConfirmation,
  ModalConflicts,
  ModalSchoolFeedback,
  RequestDetailCard,
  useModal,
} from "~components";
import { calcCancelPenaltyType, GET, msg, PUT, RequestData } from "~utils";
import { ScorePage } from "../ScoreTemplates/ScorePage";
import styles from "./RequestDetails.module.css";

// TODO: find a better way to avoid the refetchInterval
// from running when running tests (Storybook)
type RequestDetails = {
  isTest?: boolean;
};

export function RequestDetails(props: RequestDetails) {
  const { isTest } = props;
  const params = useParams<{ requestId: string }>();

  const { data, isLoading, error } = useQuery({
    queryKey: ["fetchRequestDetails", params.requestId],
    queryFn: () =>
      GET("/api/sub/request/{requestId}", {
        pathParams: { requestId: params.requestId },
      }),
    refetchInterval: (query) => {
      // only poll for an open request
      if (!isTest && query.state.data?.data.status === "STATUS_OPEN") {
        return 30 * 1000; // time in ms - seconds * milliseconds = 30 seconds
      }
      return false;
    },
  });

  return (
    <ScorePage title={msg("PAGE_TITLE_REQUEST_DETAILS")} isLoading={isLoading} hasBack>
      <ContentSingleColumn>
        {error && (
          <div className={styles["request-details-error-wrapper"]}>
            <AlertBoxError margin="0" />
          </div>
        )}
        {data?.data && <RequestDetailsView request={data.data} />}
      </ContentSingleColumn>
    </ScorePage>
  );
}

type RequestDetailsViewProps = {
  request: RequestData;
};

export function RequestDetailsView(props: RequestDetailsViewProps) {
  const { request } = props;
  const [hasApiError, setHasApiError] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [actionPayload, setActionPayload] = useState<ActionPayloadProps>({ action: undefined });

  const actionData = requestDetailsActionMapper[request.action];
  const isPast = request.status === "STATUS_COMPLETED";
  const hasFeedback = !!request.schoolFeedback;

  // type guards
  const isUsingComponent = "component" in actionData;
  const isUsingModalOptions = "modalOptions" in actionData;
  const isUsingButton = "button" in actionData;

  // using ref as don't want to trigger a re-render and instead
  // rely on setQueryData to re-render when the data has been updated
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataToSyncToCache = useRef<null | any>(null);
  // Note: without waiting for the onDidDismiss event, a new component
  // is rendered changing the modal content before the modal is closed
  function handleSyncData() {
    // only update data in client cache when it exists
    if (dataToSyncToCache.current) {
      queryClient.setQueryData(["fetchRequestDetails", request.id], dataToSyncToCache.current);
      dataToSyncToCache.current = null;
    }
    // remove any errors content the modal when modal is closed
    setHasApiError(false);
  }

  const { mutate: onAction } = useMutation({
    // TODO: @KoltonG - How can we help type this payload?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: (payload: any) => {
      return PUT("/api/sub/request/{requestId}", payload, {
        pathParams: { requestId: request.id },
      });
    },
    onSuccess: async (updatedRequest, payload) => {
      // set data to sync to client cache when we are ready (after modal has finished closing via onDidDismiss)
      dataToSyncToCache.current = updatedRequest;
      setActionPayload(payload);
      if (
        updatedRequest.data.action === "ACTION_SUB_CAN_JOIN_WAITLIST" ||
        updatedRequest.data.action === "ACTION_SUB_CAN_LEAVE_WAITLIST"
      ) {
        handleSyncData();
      }
      closeModal();
    },
    onError: () => {
      setHasApiError(true);
    },
  });

  const { openModal, closeModal } = useModal({
    component:
      isUsingComponent && actionData.component.element === ModalConfirmation ? (
        <actionData.component.element
          {...actionData.component.props}
          hasApiError={hasApiError}
          onConfirm={async () => onAction({ action: actionData.payload.action })}
          onDismiss={() => closeModal()}
        >
          {actionData.payload.action === "JOIN_WAITLIST" ? (
            <ModalJoinWaitlistContent
              displayDate={request.displayDate}
              schoolName={request.school.name}
            />
          ) : (
            <ModalConfirmationContent
              displayDate={request.displayDate}
              isMultiDay={request.isMultiDay}
              schoolName={request.school.name}
              startDate={request.startDate}
            />
          )}
        </actionData.component.element>
      ) : isUsingComponent && actionData.component.element === ModalCancelCommitments ? (
        <actionData.component.element
          {...actionData.component.props}
          hasApiError={hasApiError}
          onConfirm={async (payload) => onAction({ ...payload, action: actionData.payload.action })}
          onDismiss={() => closeModal()}
          conflicts={request.conflicts}
          // @ts-expect-error Type 'undefined' is not assignable to type 'CancelPenaltyType'
          penaltyType={
            request.fillDate
              ? calcCancelPenaltyType({
                  reqStartTime: request.startDate,
                  reqFillTime: request.fillDate,
                  reqCancelTime: DateTime.now().setZone("UTC"),
                })
              : undefined
          }
        />
      ) : isUsingComponent && actionData.component.element === ModalConflicts ? (
        <actionData.component.element
          {...actionData.component.props}
          conflicts={request.conflicts}
          hasApiError={hasApiError}
          message={msg("MODAL_CONFLICTING_COMMITMENTS_MESSAGE", {
            schoolName: request.school.name,
            startDate: DateTime.fromISO(request.startDate).toFormat("LLL dd"),
            endDate: DateTime.fromISO(request.endDate).toFormat("LLL dd"),
          })}
          onConfirm={async () => onAction({ action: actionData.payload.action })}
          onDismiss={() => closeModal()}
        />
      ) : isPast && !hasFeedback ? (
        <ModalSchoolFeedback
          step="rating"
          schoolName={request.school.name}
          requestId={request.id}
          onDismiss={() => closeModal()}
        />
      ) : (
        // when there is no modal to render
        <></>
      ),
    modalOptions: isUsingModalOptions
      ? { ...actionData.modalOptions, onDidDismiss: handleSyncData }
      : isPast && !hasFeedback
        ? { cssClass: "modal-sub-profile-edit" }
        : { onDidDismiss: handleSyncData },
  });

  return (
    <>
      <div className={styles["request-details-content-wrapper"]}>
        <div className={styles["request-details-content"]}>
          {hasApiError && (
            <div className={styles["request-details-error-wrapper"]}>
              <AlertBoxError margin="0" />
            </div>
          )}
          <RequestDetailCard
            request={request}
            alertBox={<RequestDetailsAlertBox actionPayload={actionPayload} />}
          />
        </div>
        {isUsingButton && actionData.payload && (
          <ButtonScoreWrapper
            buttonPrimary={{
              label: actionData.button.label,
              expand: "block",
              onClick: isUsingComponent ? openModal : () => onAction(actionData.payload),
              fill: actionData.button.variant,
              disabled: actionData.payload.action === "CANCEL" && !request.fillDate,
            }}
            backgroundColor="white200"
          />
        )}
        {FEATURE_FLAGS.SCHOOL_FEEDBACK && isPast && !hasFeedback && (
          <ButtonScoreWrapper
            buttonPrimary={{
              label: msg("SCHOOL_FEEDBACK_TITLE"),
              expand: "block",
              onClick: openModal,
              fill: "solid",
            }}
            backgroundColor="white200"
          />
        )}
      </div>
    </>
  );
}

type ActionPayloadProps = {
  action?: Action;
  reason?: CancelReasonType;
};

type RequestDetailsAlertBoxProps = {
  actionPayload: ActionPayloadProps;
};

function RequestDetailsAlertBox(props: RequestDetailsAlertBoxProps) {
  const { actionPayload } = props;
  switch (actionPayload.action) {
    case "CANCEL":
      return (
        <AlertBox
          className={styles["request-details-alert-box"]}
          color="info"
          title={msg("CANCEL_ALERT_TITLE")}
          showIcon
        >
          {/* covering case where reason is optional for the alertbox props */}
          {msg("CANCEL_ALERT_MESSAGE")}:{" "}
          {CancelReason[actionPayload.reason || "CANCEL_REASON_OTHER"]}
        </AlertBox>
      );
    default:
      return undefined;
  }
}

// extract props from component
type InferComponentProps<T> = T extends ComponentType<infer P> ? P : never;
// type actual component
type Component<C> = (props: InferComponentProps<C>) => JSX.Element;

type ComponentConfig<C> = {
  component: {
    element: Component<C>;
    props: Partial<InferComponentProps<C>>;
  };
};

type ModalOptionsProps = {
  modalOptions: Omit<ModalOptions, "component" | "componentProps">;
};

type ButtonProps = {
  button: {
    label: string;
    variant: "solid" | "outline" | "clear";
  };
};

type Action = "SIGN_UP" | "CANCEL" | "CONFIRM" | "LEAVE_WAITLIST" | "JOIN_WAITLIST" | "NOOP";

type ActionMapperProps<T> = ComponentConfig<T> &
  ModalOptionsProps &
  ButtonProps & {
    payload?: { action: Action };
  };

const requestDetailsActionMapper = {
  ["ACTION_SUB_CAN_SIGN_UP"]: {
    button: {
      label: msg("LABEL_SIGN_UP"),
      variant: "solid",
    },
    component: {
      element: ModalConfirmation,
      props: {
        title: msg("MODAL_CONFIRMATION_SUB_SIGN_UP_TITLE"),
        primaryButtonText: msg("MODAL_CONFIRMATION_SUB_SIGN_UP_PRIMARY_BUTTON_TEXT"),
        secondaryButtonText: msg("MODAL_CONFIRMATION_SUB_SIGN_UP_SECONDARY_BUTTON_TEXT"),
      },
    },
    modalOptions: { cssClass: "modal-confirmation" },
    payload: { action: "SIGN_UP" },
  } satisfies ActionMapperProps<typeof ModalConfirmation>,
  ["ACTION_SUB_CAN_CANCEL"]: {
    button: {
      label: msg("LABEL_CANCEL"),
      variant: "outline",
    },
    component: {
      element: ModalCancelCommitments,
      props: {
        message: msg("CANCEL_COMMITMENT_MODAL_MESSAGE"),
        title: msg("CANCEL_COMMITMENT_MODAL_TITLE"),
      },
    },
    modalOptions: { cssClass: "modal-cancel" },
    payload: { action: "CANCEL" },
  } satisfies ActionMapperProps<typeof ModalCancelCommitments>,
  ["ACTION_SUB_CAN_CONFIRM"]: {
    button: {
      label: msg("LABEL_CAN_CONFIRM"),
      variant: "solid",
    },
    component: {
      element: ModalConflicts,
      props: {
        title: msg("MODAL_CONFLICTING_COMMITMENTS_TITLE"),
      },
    },
    modalOptions: { cssClass: "modal-conflicts" },
    payload: { action: "CONFIRM" },
  } satisfies ActionMapperProps<typeof ModalConflicts>,
  ["ACTION_SUB_CAN_JOIN_WAITLIST"]: {
    button: {
      label: msg("LABEL_JOIN_WAITLIST"),
      variant: "solid",
    },
    component: {
      element: ModalConfirmation,
      props: {
        title: msg("MODAL_JOIN_WAITLIST_TITLE"),
        primaryButtonText: msg("MODAL_JOIN_WAITLIST_PRIMARY_BUTTON_TEXT"),
        secondaryButtonText: msg("MODAL_JOIN_WAITLIST_SECONDARY_BUTTON_TEXT"),
      },
    },
    modalOptions: { cssClass: "modal-confirmation" },
    payload: { action: "JOIN_WAITLIST" },
  } satisfies ActionMapperProps<typeof ModalConfirmation>,
  ["ACTION_SUB_CAN_LEAVE_WAITLIST"]: {
    button: {
      label: msg("LABEL_LEAVE_WAITLIST"),
      variant: "outline",
    },
    payload: { action: "LEAVE_WAITLIST" },
  } satisfies ButtonProps & { payload: { action: Action } },
  ["ACTION_SUB_CAN_NOOP"]: {
    payload: { action: "NOOP" },
  } satisfies { payload: { action: Action } },
};

type ModalConfirmationContentProps = {
  displayDate: RequestData["displayDate"];
  isMultiDay: RequestData["isMultiDay"];
  schoolName: RequestData["school"]["name"];
  startDate: RequestData["startDate"];
};

export function ModalConfirmationContent(props: ModalConfirmationContentProps) {
  const { displayDate, isMultiDay, schoolName, startDate } = props;
  const penaltyType = calcCancelPenaltyType({
    reqStartTime: startDate,
    reqFillTime: DateTime.now().setZone("UTC").minus({ minutes: 31 }), //Set to >30 to bypass grace period calculation
    reqCancelTime: DateTime.now().setZone("UTC"),
  });
  const insideCancelWindow = penaltyType !== "NO_PENALTY"; // A true value signals the request begins within 24hours.

  const { openModal: openCancelPolicyModal, closeModal: closeCancelPolicyModal } = useModal({
    component: <ModalCancelPolicy onDismiss={() => closeCancelPolicyModal()} />,
    modalOptions: {
      cssClass: `cancel-policy-modal`,
    },
  });

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "24px 1fr",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <Icon name="School" />
        <p>{schoolName}</p>
        <Icon name={`${isMultiDay ? "Calendar Overlap" : "Calendar"}`} />
        <p>{displayDate}</p>
        <Icon name="Block" />
        <div>
          {insideCancelWindow
            ? msg("CANCEL_POLICY_WARNING_PENALTY")
            : msg("CANCEL_POLICY_WARNING_NO_PENALTY")}

          <span
            onClick={openCancelPolicyModal}
            style={{ color: Colors.blue500, textDecoration: "underline", cursor: "pointer" }}
          >
            {msg("CANCEL_POLICY_LABEL")}.
          </span>
        </div>
      </div>
      <p style={{ paddingTop: 16 }}>
        {schoolName} {msg("MODAL_CONFIRMATION_SUB_SIGN_UP_MESSAGE")}.
      </p>
    </div>
  );
}

type ModalJoinWaitlistProps = {
  displayDate: RequestData["displayDate"];
  schoolName: RequestData["school"]["name"];
};

export function ModalJoinWaitlistContent(props: ModalJoinWaitlistProps) {
  const { displayDate, schoolName } = props;
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "24px 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Icon name="School" />
        <p>{schoolName}</p>
        <Icon name="Calendar Overlap" />
        <p>{displayDate}</p>
        <Icon name="Flag" />
        <strong>{msg("MODAL_JOIN_WAITLIST_POLICY")}</strong>
      </div>
      <ul style={{ margin: 0 }}>
        {msg("MODAL_JOIN_WAITLIST_BEFORE")}
        <li style={{ marginLeft: 16 }}>{msg("MODAL_JOIN_WAITLIST_REMOVE")}</li>
      </ul>
      <ul style={{ marginTop: 0 }}>
        {msg("MODAL_JOIN_WAITLIST_AFTER")}
        <li style={{ marginLeft: 16 }}>
          {msg("IF_YOU")}
          <strong>{msg("DO_NOT")}</strong>
          {msg("MODAL_JOIN_WAITLIST_NO_CONFLICTING")}
        </li>
        <li style={{ marginLeft: 16 }}>
          {msg("IF_YOU")}
          <strong>{msg("DO")}</strong>
          {msg("MODAL_JOIN_WAITLIST_CONFLICTING")}
        </li>
      </ul>
    </div>
  );
}
