import { FunctionComponentElement } from "react";
import { Link } from "react-router-dom";
import { Colors, Icon, IconName } from "swing-components";

import { ValuesOf } from "~utils";
import { Badge } from "../Badge";
import styles from "./ContentNavigationItem.module.css";

type ContentNavigationItemProps = {
  navigationUrl?: string;
  icon?: {
    name: IconName;
    color?: ValuesOf<typeof Colors>;
  };
  navigationIcon?: IconName;
  label: string;
  onClick?: () => void;
  badge?: FunctionComponentElement<typeof Badge>;
  hasDivider?: boolean;
  hasWhiteBackground?: boolean;
};

export function ContentNavigationItem(props: ContentNavigationItemProps) {
  // defaulting hasDivider and hasWhiteBackground to true since onboarding is using the component and have not checked if they need that 100%
  const {
    navigationUrl,
    icon,
    label,
    navigationIcon,
    onClick,
    badge,
    hasWhiteBackground = true,
    hasDivider = true,
  } = props;

  return (
    <div
      className={`${styles["content-navigation-wrapper"]} ${hasWhiteBackground ? styles["content-white-background"] : ""} ${onClick || navigationUrl ? styles["clickable"] : ""} ${styles["navigation-link-wrapper"]}`}
    >
      {navigationUrl && <Link to={navigationUrl} className={styles["navigation-link"]} />}
      <div
        className={styles["content-row-wrapper"]}
        key={label}
        rel="noopener noreferrer"
        onClick={onClick}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && <Icon name={icon.name} color={icon.color ? icon.color : Colors.blue500} />}
          <span style={{ paddingLeft: "var(--spacing-sm)" }}>{label}</span>
          {badge && <span style={{ marginLeft: "8px" }}>{badge}</span>}
        </div>
        {navigationIcon && (
          <div className={styles["navigation-icon"]}>
            <Icon name={navigationIcon} color={Colors.blue500} />
          </div>
        )}
      </div>
      {hasDivider && <div className={styles["divider"]} />}
    </div>
  );
}
