import le from "react";
var ce = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function pe(h) {
  return h && h.__esModule && Object.prototype.hasOwnProperty.call(h, "default") ? h.default : h;
}
function de(h) {
  if (h.__esModule) return h;
  var K = h.default;
  if (typeof K == "function") {
    var j = function w() {
      return this instanceof w ? Reflect.construct(K, arguments, this.constructor) : K.apply(this, arguments);
    };
    j.prototype = K.prototype;
  } else j = {};
  return Object.defineProperty(j, "__esModule", {
    value: !0
  }), Object.keys(h).forEach(function(w) {
    var U = Object.getOwnPropertyDescriptor(h, w);
    Object.defineProperty(j, w, U.get ? U : {
      enumerable: !0,
      get: function() {
        return h[w];
      }
    });
  }), j;
}
var ie = { exports: {} };
(function(h, K) {
  (function(j, w) {
    h.exports = w(le);
  })(ce, (j) => (() => {
    var w = { 703: (u, g, C) => {
      var t = C(414);
      function M() {
      }
      function W() {
      }
      W.resetWarningCache = M, u.exports = function() {
        function v(re, A, G, Z, ne, V) {
          if (V !== t) {
            var z = new Error("Calling PropTypes validators directly is not supported by the `prop-types` package. Use PropTypes.checkPropTypes() to call them. Read more at http://fb.me/use-check-prop-types");
            throw z.name = "Invariant Violation", z;
          }
        }
        function O() {
          return v;
        }
        v.isRequired = v;
        var J = { array: v, bigint: v, bool: v, func: v, number: v, object: v, string: v, symbol: v, any: v, arrayOf: O, element: v, elementType: v, instanceOf: O, node: v, objectOf: O, oneOf: O, oneOfType: O, shape: O, exact: O, checkPropTypes: W, resetWarningCache: M };
        return J.PropTypes = J, J;
      };
    }, 697: (u, g, C) => {
      u.exports = C(703)();
    }, 414: (u) => {
      u.exports = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
    }, 98: (u) => {
      u.exports = j;
    } }, U = {};
    function y(u) {
      var g = U[u];
      if (g !== void 0) return g.exports;
      var C = U[u] = { exports: {} };
      return w[u](C, C.exports, y), C.exports;
    }
    y.n = (u) => {
      var g = u && u.__esModule ? () => u.default : () => u;
      return y.d(g, { a: g }), g;
    }, y.d = (u, g) => {
      for (var C in g) y.o(g, C) && !y.o(u, C) && Object.defineProperty(u, C, { enumerable: !0, get: g[C] });
    }, y.o = (u, g) => Object.prototype.hasOwnProperty.call(u, g), y.r = (u) => {
      typeof Symbol < "u" && Symbol.toStringTag && Object.defineProperty(u, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(u, "__esModule", { value: !0 });
    };
    var ee = {};
    return (() => {
      y.r(ee), y.d(ee, { default: () => se });
      var u = y(98), g = y.n(u), C = y(697), t = y.n(C);
      function M() {
        return M = Object.assign ? Object.assign.bind() : function(r) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (r[l] = o[l]);
          }
          return r;
        }, M.apply(this, arguments);
      }
      var W = function(r) {
        var i = r.pageClassName, o = r.pageLinkClassName, l = r.page, I = r.selected, H = r.activeClassName, E = r.activeLinkClassName, a = r.getEventListener, e = r.pageSelectedHandler, p = r.href, n = r.extraAriaContext, s = r.pageLabelBuilder, c = r.rel, f = r.ariaLabel || "Page " + l + (n ? " " + n : ""), m = null;
        return I && (m = "page", f = r.ariaLabel || "Page " + l + " is your current page", i = i !== void 0 ? i + " " + H : H, o !== void 0 ? E !== void 0 && (o = o + " " + E) : o = E), g().createElement("li", { className: i }, g().createElement("a", M({ rel: c, role: p ? void 0 : "button", className: o, href: p, tabIndex: I ? "-1" : "0", "aria-label": f, "aria-current": m, onKeyPress: e }, a(e)), s(l)));
      };
      W.propTypes = { pageSelectedHandler: t().func.isRequired, selected: t().bool.isRequired, pageClassName: t().string, pageLinkClassName: t().string, activeClassName: t().string, activeLinkClassName: t().string, extraAriaContext: t().string, href: t().string, ariaLabel: t().string, page: t().number.isRequired, getEventListener: t().func.isRequired, pageLabelBuilder: t().func.isRequired, rel: t().string };
      const v = W;
      function O() {
        return O = Object.assign ? Object.assign.bind() : function(r) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (r[l] = o[l]);
          }
          return r;
        }, O.apply(this, arguments);
      }
      var J = function(r) {
        var i = r.breakLabel, o = r.breakAriaLabel, l = r.breakClassName, I = r.breakLinkClassName, H = r.breakHandler, E = r.getEventListener, a = l || "break";
        return g().createElement("li", { className: a }, g().createElement("a", O({ className: I, role: "button", tabIndex: "0", "aria-label": o, onKeyPress: H }, E(H)), i));
      };
      J.propTypes = { breakLabel: t().oneOfType([t().string, t().node]), breakAriaLabel: t().string, breakClassName: t().string, breakLinkClassName: t().string, breakHandler: t().func.isRequired, getEventListener: t().func.isRequired };
      const re = J;
      function A(r) {
        var i = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
        return r ?? i;
      }
      function G(r) {
        return G = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(i) {
          return typeof i;
        } : function(i) {
          return i && typeof Symbol == "function" && i.constructor === Symbol && i !== Symbol.prototype ? "symbol" : typeof i;
        }, G(r);
      }
      function Z() {
        return Z = Object.assign ? Object.assign.bind() : function(r) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (r[l] = o[l]);
          }
          return r;
        }, Z.apply(this, arguments);
      }
      function ne(r, i) {
        for (var o = 0; o < i.length; o++) {
          var l = i[o];
          l.enumerable = l.enumerable || !1, l.configurable = !0, "value" in l && (l.writable = !0), Object.defineProperty(r, l.key, l);
        }
      }
      function V(r, i) {
        return V = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(o, l) {
          return o.__proto__ = l, o;
        }, V(r, i);
      }
      function z(r, i) {
        if (i && (G(i) === "object" || typeof i == "function")) return i;
        if (i !== void 0) throw new TypeError("Derived constructors may only return object or undefined");
        return x(r);
      }
      function x(r) {
        if (r === void 0) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return r;
      }
      function Q(r) {
        return Q = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(i) {
          return i.__proto__ || Object.getPrototypeOf(i);
        }, Q(r);
      }
      function P(r, i, o) {
        return i in r ? Object.defineProperty(r, i, { value: o, enumerable: !0, configurable: !0, writable: !0 }) : r[i] = o, r;
      }
      var te = function(r) {
        (function(a, e) {
          if (typeof e != "function" && e !== null) throw new TypeError("Super expression must either be null or a function");
          a.prototype = Object.create(e && e.prototype, { constructor: { value: a, writable: !0, configurable: !0 } }), Object.defineProperty(a, "prototype", { writable: !1 }), e && V(a, e);
        })(E, r);
        var i, o, l, I, H = (l = E, I = function() {
          if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham) return !1;
          if (typeof Proxy == "function") return !0;
          try {
            return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
            })), !0;
          } catch {
            return !1;
          }
        }(), function() {
          var a, e = Q(l);
          if (I) {
            var p = Q(this).constructor;
            a = Reflect.construct(e, arguments, p);
          } else a = e.apply(this, arguments);
          return z(this, a);
        });
        function E(a) {
          var e, p;
          return function(n, s) {
            if (!(n instanceof s)) throw new TypeError("Cannot call a class as a function");
          }(this, E), P(x(e = H.call(this, a)), "handlePreviousPage", function(n) {
            var s = e.state.selected;
            e.handleClick(n, null, s > 0 ? s - 1 : void 0, { isPrevious: !0 });
          }), P(x(e), "handleNextPage", function(n) {
            var s = e.state.selected, c = e.props.pageCount;
            e.handleClick(n, null, s < c - 1 ? s + 1 : void 0, { isNext: !0 });
          }), P(x(e), "handlePageSelected", function(n, s) {
            if (e.state.selected === n) return e.callActiveCallback(n), void e.handleClick(s, null, void 0, { isActive: !0 });
            e.handleClick(s, null, n);
          }), P(x(e), "handlePageChange", function(n) {
            e.state.selected !== n && (e.setState({ selected: n }), e.callCallback(n));
          }), P(x(e), "getEventListener", function(n) {
            return P({}, e.props.eventListener, n);
          }), P(x(e), "handleClick", function(n, s, c) {
            var f = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {}, m = f.isPrevious, R = m !== void 0 && m, F = f.isNext, Y = F !== void 0 && F, q = f.isBreak, L = q !== void 0 && q, T = f.isActive, _ = T !== void 0 && T;
            n.preventDefault ? n.preventDefault() : n.returnValue = !1;
            var B = e.state.selected, d = e.props.onClick, N = c;
            if (d) {
              var k = d({ index: s, selected: B, nextSelectedPage: c, event: n, isPrevious: R, isNext: Y, isBreak: L, isActive: _ });
              if (k === !1) return;
              Number.isInteger(k) && (N = k);
            }
            N !== void 0 && e.handlePageChange(N);
          }), P(x(e), "handleBreakClick", function(n, s) {
            var c = e.state.selected;
            e.handleClick(s, n, c < n ? e.getForwardJump() : e.getBackwardJump(), { isBreak: !0 });
          }), P(x(e), "callCallback", function(n) {
            e.props.onPageChange !== void 0 && typeof e.props.onPageChange == "function" && e.props.onPageChange({ selected: n });
          }), P(x(e), "callActiveCallback", function(n) {
            e.props.onPageActive !== void 0 && typeof e.props.onPageActive == "function" && e.props.onPageActive({ selected: n });
          }), P(x(e), "getElementPageRel", function(n) {
            var s = e.state.selected, c = e.props, f = c.nextPageRel, m = c.prevPageRel, R = c.selectedPageRel;
            return s - 1 === n ? m : s === n ? R : s + 1 === n ? f : void 0;
          }), P(x(e), "pagination", function() {
            var n = [], s = e.props, c = s.pageRangeDisplayed, f = s.pageCount, m = s.marginPagesDisplayed, R = s.breakLabel, F = s.breakClassName, Y = s.breakLinkClassName, q = s.breakAriaLabels, L = e.state.selected;
            if (f <= c) for (var T = 0; T < f; T++) n.push(e.getPageElement(T));
            else {
              var _ = c / 2, B = c - _;
              L > f - c / 2 ? _ = c - (B = f - L) : L < c / 2 && (B = c - (_ = L));
              var d, N, k = function(S) {
                return e.getPageElement(S);
              }, b = [];
              for (d = 0; d < f; d++) {
                var X = d + 1;
                if (X <= m) b.push({ type: "page", index: d, display: k(d) });
                else if (X > f - m) b.push({ type: "page", index: d, display: k(d) });
                else if (d >= L - _ && d <= L + (L === 0 && c > 1 ? B - 1 : B)) b.push({ type: "page", index: d, display: k(d) });
                else if (R && b.length > 0 && b[b.length - 1].display !== N && (c > 0 || m > 0)) {
                  var ae = d < L ? q.backward : q.forward;
                  N = g().createElement(re, { key: d, breakAriaLabel: ae, breakLabel: R, breakClassName: F, breakLinkClassName: Y, breakHandler: e.handleBreakClick.bind(null, d), getEventListener: e.getEventListener }), b.push({ type: "break", index: d, display: N });
                }
              }
              b.forEach(function(S, D) {
                var $ = S;
                S.type === "break" && b[D - 1] && b[D - 1].type === "page" && b[D + 1] && b[D + 1].type === "page" && b[D + 1].index - b[D - 1].index <= 2 && ($ = { type: "page", index: S.index, display: k(S.index) }), n.push($.display);
              });
            }
            return n;
          }), a.initialPage !== void 0 && a.forcePage !== void 0 && console.warn("(react-paginate): Both initialPage (".concat(a.initialPage, ") and forcePage (").concat(a.forcePage, ") props are provided, which is discouraged.") + ` Use exclusively forcePage prop for a controlled component.
See https://reactjs.org/docs/forms.html#controlled-components`), p = a.initialPage ? a.initialPage : a.forcePage ? a.forcePage : 0, e.state = { selected: p }, e;
        }
        return i = E, (o = [{ key: "componentDidMount", value: function() {
          var a = this.props, e = a.initialPage, p = a.disableInitialCallback, n = a.extraAriaContext, s = a.pageCount, c = a.forcePage;
          e === void 0 || p || this.callCallback(e), n && console.warn("DEPRECATED (react-paginate): The extraAriaContext prop is deprecated. You should now use the ariaLabelBuilder instead."), Number.isInteger(s) || console.warn("(react-paginate): The pageCount prop value provided is not an integer (".concat(s, "). Did you forget a Math.ceil()?")), e !== void 0 && e > s - 1 && console.warn("(react-paginate): The initialPage prop provided is greater than the maximum page index from pageCount prop (".concat(e, " > ").concat(s - 1, ").")), c !== void 0 && c > s - 1 && console.warn("(react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (".concat(c, " > ").concat(s - 1, ")."));
        } }, { key: "componentDidUpdate", value: function(a) {
          this.props.forcePage !== void 0 && this.props.forcePage !== a.forcePage && (this.props.forcePage > this.props.pageCount - 1 && console.warn("(react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (".concat(this.props.forcePage, " > ").concat(this.props.pageCount - 1, ").")), this.setState({ selected: this.props.forcePage })), Number.isInteger(a.pageCount) && !Number.isInteger(this.props.pageCount) && console.warn("(react-paginate): The pageCount prop value provided is not an integer (".concat(this.props.pageCount, "). Did you forget a Math.ceil()?"));
        } }, { key: "getForwardJump", value: function() {
          var a = this.state.selected, e = this.props, p = e.pageCount, n = a + e.pageRangeDisplayed;
          return n >= p ? p - 1 : n;
        } }, { key: "getBackwardJump", value: function() {
          var a = this.state.selected - this.props.pageRangeDisplayed;
          return a < 0 ? 0 : a;
        } }, { key: "getElementHref", value: function(a) {
          var e = this.props, p = e.hrefBuilder, n = e.pageCount, s = e.hrefAllControls;
          if (p) return s || a >= 0 && a < n ? p(a + 1, n, this.state.selected) : void 0;
        } }, { key: "ariaLabelBuilder", value: function(a) {
          var e = a === this.state.selected;
          if (this.props.ariaLabelBuilder && a >= 0 && a < this.props.pageCount) {
            var p = this.props.ariaLabelBuilder(a + 1, e);
            return this.props.extraAriaContext && !e && (p = p + " " + this.props.extraAriaContext), p;
          }
        } }, { key: "getPageElement", value: function(a) {
          var e = this.state.selected, p = this.props, n = p.pageClassName, s = p.pageLinkClassName, c = p.activeClassName, f = p.activeLinkClassName, m = p.extraAriaContext, R = p.pageLabelBuilder;
          return g().createElement(v, { key: a, pageSelectedHandler: this.handlePageSelected.bind(null, a), selected: e === a, rel: this.getElementPageRel(a), pageClassName: n, pageLinkClassName: s, activeClassName: c, activeLinkClassName: f, extraAriaContext: m, href: this.getElementHref(a), ariaLabel: this.ariaLabelBuilder(a), page: a + 1, pageLabelBuilder: R, getEventListener: this.getEventListener });
        } }, { key: "render", value: function() {
          var a = this.props.renderOnZeroPageCount;
          if (this.props.pageCount === 0 && a !== void 0) return a && a(this.props);
          var e = this.props, p = e.disabledClassName, n = e.disabledLinkClassName, s = e.pageCount, c = e.className, f = e.containerClassName, m = e.previousLabel, R = e.previousClassName, F = e.previousLinkClassName, Y = e.previousAriaLabel, q = e.prevRel, L = e.nextLabel, T = e.nextClassName, _ = e.nextLinkClassName, B = e.nextAriaLabel, d = e.nextRel, N = this.state.selected, k = N === 0, b = N === s - 1, X = "".concat(A(R)).concat(k ? " ".concat(A(p)) : ""), ae = "".concat(A(T)).concat(b ? " ".concat(A(p)) : ""), S = "".concat(A(F)).concat(k ? " ".concat(A(n)) : ""), D = "".concat(A(_)).concat(b ? " ".concat(A(n)) : ""), $ = k ? "true" : "false", oe = b ? "true" : "false";
          return g().createElement("ul", { className: c || f, role: "navigation", "aria-label": "Pagination" }, g().createElement("li", { className: X }, g().createElement("a", Z({ className: S, href: this.getElementHref(N - 1), tabIndex: k ? "-1" : "0", role: "button", onKeyPress: this.handlePreviousPage, "aria-disabled": $, "aria-label": Y, rel: q }, this.getEventListener(this.handlePreviousPage)), m)), this.pagination(), g().createElement("li", { className: ae }, g().createElement("a", Z({ className: D, href: this.getElementHref(N + 1), tabIndex: b ? "-1" : "0", role: "button", onKeyPress: this.handleNextPage, "aria-disabled": oe, "aria-label": B, rel: d }, this.getEventListener(this.handleNextPage)), L)));
        } }]) && ne(i.prototype, o), Object.defineProperty(i, "prototype", { writable: !1 }), E;
      }(u.Component);
      P(te, "propTypes", { pageCount: t().number.isRequired, pageRangeDisplayed: t().number, marginPagesDisplayed: t().number, previousLabel: t().node, previousAriaLabel: t().string, prevPageRel: t().string, prevRel: t().string, nextLabel: t().node, nextAriaLabel: t().string, nextPageRel: t().string, nextRel: t().string, breakLabel: t().oneOfType([t().string, t().node]), breakAriaLabels: t().shape({ forward: t().string, backward: t().string }), hrefBuilder: t().func, hrefAllControls: t().bool, onPageChange: t().func, onPageActive: t().func, onClick: t().func, initialPage: t().number, forcePage: t().number, disableInitialCallback: t().bool, containerClassName: t().string, className: t().string, pageClassName: t().string, pageLinkClassName: t().string, pageLabelBuilder: t().func, activeClassName: t().string, activeLinkClassName: t().string, previousClassName: t().string, nextClassName: t().string, previousLinkClassName: t().string, nextLinkClassName: t().string, disabledClassName: t().string, disabledLinkClassName: t().string, breakClassName: t().string, breakLinkClassName: t().string, extraAriaContext: t().string, ariaLabelBuilder: t().func, eventListener: t().string, renderOnZeroPageCount: t().func, selectedPageRel: t().string }), P(te, "defaultProps", { pageRangeDisplayed: 2, marginPagesDisplayed: 3, activeClassName: "selected", previousLabel: "Previous", previousClassName: "previous", previousAriaLabel: "Previous page", prevPageRel: "prev", prevRel: "prev", nextLabel: "Next", nextClassName: "next", nextAriaLabel: "Next page", nextPageRel: "next", nextRel: "next", breakLabel: "...", breakAriaLabels: { forward: "Jump forward", backward: "Jump backward" }, disabledClassName: "disabled", disableInitialCallback: !1, pageLabelBuilder: function(r) {
        return r;
      }, eventListener: "onClick", renderOnZeroPageCount: void 0, selectedPageRel: "canonical", hrefAllControls: !1 });
      const se = te;
    })(), ee;
  })());
})(ie);
var ue = ie.exports;
const fe = /* @__PURE__ */ pe(ue);
export {
  fe as R,
  de as a,
  ce as c,
  pe as g
};
