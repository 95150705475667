import { useEffect, useState } from "react";

/**
 * Determine whether viewport is considered desktop or not based on a `900px` media query.
 * @returns {boolean} true when desktop
 * @example
 * const isDesktop = useIsDesktop();
 */
export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const isDesktopMatcher = window.matchMedia("(min-width: 900px)");
    const updateIsDesktop = (e: MediaQueryListEvent) => {
      setIsDesktop(e.matches);
    };
    // initially set on mount
    setIsDesktop(isDesktopMatcher.matches);
    // set up event listener
    isDesktopMatcher.addEventListener("change", updateIsDesktop);
    // clean up event listener
    return () => {
      isDesktopMatcher.removeEventListener("change", updateIsDesktop);
    };
  }, []);

  return isDesktop;
}
