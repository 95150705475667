import { Colors, Icon } from "swing-components";

import { msg } from "~utils";
import styles from "./AliasBanner.module.css";

type AliasBannerProps = {
  email: string;
};

export function AliasBanner(props: AliasBannerProps) {
  const { email } = props;

  return (
    <div role="complementary" className={styles["alias-banner-container"]}>
      <Icon name="Eye Glasses" color={Colors.white200} />
      <span className={`${styles["alias-banner-label"]} ${styles["alias-truncate"]}`}>
        {msg("LABEL_ALIASED")}: {email}
      </span>
    </div>
  );
}
