import { createRef, Ref } from "react";
import { ExtractRouteParams, generatePath, useHistory, useRouteMatch } from "react-router";
import { ONBOARDING_ROUTES } from "src/pages/onboarding";

import { goToTop, Page, PageProps, useAuth } from "~components";
import { MOCK_ONBOARDING_REGION } from "~mock";
import { openZendeskSupport } from "~utils";

type OnboardingPageProps = Pick<PageProps, "children" | "isLoading">;

/**
 * Wrapper for the `Page` component that includes the onboarding navigation.
 *
 * TODO: @KoltonG - We could make this also take in route props to reduce the hooks usages.
 */
export function OnboardingPage(props: OnboardingPageProps) {
  const { isLoading, children } = props;
  const ref: Ref<HTMLIonContentElement> = createRef();

  /***** Hooks *****/
  // Used to determine if we are on the Tasks, Rejection or More page url.
  const tasksPageMatch = useRouteMatch<
    ExtractRouteParams<(typeof ONBOARDING_ROUTES)["tasks"], string>
  >(ONBOARDING_ROUTES["tasks"]);
  const rejectionPageMatch = useRouteMatch<
    ExtractRouteParams<(typeof ONBOARDING_ROUTES)["rejection"], string>
  >(ONBOARDING_ROUTES["rejection"]);
  const morePageMatch = useRouteMatch<
    ExtractRouteParams<(typeof ONBOARDING_ROUTES)["more"], string>
  >(ONBOARDING_ROUTES["more"]);
  // Used to navigate to different pages.
  const history = useHistory();
  // To sign out and get user details.
  const { signOut, userInfo } = useAuth();

  const isAliased = userInfo.isAlias;

  /***** Render *****/
  return (
    <Page
      reference={ref}
      isLoading={isLoading}
      isAliased={isAliased}
      aliasEmail={userInfo.email}
      desktopNavigationProps={{
        profile: {
          // TODO: @KoltonG - Include the profile image once it's available.
          name: userInfo.name,
        },
        menuItems: {
          top: [
            {
              iconName: "Home",
              label: "Home",
              // Highlight the Home tab only when on the `/onboarding/:region` route
              isActive: tasksPageMatch?.isExact || rejectionPageMatch?.isExact,
              onClick: async () => {
                // When already on the page, scroll to the top of the page
                if (tasksPageMatch?.isExact === true || rejectionPageMatch?.isExact === true) {
                  await goToTop(ref);
                } else {
                  history.push(
                    generatePath(ONBOARDING_ROUTES["tasks"], {
                      region: tasksPageMatch!.params.region,
                    }),
                  );
                }
              },
            },
            {
              iconName: "Chat Bubble",
              label: "Support",
              isActive: false,
              onClick: openZendeskSupport,
            },
          ],
          bottom: [
            {
              iconName: "Help",
              label: "Help",
              isActive: false,
              onClick: () =>
                window.open(
                  "https://support.swingeducation.com/hc/en-us/articles/30983137046547-How-to-become-a-California-substitute-teacher",
                  "_blank",
                ),
            },
            {
              iconName: "Logout",
              label: "Log Out",
              isActive: false,
              onClick: () => signOut({ history }),
            },
          ],
        },
      }}
      mobileNavigationProps={{
        menuItems: [
          {
            iconName: "Home",
            label: "Home",
            // Highlight the Home tab only when on the `/onboarding/:region` route
            isActive: tasksPageMatch?.isExact || rejectionPageMatch?.isExact || false,
            onClick: () =>
              history.push(
                generatePath(ONBOARDING_ROUTES["tasks"], {
                  region: tasksPageMatch!.params.region,
                }),
              ),
          },
          {
            iconName: "Chat Bubble",
            label: "Support",
            isActive: false,
            onClick: openZendeskSupport,
          },
          {
            iconName: "Menu Meatball",
            label: "More",
            isActive: morePageMatch?.isExact || false,
            onClick: () =>
              history.push(
                generatePath(ONBOARDING_ROUTES["more"], { region: MOCK_ONBOARDING_REGION }),
              ),
          },
        ],
      }}
    >
      {children}
    </Page>
  );
}
