import { jsxs as s, jsx as o } from "react/jsx-runtime";
import { I as O, g as G } from "../../../Icon-CcIP5yi-.js";
import { IonButton as _, IonText as d } from "@ionic/react";
import { useState as c } from "react";
import { Colors as w } from "../../foundations/Colors/Colors.js";
import { u as x, f as T, E as v } from "../../../AuthProvider-DO6tcIND.js";
import l from "../../string-table.js";
import "../../../react-paginate-Lh5zQzpz.js";
import '../../../css/Login.css';const A = "_form_1fcag_1", S = "_divider_1fcag_5", y = "_emailInput_1fcag_27", R = "_loginButton_1fcag_31", B = "_buttonText_1fcag_39", k = "_googleIcon_1fcag_44", e = {
  form: A,
  divider: S,
  emailInput: y,
  loginButton: R,
  buttonText: B,
  googleIcon: k
};
function H(a) {
  const {
    loginWithPasswordPath: n,
    history: f,
    onLoginEmailSubmit: g
  } = a, {
    signIn: m
  } = x(), [r, I] = c(""), [E, i] = c(!1), [p, u] = c(!1), h = (t) => {
    I(t), C(t) ? u(!0) : u(!1);
  }, L = async () => {
    const t = await g(r).catch(() => i(!0));
    switch (t) {
      case "COGNITO":
        i(!1), f.push({
          pathname: n,
          // Explicitly not using `useLocation` as we want to live value vs
          // cached value.
          search: window.location.search,
          state: {
            email: r
          }
        });
        break;
      case "GOOGLE":
        m({
          provider: t.toLowerCase()
        }).catch(() => {
          i(!0);
        });
        break;
      default:
        i(!0);
        break;
    }
  };
  async function N(t) {
    t.preventDefault(), await L();
  }
  async function b(t) {
    t.preventDefault(), await m({
      provider: "google"
    });
  }
  return /* @__PURE__ */ s(T, {
    title: l("LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE"),
    children: [/* @__PURE__ */ s("form", {
      className: e.form,
      onSubmit: async (t) => await N(t),
      children: [/* @__PURE__ */ o("div", {
        className: e.emailInput,
        children: /* @__PURE__ */ o(v, {
          email: r,
          onEmailInput: (t) => h(t),
          readOnly: !1,
          error: E
        })
      }), /* @__PURE__ */ s(_, {
        type: "submit",
        size: "default",
        expand: "block",
        className: e.loginButton,
        disabled: !p,
        children: [/* @__PURE__ */ o(d, {
          className: e.buttonText,
          children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD")
        }), /* @__PURE__ */ o(O, {
          name: "Arrow Right",
          color: w.white200
        })]
      })]
    }), /* @__PURE__ */ o(M, {
      label: l("LOGIN_EMAIL_ENTRY_LABEL_OR")
    }), /* @__PURE__ */ o("form", {
      className: e.form,
      onSubmit: async (t) => await b(t),
      children: /* @__PURE__ */ s(_, {
        type: "submit",
        size: "default",
        fill: "outline",
        expand: "block",
        className: e.loginButton,
        children: [/* @__PURE__ */ o("img", {
          src: G,
          className: e.googleIcon,
          alt: "Login with Google"
        }), /* @__PURE__ */ o(d, {
          className: e.buttonText,
          children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE")
        })]
      })
    })]
  });
}
function M(a) {
  const {
    label: n
  } = a;
  return /* @__PURE__ */ o("div", {
    className: e.divider,
    children: n
  });
}
function C(a) {
  return new RegExp(/.+@.+\..{2,}/).test(a);
}
export {
  H as Login,
  C as isValidEmail
};
