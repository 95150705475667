import "./ModalCancelPolicy.css";

import { Colors, Icon } from "swing-components";

import { msg } from "~utils";

type ModalCancelPolicyProps = {
  onDismiss: () => void;
};

export function ModalCancelPolicy(props: ModalCancelPolicyProps) {
  const { onDismiss } = props;

  return (
    <div className="modal-wrapper" style={{ paddingTop: 0, paddingBottom: 0 }}>
      <div className="modal-header">
        <h2 className="modal-header-label">{msg("CANCEL_POLICY_LABEL")} </h2>
        <button onClick={onDismiss} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>

      <div
        style={{
          flexDirection: "column",
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <div className="modal-subheading" style={{ marginBottom: 24 }}>
          {msg("CANCEL_POLICY_MODAL_SUBHEADING")}
        </div>
        <CancelPolicyIconBlock
          color={Colors.green500}
          label={msg("CANCEL_POLICY_MODAL_GREEN_LABEL")}
          body={msg("CANCEL_POLICY_MODAL_GREEN_BODY")}
        />
        <CancelPolicyIconBlock
          color={Colors.yellow500}
          label={msg("CANCEL_POLICY_MODAL_YELLOW_LABEL")}
          body={msg("CANCEL_POLICY_MODAL_YELLOW_BODY")}
        />
        <CancelPolicyIconBlock
          color={Colors.red500}
          label={msg("CANCEL_POLICY_MODAL_RED_LABEL")}
          body={msg("CANCEL_POLICY_MODAL_RED_BODY")}
        />
      </div>
    </div>
  );
}

function CancelPolicyIconBlock({
  color,
  label,
  body,
}: {
  color: string;
  label: string;
  body: string;
}) {
  return (
    <div style={{ flexDirection: "row", display: "flex", marginBottom: 24 }}>
      <div
        style={{
          width: 12,
          borderRadius: 8,
          backgroundColor: color,
        }}
      ></div>
      <div style={{ marginLeft: 16 }}>
        <div className="label">{label}</div>
        <div>{body}</div>
      </div>
    </div>
  );
}
