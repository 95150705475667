import { IonSkeletonText } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "~components";
import { UploadAnswerType } from "~onboarding/utils";
import { GET } from "~utils";
import styles from "./UploadAnswer.module.css";

type UploadAnswerProps = Omit<UploadAnswerType, "type"> & {
  /**
   * The function to be called when a document is uploaded
   */
  onChange: (value: string) => void;
  /**
   * Disables the ability to upload documents
   */
  isReadOnly?: boolean;
};

export function UploadAnswer(props: UploadAnswerProps) {
  const { label, onChange, isReadOnly } = props;

  // This query will upsert the folder name into box, and
  // return the folder name and an auth token that can be
  // subsequently used to upload files.
  //
  // The folder name defaults to the user's db-id.
  const onComplete = useCallback((fileId: string) => onChange(fileId), [onChange]);

  const { data: boxValues } = useQuery({
    queryKey: ["getBoxValues"],
    queryFn: () => GET("/api/sub/upload-metadata"),
    select: (data) => data.data,
  });

  /***** Render *****/
  if (!boxValues) {
    return (
      <IonSkeletonText animated={true} style={{ height: "32px", width: "186px", margin: "5px" }} />
    );
  }

  return (
    <BoxUploader
      label={label}
      folderId={boxValues.folderId}
      accessToken={boxValues.accessToken}
      onComplete={onComplete}
      isReadOnly={isReadOnly}
    />
  );
}

/********** Helper Component **********/
type BoxUploaderProps = {
  label: string;
  folderId: string;
  accessToken: string;
  onComplete: (fileId: string) => void;
  isReadOnly?: boolean;
};

/**
 * Displays the Box uploader UI which deals with uploading files to Box.
 * When the upload is complete, the fileId is passed to the parent component via `onComplete`.
 *
 * See https://developer.box.com/guides/embed/ui-elements/uploader
 */
function BoxUploader(props: BoxUploaderProps) {
  const { label, folderId, accessToken, onComplete, isReadOnly } = props;

  /***** State *****/
  const [isOpen, setIsOpen] = useState(false);

  /***** Refs *****/
  // Initiating the box uploader
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contentUploaderRef = useRef<any>(null);

  /***** Handlers *****/
  function handleClick() {
    // Handling Storybook mocking since we generate a fake access token and folder id and thus the upload will actually not work.
    if (window?.IS_STORYBOOK === true) {
      return onComplete("fake-file-id");
    }

    // Mount the upload container
    setIsOpen(true);
    // Show the uploader
    contentUploaderRef.current.show(folderId, accessToken, {
      container: `.${styles.uploadModal}`,
      fileLimit: 1,
    });
  }

  useEffect(() => {
    const contentUploader = new Box.ContentUploader();
    contentUploaderRef.current = contentUploader;

    // The `complete` event returns an array of file metadata
    // https://developer.box.com/guides/embed/ui-elements/uploader/#events
    contentUploader.addListener("complete", (data: { id: string }[]) => {
      setIsOpen(false);
      onComplete(data[0].id);
    });

    contentUploader.addListener("close", () => {
      setIsOpen(false);
    });

    return () => {
      contentUploader.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button label={label} disabled={isReadOnly} onClick={handleClick} />
      {/* Uploader container */}
      <div
        className={`${styles.uploadModal} ${isOpen === true ? styles["uploadModal-open"] : ""}`}
      />
    </div>
  );
}
