// import { GOOGLE_CLIENT_ID } from "~utils";
import { Capacitor } from "@capacitor/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { App } from "src/App";
import singletonStorage from "src/stores/store";
import { useAuth as _useAuth, AuthProvider } from "swing-components";

import {
  api,
  COGNITO_APP_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  GET,
  GOOGLE_CLIENT_ID,
  POST,
} from "~utils";

/********* Constants ********/
export const QUERY_CLIENT = new QueryClient();

export const STORAGE_AUTH_KEY = "auth";

// THIS MUST ALIGN WITH AWS COGNITO POOL
// Note: not been able to figure out how to get this via API.
// Default for refreshToken is 30 days, we are setting to 29 which will force user
// to sign back in then. Each time a user signs out and signs back into the app
// they get a new refresh token (along with a new idToken and accessToken)
// note: 1000 milliseconds * 60 seconds * 60 minutes * 24 hours * 29 days
export const COGNITO_REFRESH_TOKEN_VALIDITY_TIMESTAMP = new Date(
  Date.now() + 1000 * 60 * 60 * 24 * 29,
).getTime();

/********* User APIs *********/
const userApi = async () => await GET("/api/sub/me");
const userSyncApi = async () => await POST("/api/user/me");
// COGNITO USER POOL INITIALIZATION
const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_APP_CLIENT_ID,
  // defaults to localStorage but we want our own storage implementation
  Storage: singletonStorage,
});

/********* Component *********/
export function AuthWrapper() {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <AuthProvider
        api={api}
        cognitoRefreshTokenExpiration={COGNITO_REFRESH_TOKEN_VALIDITY_TIMESTAMP}
        googleClientId={GOOGLE_CLIENT_ID}
        isWeb={Capacitor.getPlatform() === "web"}
        queryClient={QUERY_CLIENT}
        storage={singletonStorage}
        storageAuthKey={STORAGE_AUTH_KEY}
        userPool={userPool}
        userApi={userApi}
        userSyncApi={userSyncApi}
      >
        <App />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

/********* Helper Components *********/
export type User = Awaited<ReturnType<typeof userApi>>;

/**
 * Typed `useAuth` for the Swing Sub user. This should be used vs the
 * swing-components one.
 */
export function useAuth() {
  return _useAuth<User>();
}
