var o = Object.defineProperty;
var h = (e, t, a) => t in e ? o(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var i = (e, t, a) => h(e, typeof t != "symbol" ? t + "" : t, a);
class u {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(t = {}) {
    // optional for testing purposes only
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    i(this, "authData");
    this.authData = t;
  }
  get provider() {
    var t;
    return (t = this.authData) == null ? void 0 : t.provider;
  }
  get token() {
    var t, a, r;
    switch ((t = this.authData) == null ? void 0 : t.provider) {
      case "google":
        return (a = this.authData) == null ? void 0 : a.authentication.idToken;
      case "cognito":
        return (r = this.authData) == null ? void 0 : r.idToken.jwtToken;
      default:
        return "";
    }
  }
  get refreshTokenExpiration() {
    var t, a;
    switch ((t = this.authData) == null ? void 0 : t.provider) {
      case "cognito":
        return (a = this.authData) == null ? void 0 : a.refreshTokenExpiration;
      default:
        return;
    }
  }
}
export {
  u as AuthModel
};
