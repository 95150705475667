import React, { ReactNode } from "react";
import { Colors } from "swing-components";

import { colors } from "~utils";

const iconSize = 18;

const styles = {
  container: ({ isDisabled }: { isDisabled: boolean | undefined }) =>
    ({
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "flex-start",
      background: "#fff",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }) as React.CSSProperties,
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "19px 24px",
    cursor: "inherit",
  } as React.CSSProperties,
  icon: ({ isDisabled, isChecked }: { isDisabled?: boolean; isChecked?: boolean }) => {
    const borderColor = isDisabled ? colors.inactiveGray200 : Colors.blue500;
    const backgroundColor = isDisabled
      ? colors.inactiveGray200
      : isChecked
        ? Colors.blue500
        : Colors.white200;
    return {
      width: iconSize,
      height: iconSize,
      position: "relative",
      transition: "background 180ms cubic-bezier(0.4, 0, 0.2, 1)",
      borderRadius: `calc(${iconSize}px * 0.125)`,
      border: `2px solid ${borderColor}`,
      background: backgroundColor,
      boxSizing: "border-box",
    } as React.CSSProperties;
  },
  iconPath: ({ isChecked = false }) =>
    ({
      fill: "none",
      stroke: Colors.white200,
      strokeWidth: "3",
      opacity: isChecked ? "1" : "0",
      strokeDasharray: "30",
      strokeDashoffset: isChecked ? "0" : "30",
      ...(isChecked && { transition: "stroke-dashoffset 90ms linear 90ms" }),
    }) as React.CSSProperties,
  nativeInput: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: 0,
    outline: 0,
    clip: "rect(0px, 0px, 0px, 0px)",
    opacity: 0,
    overflow: "hidden",
    appearance: "none",
  } as React.CSSProperties,
};

type CheckboxProps = {
  isChecked?: boolean;
  label?: string | ReactNode;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
};

function CheckIcon(props: CheckboxProps) {
  return (
    <svg viewBox="0 0 24 24" style={styles.icon(props)}>
      <path style={styles.iconPath(props)} d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
    </svg>
  );
}

export function Checkbox(props: CheckboxProps) {
  const { isDisabled, isChecked, label, onChange = () => {} } = props;
  return (
    <label style={styles.container({ isDisabled })}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => {
          if (!isDisabled) onChange(e.currentTarget.checked);
        }}
        style={styles.nativeInput}
      />
      <div style={styles.iconWrapper}>
        <CheckIcon {...props}></CheckIcon>
      </div>
      {label}
    </label>
  );
}
