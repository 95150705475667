import { TaskCardBase, TaskCardBaseProps } from "~components";
import type { TaskComputed } from "~onboarding/utils";
import { formatAsMonthAndDay } from "~utils";

type TaskCardProps = Omit<TaskComputed, "steps"> & {
  /**
   * Fn to handle click events
   */
  onClick?: () => void;
  /**
   * Boolean to determine whether Task is locked currently
   */
  disabled?: boolean;
};

function taskCardBaseProps(props: TaskCardProps): TaskCardBaseProps {
  const { icon, durationTimeText, name, progress, status, updatedAt, disabled, onClick } = props;

  const progressPercent = progress * 100;
  const baseProps = {
    title: name,
    description: durationTimeText,
    onClick: onClick,
  };

  if (disabled) {
    return {
      ...baseProps,
      progressIcon: {
        icon: "Lock",
        color: "SLATE",
        percentage: progressPercent,
      },
    };
  }

  if (status === "UNDER_REVIEW") {
    return {
      ...baseProps,
      boxShadow: true,
      progressIcon: {
        icon: "Hourglass",
        color: "PURPLE",
        percentage: progressPercent,
      },
      chip: {
        color: "PURPLE",
        // Forcing `updateAt` since if there is a `status`, there must be an `updatedAt`.
        label: `Submitted on ${formatAsMonthAndDay(updatedAt!)}`,
      },
    };
  }

  if (status === "ACTION_REQUIRED") {
    return {
      ...baseProps,
      boxShadow: true,
      progressIcon: {
        icon: "Warning",
        color: "YELLOW",
        percentage: progressPercent,
      },
      chip: {
        color: "YELLOW",
        label: "Action Required",
      },
    };
  }

  if (status === "COMPLETED") {
    return {
      ...baseProps,
      boxShadow: true,
      progressIcon: {
        icon: "Check Circle",
        color: "GREEN",
        percentage: progressPercent,
      },
    };
  }

  return {
    ...baseProps,
    boxShadow: true,
    progressIcon: {
      icon: icon,
      color: "BLUE",
      percentage: progressPercent,
    },
  };
}

export function TaskCard(props: TaskCardProps) {
  return <TaskCardBase {...taskCardBaseProps(props)} />;
}
