import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useReducer } from "react";
import { ExtractRouteParams, generatePath, RouteComponentProps } from "react-router";

import { ContentSingleColumn, QUERY_CLIENT, useAuth } from "~components";
import { OnboardingPage } from "~onboarding/components";
import { ONBOARDING_ROUTES } from "~onboarding/index";
import { getConfiguration, replacePath } from "~onboarding/utils";
import { GET, POST, POSTBody } from "~utils";
import { TaskPageBase } from "./Task.page.base";
import { TaskPageReducer, TaskPageReducerState } from "./Task.page.reducer";

type TaskPageProps = RouteComponentProps<
  ExtractRouteParams<(typeof ONBOARDING_ROUTES)["task"], string>
>;

/***** Component *****/
export function TaskPage(props: TaskPageProps) {
  const { history, match } = props;
  const { region, task: taskIndex, step: stepIndex } = match.params;

  /***** Hooks *****/
  const { refreshUser } = useAuth();

  /***** Effects *****/
  // Refresh the sub's details when mounting to capture any changes in their
  // `onboardingStatus` which if there is, the parent routers will redirect
  // them to the correct page.
  useEffect(() => {
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***** State *****/
  const [state, dispatch] = useReducer(TaskPageReducer, {
    // Setting the initial state to the URL value if it exists
    stepIndex: { initial: stepIndex ? parseInt(stepIndex) : undefined },
    // Set to true since we don't have the `taskComputed` field yet.
    isLoading: true,
    // Handlers
    onClose: handleClose,
    onSubmit: handleSubmit,
    onStepChange: handleStepChange,
  } satisfies TaskPageReducerState);

  /***** Queries *****/
  // Get the Task configuration for the region
  const { data: task } = useQuery({
    queryKey: ["configuration", region],
    queryFn: () => getConfiguration(region),
    // Cache the configuration for the lifetime of the application since this
    // only changes on new deployments which will require an application refresh.
    // This will make all future calls using this key to not make a network request.
    staleTime: Infinity,
    select: (configuration) =>
      configuration?.groups.flatMap((group) => group.tasks)[parseInt(taskIndex)],
  });
  useEffect(() => task && dispatch({ type: "SET_TASK", payload: { task } }), [task]);

  // Get the sub attributes which are used to calculate the progress of each Task
  const { data: subAttributes } = useQuery({
    queryKey: ["attributes"],
    queryFn: () => GET("/api/sub/attributes"),
    // Since the response is `{ data: [...subAttribute] }` we can clean up the response
    select: (data) =>
      data?.data.map((attribute) => ({ ...attribute, updatedAt: new Date(attribute.updatedAt) })),
  });
  useEffect(
    () => subAttributes && dispatch({ type: "SET_SUB_ATTRIBUTES", payload: { subAttributes } }),
    // Using `JSON.stringify` to compare the array of sub attributes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(subAttributes)],
  );

  /***** Mutations *****/
  const { mutate: submitAnswers } = useMutation({
    mutationKey: ["submitAnswers"],
    // FIXME: Change the `identifier` to `attribute` in the API layer to keep the response object
    // fields consistent.
    mutationFn: (answers: POSTBody<"/api/sub/attributes">) => POST("/api/sub/attributes", answers),
    onSuccess: (newSubAttribute) => {
      // Update the cache with the new sub attributes
      QUERY_CLIENT.setQueryData(["attributes"], newSubAttribute);
      // Refresh the user in case they have `COMPLETED` the onboarding
      // requirements or have been `REJECTED` during the process.
      refreshUser();
    },
  });

  /***** Handlers *****/
  function handleSubmit(state: Parameters<TaskPageReducerState["onSubmit"]>[0]) {
    return submitAnswers(
      // @ts-expect-error - @KoltonG This is having a hard time handing the
      // discriminant union type.
      state.unsyncedSubAttributes.map((usa) => ({
        identifier: usa.attribute,
        value: usa.value,
      })),
    );
  }

  function handleStepChange(newStepIndex: number) {
    const newUrl = generatePath(ONBOARDING_ROUTES.task, {
      region,
      task: taskIndex,
      step: newStepIndex,
    });

    replacePath(newUrl);
  }

  function handleClose() {
    history.push(generatePath(ONBOARDING_ROUTES["tasks"], { region }));
  }

  /***** Render *****/
  if (!state.taskComputed || state.stepIndex?.current === undefined) {
    return <OnboardingPage isLoading={true} />;
  }

  return (
    <OnboardingPage>
      <ContentSingleColumn>
        <TaskPageBase
          taskComputed={state.taskComputed}
          stepIndex={state.stepIndex?.current}
          isLoading={state.isLoading}
          isReadOnly={state.isReadOnly}
          onAnswerChange={(subAttribute) =>
            dispatch({ type: "UPDATE_ANSWER", payload: { subAttribute } })
          }
          onNextButtonClick={() => dispatch({ type: "NEXT_BUTTON" })}
          onPrevButtonClick={() => dispatch({ type: "PREV_BUTTON" })}
          onClose={handleClose}
        />
      </ContentSingleColumn>
    </OnboardingPage>
  );
}
