import { IconBlockGroup } from "swing-components";

import { ContentNavigationItem, ContentSingleColumn } from "~components";
import { msg } from "~utils";
import { createMyEarningsUrl, createPaymentSetupUrl } from "../../Routes";
import { ScorePage } from "../../ScoreTemplates/ScorePage";
import styles from "./Payment.module.css";

export function Payment() {
  return (
    <ScorePage title={msg("PAGE_TITLE_PAYMENT")} hasBack>
      <ContentSingleColumn>
        <div className={styles["payment-menu-wrapper"]}>
          <IconBlockGroup>
            <ContentNavigationItem
              navigationUrl={createMyEarningsUrl()}
              icon={{ name: "Payments" }}
              label={msg("PAGE_TITLE_PAYMENT_MY_EARNINGS")}
              hasWhiteBackground={false}
            />
            <ContentNavigationItem
              navigationUrl={createPaymentSetupUrl()}
              icon={{ name: "Monetization" }}
              label={msg("PAGE_TITLE_PAYMENT_SETUP")}
              hasWhiteBackground={false}
            />
          </IconBlockGroup>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}
