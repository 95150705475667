import { useHistory } from "react-router";
import { Colors, Icon } from "swing-components";

import { generateBackNavigation, msg } from "~utils";
import styles from "./Header.module.css";

type HeaderProps = {
  title: string;
  hasBack: boolean;
};

export function Header(props: HeaderProps) {
  const { title, hasBack } = props;
  const history = useHistory();

  const handleOnBack = () => {
    return generateBackNavigation(history);
  };

  return (
    <div className={styles["score-page-header"]}>
      {hasBack && (
        <div className={styles["page-header-back-wrapper"]} onClick={handleOnBack}>
          <Icon name="Arrow Left" color={Colors.blue500} />
          <span className={styles["header-back-navigation-label"]}>{msg("LABEL_BACK")}</span>
        </div>
      )}
      <span className={styles["page-header-title"]}>{title}</span>
    </div>
  );
}
