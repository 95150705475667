import { ReactNode, useState } from "react";
import { Colors } from "swing-components";

import { Accordion, AccordionProps, Chip } from "~components";
import { EmptyTaskCard, TaskCard } from "~onboarding/components";
import type { GroupComputed, TaskComputed } from "~onboarding/utils";
import { maybePluralize } from "~utils";

/********** Components **********/
type TasksPageBaseProps = {
  groupsComputed: GroupComputed[];
  onTaskClick: (taskIndex: number) => void;
};

export function TasksPageBase(props: TasksPageBaseProps) {
  const { groupsComputed, onTaskClick } = props;

  /***** Constants *****/
  const upcomingTasks = groupsComputed
    .filter((group) => group.status === "BLOCKED")
    // return a flat array of blocked tasks
    .flatMap((group) => group.tasks);

  const toDoTasks = groupsComputed
    .filter((group) => group.status === "IN_PROGRESS")
    // get a flat array of in progress tasks
    .flatMap((group) => group.tasks)
    // filter tasks that have no status or action required
    .filter((task: TaskComputed) => !task.status || task.status === "ACTION_REQUIRED")
    // sort to bring action required tasks to the top
    .sort((taskA, taskB) => {
      if (taskA.status === "ACTION_REQUIRED" && taskB.status === "ACTION_REQUIRED") {
        return 0;
      } else if (taskA.status === "ACTION_REQUIRED" && taskB.status !== "ACTION_REQUIRED") {
        return -1;
      } else {
        return 1;
      }
    });

  const underReviewTasks = groupsComputed
    .filter((group) => group.status === "IN_PROGRESS")
    .flatMap((group) => group.tasks)
    // filter statuses which are under review or awaiting results
    .filter((task) => task.status === "UNDER_REVIEW")
    // sort by recently updated
    .sort((taskA, taskB) => {
      if (taskA.updatedAt && taskB.updatedAt) {
        return new Date(taskB.updatedAt).valueOf() - new Date(taskA.updatedAt).valueOf();
      } else return 0;
    });

  const completedTasks = groupsComputed
    .flatMap((group) => group.tasks)
    // filter completed tasks from a flat map of all tasks because there can be
    // completed tasks in an IN_PROGRESS group
    .filter((task: TaskComputed) => task.status === "COMPLETED")
    // sort by recently completed
    .sort((taskA, taskB) => {
      if (taskA.updatedAt && taskB.updatedAt) {
        return new Date(taskB.updatedAt).valueOf() - new Date(taskA.updatedAt).valueOf();
      } else return 0;
    });

  /***** Handlers *****/
  function handleTaskClick(taskName: string) {
    const taskIndex = groupsComputed
      .flatMap((group) => group.tasks)
      .findIndex((task) => task.name === taskName);
    if (taskIndex !== undefined) {
      onTaskClick(taskIndex);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Task name: ${taskName} not found`);
    }
  }

  /***** Render *****/
  return (
    <div style={{ flexGrow: 1 }}>
      <header
        style={{
          padding: "24px 16px",
          alignSelf: "stretch",
          borderRadius: "8px 8px 0px 0px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
          marginBottom: "2px",
          background: Colors.white200,
        }}
      >
        <p style={{ fontSize: "20px", fontWeight: "600" }}>Welcome</p>
        <p style={{ fontSize: "16px" }}>
          Here’s where you’ll find everything you need to get ready to teach through Swing.
        </p>
      </header>

      {/* TODO Accordions */}
      <AccordionWrapper title="To-do" count={toDoTasks.length} startsOpen={true}>
        {TaskCards({
          tasks: toDoTasks,
          onTaskClick: handleTaskClick,
          emptyTitle: "You're all caught up",
          emptyDesc:
            "We'll share your next tasks here once we've reviewed your Under Review tasks.",
        })}
      </AccordionWrapper>

      {/* Under Review Accordions */}
      <AccordionWrapper
        title="Under Review"
        count={underReviewTasks.length}
        // Open when there is at least one task
        startsOpen={underReviewTasks.length > 0}
      >
        {TaskCards({
          tasks: underReviewTasks,
          onTaskClick: handleTaskClick,
          emptyTitle: "Nothing right now",
          emptyDesc: "When we need to review a task you'll find it here.",
        })}
      </AccordionWrapper>

      {/* Upcoming Accordions */}
      <AccordionWrapper title="Upcoming" count={upcomingTasks.length} startsOpen={false}>
        {TaskCards({
          tasks: upcomingTasks,
          disabled: true,
          emptyTitle: "You're almost done",
          emptyDesc: "Once you complete your final tasks you'll be ready to teach.",
        })}
      </AccordionWrapper>

      {/* Complete Accordions */}
      <AccordionWrapper title="Completed" count={completedTasks.length} startsOpen={false}>
        {TaskCards({
          tasks: completedTasks,
          onTaskClick: handleTaskClick,
          emptyTitle: "You'll see your hard work here",
          emptyDesc: "You'll find your completed tasks here.",
        })}
      </AccordionWrapper>
    </div>
  );
}

/********** Helper Components **********/
function AccordionTitle(props: { count: number; title: ReactNode }) {
  const { count, title } = props;

  return (
    <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
      <span style={{ fontWeight: "bold" }}>{title}</span>
      <span>
        <Chip color="WHITE" label={maybePluralize("task", count)} />
      </span>
    </div>
  );
}

function AccordionWrapper(
  props: Pick<AccordionProps, "title" | "children"> & { startsOpen: boolean; count: number },
) {
  const { title, children, startsOpen, count } = props;

  /***** Hooks *****/
  const [isOpen, setIsOpen] = useState(startsOpen);

  /***** Handlers *****/
  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  /***** Render *****/
  return (
    <Accordion
      isOpen={isOpen}
      title={<AccordionTitle title={title} count={count} />}
      onClick={handleOnClick}
    >
      {children}
    </Accordion>
  );
}

function TaskCards(props: {
  tasks: TaskComputed[];
  emptyTitle: string;
  emptyDesc: string;
  onTaskClick?: (taskName: string) => void;
  disabled?: boolean;
}) {
  const { tasks, emptyTitle, emptyDesc, onTaskClick, disabled } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      {tasks.length === 0 && (
        <div style={{ width: "100%" }}>
          <EmptyTaskCard title={emptyTitle} description={emptyDesc} />
        </div>
      )}
      {tasks.length !== 0 &&
        tasks.map((task) => (
          <div style={{ width: "100%" }} key={task.name}>
            <TaskCard
              {...{
                ...task,
                ...(onTaskClick ? { onClick: () => onTaskClick(task.name) } : {}),
                disabled,
              }}
            />
          </div>
        ))}
    </div>
  );
}
