const string_table = {
  ALL_DATES_TIMES_LABEL: () => "All Dates / Times",
  ARIA_LABEL_SELECTED: () => "selected",
  ARIA_LABEL_DESELECTED: () => "deselected",
  CANCEL_ALERT_MESSAGE: () => "Reason",
  CANCEL_ALERT_TITLE: () => "You've canceled.",
  CANCEL_COMMITMENT_MODAL_BUTTON_SUBMIT: () => "Cancel Request",
  CANCEL_COMMITMENT_MODAL_BUTTON_CANCEL: () => "Don't Cancel",
  CANCEL_COMMITMENT_MODAL_MESSAGE: () => "Why are you canceling?",
  CANCEL_COMMITMENT_MODAL_OTHER_PLACEHOLDER: () => "Please describe",
  CANCEL_COMMITMENT_MODAL_TITLE: () => "Cancel Request",
  CANCEL_LAST_MINUTE_ALREADY_STARTED: () => "This request has already started",
  CANCEL_LAST_MINUTE_IMPACT_MESSAGE: () =>
    "Canceling now may impact your sub rating and standing with schools",
  CANCEL_LAST_MINUTE_TITLE: () => "Last minute cancelation",
  CANCEL_LAST_MINUTE_WITHIN_DAY: () => "This request begins in less than 24 hours",
  CANCEL_REASON_CLICKED_BY_ACCIDENT: () => "Clicked by accident",
  CANCEL_REASON_SICKNESS: () => "Sickness",
  CANCEL_REASON_SCHEDULE_CONFLICT: () => "Schedule conflict",
  CANCEL_POLICY_BUTTON_SUBHEADING: () => "To avoid a penalty cancel at least 24 hours in advance",
  CANCEL_POLICY_LABEL: () => "Cancellation Policy",
  CANCEL_POLICY_WARNING_NO_PENALTY: () =>
    "Cancel at least 24 hours before the request start to avoid a penalty. ",
  CANCEL_POLICY_WARNING_PENALTY: () =>
    "This request starts in less than 24 hours. If you cancel after signing up it will be considered a last minute cancellation. ",
  CANCEL_POLICY_MODAL_GREEN_BODY: () => "Cancel without a penalty.",
  CANCEL_POLICY_MODAL_GREEN_LABEL: () => "Within 30 mins of signup",
  CANCEL_POLICY_MODAL_RED_BODY: () => "You'll be penalized for a last minute cancellation.",
  CANCEL_POLICY_MODAL_RED_LABEL: () => "Less than 24 hours before",
  CANCEL_POLICY_MODAL_YELLOW_BODY: () => "This gives the school time to find a new sub.",
  CANCEL_POLICY_MODAL_YELLOW_LABEL: () => "More than 24 hours before",
  CANCEL_POLICY_MODAL_SUBHEADING: () =>
    "We know that life happens. If you must cancel, please use this guidance.",
  COMMITMENT_CARD_CONFLICT: () => "This conflicts with a request you've signed up for",
  COMMITMENTS_SEGMENT_UPCOMING: () => "Upcoming",
  COMMITMENTS_SEGMENT_PAST: () => "Past",
  CONFIRM_REQUEST_MESSAGE_LINK_LABEL: () => "confirm this request",
  CONFIRM_REQUEST_MESSAGE_1: ({
    schoolName,
    startDate,
    endDate,
  }: {
    schoolName: string;
    startDate: string;
    endDate: string;
  }) => `${schoolName} has selected you for the ${startDate} to ${endDate} request. To sign up`,
  CONFIRM_REQUEST_MESSAGE_2: () => "by",
  CONFIRM_REQUEST_MESSAGE_3: () => "at",
  CONFIRM_REQUEST_TITLE: () => "Congratulations! You've been selected from the waitlist",
  DATES_TIMES_LABEL: () => "All dates/times",
  DO: () => "do",
  DO_NOT: () => "do not",
  EDUCATION_LANDING_SUB_HEADER: () => "Showcase up to 3 educational experiences",
  ERROR_GENERAL: () => "Sorry, we've encountered an error",
  ERROR_INTERNAL: ({ errorCode }: { errorCode: string }) => `An error occurred. ${errorCode}`,
  GRADE_NOT_PROVIDED: () => "Grade not provided",
  GRADE_UNKNOWN: () => "Grade unknown (see notes)",
  HELP_CALL_OR_TEXT: () => "Call or Text Swing Support",
  HELP_CHAT_WITH_SUPPORT: () => "Chat with Swing Support",
  HELP_CONTACT_SUPPORT: () => "Contact Support",
  HELP_HELP_CENTER: () => "Help Center",
  HELP_PRIVACY_POLICY: () => "Privacy Policy",
  HELP_SUPPORT_SUB_NUMBER: () => "650-413-9268",
  HOURLY_SUFFIX: () => "hr",
  IF_YOU: () => "If you ",
  LESSON_PLAN_PROVIDED: () => "Provided Upon Arrival",
  LOCATION_SEARCH_CONTACT_SUPPORT_1: () => "Contact support",
  LOCATION_SEARCH_CONTACT_SUPPORT_2: () => "to change your state",
  LOCATION_SEARCH_DESCRIPTION: () =>
    "Enter your zipcode. Schools in your selected locations will appear in openings.",
  LOCATION_SEARCH_INVALID_ZIP_CODE: () => "Invalid zipcode",
  LOCATION_SEARCH_SHOWING_LOCATIONS: () => "Showing locations based on",
  LOCATION_SETTINGS_LOCATION: () => "location",
  LOCATION_SETTINGS_LOCATIONS: () => "locations",
  LOCATION_SETTINGS_SAVE_SUCCESS_MESSAGE: () =>
    "Schools in your selected locations will now appear in your openings",
  LOCATION_SETTINGS_SAVE_SUCCESS_TITLE: () => "Location preferences saved",
  LOCATION_SETTINGS_SCHOOL: () => "school",
  LOCATION_SETTINGS_SCHOOLS: () => "schools",
  LOGIN_NEED_HELP: () => "Need help? Email",
  MODAL_CONFIRMATION_SUB_SIGN_UP_MESSAGE: () => "will be notified once you sign up or cancel",
  MODAL_CONFIRMATION_SUB_SIGN_UP_TITLE: () => "You're signing up for",
  MODAL_CONFIRMATION_SUB_SIGN_UP_PRIMARY_BUTTON_TEXT: () => "Sign Up",
  MODAL_CONFIRMATION_SUB_SIGN_UP_SECONDARY_BUTTON_TEXT: () => "Nevermind",
  MODAL_CONFLICTING_COMMITMENTS_DECLINE_LABEL: () => "No, keep current commitments",
  MODAL_CONFLICTING_COMMITMENTS_ROW: ({ schoolName, date }: { schoolName: string; date: string }) =>
    `${date} at ${schoolName}`,
  MODAL_CONFLICTING_COMMITMENTS_MESSAGE: ({
    schoolName,
    startDate,
    endDate,
  }: {
    schoolName: string;
    startDate: string;
    endDate: string;
  }) =>
    `${schoolName} has selected you for the ${startDate} to ${endDate} request. To sign up for this request, we'll need to cancel your conflicting commitments. You won't be penalized for these cancelations.`,
  MODAL_CONFLICTING_COMMITMENTS_SIGN_UP_LABEL: () => "Yes, sign up",
  MODAL_CONFLICTING_COMMITMENTS_SIGN_UP_QUESTION: () =>
    "Sign up for this request and cancel your conflicting commitments?",
  MODAL_CONFLICTING_COMMITMENTS_TITLE: () => "Conflicting Commitments",
  MODAL_JOIN_WAITLIST_TITLE: () => "You're joining the waitlist for",
  MODAL_JOIN_WAITLIST_PRIMARY_BUTTON_TEXT: () => "Join Waitlist",
  MODAL_JOIN_WAITLIST_SECONDARY_BUTTON_TEXT: () => "Nevermind",
  MODAL_JOIN_WAITLIST_POLICY: () => "Waitlist Policy",
  MODAL_JOIN_WAITLIST_BEFORE: () => "Before being selected:",
  MODAL_JOIN_WAITLIST_REMOVE: () =>
    "You can remove yourself from the waitlist at any time without penalty.",
  MODAL_JOIN_WAITLIST_AFTER: () => "After being selected",
  MODAL_JOIN_WAITLIST_NO_CONFLICTING: () =>
    " have any conflicting requests, you'll be automatically confirmed for this request.",
  MODAL_JOIN_WAITLIST_CONFLICTING: () =>
    " have conflicting requests, you'll have the option of cancelling your conflicting requests without penalty.",
  MORE_HELP: () => "Help",
  MORE_LOCATION_SETTINGS: () => "Location Settings",
  MORE_LOGOUT: () => "Log Out",
  MORE_REFERRAL_PROGRAM: () => "Referral Program",
  MORE_REFERRAL_PROGRAM_WIDGET_TYPE: () => "p/hubspot-referral-program/w/referrerWidget",
  MORE_RESOURCES: () => "Resources",
  MORE_SETTINGS: () => "Settings",
  MY_REQUESTS_SECTION_DESCRIPTION_PAST: () => "Requests you've completed in the past 30 days",
  MY_REQUESTS_SECTION_DESCRIPTION_PENDING: () =>
    "You're on the waitlist for these requests. We'll notify you if the school selects you",
  MY_REQUESTS_SECTION_HEADER_LATER: () => "Later",
  MY_REQUESTS_SECTION_HEADER_NEXT_FIVE_DAYS: () => "Next 5 days",
  MY_REQUESTS_SECTION_HEADER_ONGOING: () => "Ongoing",
  MY_REQUESTS_SECTION_HEADER_PENDING: () => "Pending",
  NAV_COMMITMENTS: () => "Commitments",
  NAV_DETAILS: () => "Details",
  NAV_MORE: () => "More",
  NAV_MY_REQUESTS: () => "My Requests",
  NAV_OPENINGS: () => "Openings",
  NAV_PROFILE: () => "Profile",
  OPENINGS_CARD_DATE_MULTI_DAY: () => "days",
  OPENINGS_CARD_DATE_SINGLE_DAY: () => "day",
  OPENINGS_CARD_PAY_NON_HOURLY: () => "total",
  OPENINGS_CARD_PAY_HOURLY: () => "per hour",
  OPENINGS_NONE_AVAILABLE: () => "No open requests right now",
  OPENINGS_RETURN_LATER: () =>
    "Check back soon. When schools in your area post requests, you'll see them here",
  PAGE_TITLE_EDUCATION_EDIT: () => "Add Education",
  PAGE_TITLE_EDUCATION_LANDING: () => "Edit Education",
  PAGE_TITLE_HELP: () => "Help",
  PAGE_TITLE_MORE: () => "More",
  PAGE_TITLE_MY_REQUESTS: () => "My Requests",
  PAGE_TITLE_OPENINGS: () => "Openings",
  PAGE_TITLE_PAY: () => "Pay",
  PAGE_TITLE_PAYMENT: () => "Payment",
  PAGE_TITLE_PAYMENT_MY_EARNINGS: () => "My Earnings",
  PAGE_TITLE_PAYMENT_SETUP: () => "Payment Setup",
  PAGE_TITLE_PROFILE: () => "My Profile",
  PAGE_TITLE_REQUEST_DETAILS: () => "Request Details",
  PAGE_TITLE_SETTINGS: () => "Settings",
  PAGE_TITLE_WORK_EXPERIENCE_EDIT: () => "Add Work Experience",
  PAGE_TITLE_WORK_EXPERIENCE_LANDING: () => "Edit Work Experience",
  PAGE_TITLE_WORK_EXPERIENCE_LANDING_SUB_HEADER: () => "Showcase up to 5 work experiences",
  PAY_BREAKDOWN_FOOTER: () => "More about your pay",
  PAY_BREAKDOWN_LABEL: () => "Pay Breakdown",
  PAY_DATE_DISCLAIMER: () => "You are paid every Friday for the previous week's work.",
  PHONE_VERIFICATION_ALERT_TITLE: () => "Never miss an opportunity",
  PHONE_VERIFICATION_ALERT_MSG: () => "to receive teaching jobs right to your phone.",
  PHONE_VERIFICATION_ALERT_MSG_LINK_LABEL: () => "Verify your phone number",
  PHONE_VERIFICATION_BADGE_LABEL: () => "Needs Verification",
  PHONE_VERIFICATION_ENTER_CODE_ALERT_TITLE: () => "Check your text messages",
  PHONE_VERIFICATION_ENTER_CODE_ALERT_MSG: () => "Enter the verification code we sent to",
  PHONE_VERIFICATION_ENTER_CODE_ALERT_ERROR: () =>
    "The code you entered is incorrect or expired. Try again or request a new code.",
  PHONE_VERIFICATION_ENTER_CODE_MISSING_CODE: () => "Didn't get a code?",
  PHONE_VERIFICATION_ENTER_CODE_REQUEST_NEW_CODE: () => "Request a new code",
  PHONE_VERIFICATION_ENTER_CODE_TOAST_MSG: () => "Code sent",
  PROFILE_EDIT_ABOUT_ME_LABEL: () => "Edit About Me",
  PROFILE_EDIT_ABOUT_ME_SUB_HEADER: () => "About Me",
  PROFILE_EDIT_ABOUT_ME_PLACEHOLDER: () => "Share some information about yourself",
  PROFILE_EDIT_EDUCATION_INPUT_LABEL_AND_PLACEHOLDER: () => "School Name",
  PROFILE_EDIT_PREFERRED_GRADE_LEVELS_LABEL: () => "Preferred Grade Levels",
  PROFILE_EDIT_PREFERRED_LANGUAGES_LABEL: () => "Preferred Languages",
  PROFILE_EDIT_PREFERRED_SUBJECTS_LABEL: () => "Preferred Subjects",
  PROFILE_PHONE_NUMBER_EMPTY_INPUT_ERROR: () =>
    "Your existing phone number is not 10 digits. Please enter a valid 10-digit number",
  PROFILE_PHONE_NUMBER_INPUT_LABEL: () => "Phone number",
  PROFILE_PHONE_NUMBER_INVALID_ERROR_MESSAGE: () =>
    "Enter a valid phone number. If you're having trouble",
  PROFILE_PHONE_NUMBER_REQUEST_CODE_BUTTON_LABEL: () => "Request Code",
  PROFILE_PHONE_NUMBER_REQUEST_CODE_MESSAGE: () =>
    "Enter and verify your 10-digit phone number to receive important updates from Swing",
  PROFILE_PHONE_NUMBER_STEP_ERROR_MESSAGE: ({ step }: { step: string }) =>
    `Step: ${step} is currently not supported`,
  PROFILE_PHONE_NUMBER_SUCCESS_CONTENT_BODY: () =>
    "You'll now receive important text updates from Swing",
  PROFILE_PHONE_NUMBER_SUCCESS_CONTENT_TITLE: () => "Your phone number is verified",
  PROFILE_PHONE_NUMBER_UPDATE_NEVERMIND_LABEL: () => "Nevermind",
  PROFILE_PHONE_NUMBER_UPDATE_TITLE: () => "Update Your Number",
  PROFILE_PHONE_NUMBER_VERIFY_TITLE: () => "Verify Your Number",
  PROFILE_PHONE_NUMBER_VERIFY_LATER_LABEL: () => "I'll do this later",
  PROFILE_SET_UP_CONTENT: () =>
    "Adding a photo, story about why you sub, your education, and your work experience increases your chances of getting hired for long-term requests.",
  PROFILE_SET_UP_HEADER: () => "Schools want to know more about you!",
  PROFILE_SET_UP_IMAGE_ALT: () =>
    "a cartoon person standing and looking through binoculars larger than the person",
  PROFILE_SET_UP_LINK: () => "How do I set up my profile?",
  PROFILE_WORK_EXPERIENCE_CURRENT_JOB: () => "Current Job?",
  PROFILE_WORK_EXPERIENCE_GRADE_LABEL: () => "Grade Levels",
  PROFILE_WORK_EXPERIENCE_ORGANIZATION_LABEL: () => "Organization",
  PROFILE_WORK_EXPERIENCE_ORGANIZATION_PLACEHOLDER: () => "Organization Name",
  PROFILE_WORK_EXPERIENCE_TITLE_LABEL: () => "Title",
  PROFILE_WORK_EXPERIENCE_TITLE_PLEACEHOLDER: () => "Role Name",
  PROFILE_WORK_EXPERIENCE_TYPE_LABEL: () => "Type",
  REQUEST_CARD_DETAIL_CONTENT_CANCELED: () => "This request was canceled.",
  REQUEST_CARD_DETAIL_CONTENT_COMPLETED: () => "You completed this request.",
  REQUEST_CARD_DETAIL_CONTENT_FILLED: () => "This request has been filled by another sub.",
  REQUEST_CARD_DETAIL_CONTENT_NO_SHOW: () => "You have been marked a no-show for this request.",
  REQUEST_CARD_DETAIL_CONTENT_OPEN: () => "This request is available.",
  REQUEST_CARD_DETAIL_CONTENT_PENDING: () => "School is reviewing interested subs.",
  REQUEST_CARD_DETAIL_CONTENT_SIGNED_UP: () => "You're confirmed for this request!",
  REQUEST_CARD_DETAIL_LABEL_CANCELED: () => "Canceled",
  REQUEST_CARD_DETAIL_LABEL_COMPLETED: () => "Completed",
  REQUEST_CARD_DETAIL_LABEL_FILLED: () => "Already filled",
  REQUEST_CARD_DETAIL_LABEL_NO_SHOW: () => "No-show",
  REQUEST_CARD_DETAIL_LABEL_PENDING: () => "Pending review",
  REQUEST_CARD_DETAIL_LABEL_SIGNED_UP: () => "You're signed up",
  REQUEST_CARD_LABEL_TEXT: () => "Open",
  REQUEST_TIME_UNIT: () => "Days",
  SCHOOL_FEEDBACK_ADDITIONAL_INFO_LABEL: () => "Anything else you'd like to share? (Optional)",
  SCHOOL_FEEDBACK_ADDITIONAL_INFO_PLACEHOLDER: () => "Share any additional information here",
  SCHOOL_FEEDBACK_ADDITIONAL_INFO_SUBMIT_BUTTON_LABEL: () => "Submit Review",
  SCHOOL_FEEDBACK_RATING_MESSAGE: () =>
    "Let us know how it went so we can improve your school experience. Schools will not see your specific review",
  SCHOOL_FEEDBACK_RATING_QUESTION: ({ schoolName }: { schoolName: string }) =>
    `How was your time at ${schoolName}`,
  SCHOOL_FEEDBACK_RATING_QUESTION_DEFAULT_SCHOOL: () => "this school",
  SCHOOL_FEEDBACK_REVIEWED_LABEL: () => "Reviewed",
  SCHOOL_FEEDBACK_SUBMITTED_ADDITIONAL_INFO_TITLE: () => "Additional comments",
  SCHOOL_FEEDBACK_SUBMITTED_DEFAULT_DATE_MSG: () => "a previous date",
  SCHOOL_FEEDBACK_SUBMITTED_TITLE: () => "Review Submitted",
  SCHOOL_FEEDBACK_SUBMITTED_MSG: () => " Thanks for helping us improve your school experience",
  SCHOOL_FEEDBACK_SUBMITTED_ON_MSG: ({ date }: { date: string }) => `Submitted on ${date}`,
  SCHOOL_FEEDBACK_SUBMITTED_OVERALL_LABEL: () => "Overall experience",
  SCHOOL_FEEDBACK_SUBMITTED_REASONS_LABEL_DEFAULT: () => "What could have been better",
  SCHOOL_FEEDBACK_SUBMITTED_REASONS_LABEL_POSITIVE: () => "What went well",
  SCHOOL_FEEDBACK_TITLE: () => "Leave Review",
  SCHOOL_FEEDBACK_REASONS_CHECK_IN: () => "Check-in",
  SCHOOL_FEEDBACK_REASONS_CLASSROOM_MANAGEMENT: () => "Classroom management",
  SCHOOL_FEEDBACK_REASONS_LESSON_PLAN: () => "Lesson Plan",
  SCHOOL_FEEDBACK_REASONS_NEUTRAL_NEGATIVE_SUB_HEADER: () => "What could have been better?",
  SCHOOL_FEEDBACK_REASONS_NEGATIVE_PLACEHOLDER: () => "Describe what could've been better",
  SCHOOL_FEEDBACK_REASONS_OTHER_DESCRIPTION: () => "Additional info required",
  SCHOOL_FEEDBACK_REASONS_PARKING: () => "Parking",
  SCHOOL_FEEDBACK_REASONS_POSITIVE_PLACEHOLDER: () => "Describe what went well",
  SCHOOL_FEEDBACK_REASONS_POSITIVE_SUB_HEADER: () => "What went well?",
  SCHOOL_FEEDBACK_REASONS_ROUTINES_PROCEDURE: () => "School routines and procedures",
  SCHOOL_FEEDBACK_REASONS_STAFF_SUPPORT: () => "Staff support",
  SCHOOL_FEEDBACK_VIEW_REVIEW_LABEL: () => "View Review",
  SETTINGS_LOCATION_COMMUTE: () => "Location & Commute Time",
  SETTINGS_MY_DOCUMENTS: () => "My Documents",
  SETUP_CONTACT_SUPPORT: () =>
    "If you think this was an error or have any questions, please reach out to our support team.",
  SETUP_ILLUSTRATION_IMAGE_ALT: () => "Illustration of classroom equipment",
  SETUP_REJECTION_UNIVERSAL: () =>
    "We appreciate your interest in Swing. Unfortunately, we won't be able to move forward with your Swing Sub application at this time, as you did not meet one or more mandatory criteria to substitute teach with Swing.",
  SKILL_BUILDER_EMPTY_TASK_DESCRIPTION: () => "We'll be releasing new material soon",
  SKILL_BUILDER_EMPTY_TASK_TITLE: () => "Coming Soon",
  SKILL_BUILDER_FEEDBACK_BUTTON_SUBMIT: () => "Submit Feedback",
  SKILL_BUILDER_FEEDBACK_TITLE: () => "Feedback",
  SKILL_BUILDER_SUB_TEXT: () =>
    "Build your teaching skills and earn qualifications for your profile",
  SKILL_BUILDER_TITLE: () => "Skill Builder",
  SUBJECT_NOT_PROVIDED: () => "subject not provided",
  SUBJECT_UNKNOWN: () => "subject unknown (see notes)",
  SWING_SUBS_LINK_ACCOUNT_SETTINGS: ({ userId }: { userId: string }) => `/#/sub/${userId}/settings`,
  SWING_SUPPORT_LINK_GENERAL_REQUEST: () => "/hc/en-us/requests/new",
  SWING_SUPPORT_LINK_HELP_CENTER: () => "/hc/en-us#state",
  SWING_SUPPORT_LINK_PAY_1099: () =>
    "/hc/en-us/articles/115000778367-How-will-I-get-paid-and-how-often-",
  SWING_SUPPORT_LINK_PAY_W2: () =>
    "/hc/en-us/articles/28371675532051-How-will-I-get-paid-and-how-often-CA-subs",
  SWING_LINK_TERMS_OF_SERVICE: () => "/terms",
  UNPROVIDED_DATA_MESSAGE: () => "Not Provided",

  /* Labels */
  LABEL_ADDRESS: () => "Address",
  LABEL_ALIASED: () => "Aliased as",
  LABEL_BACK: () => "Back",
  LABEL_CAN_CONFIRM: () => "Confirm Me",
  LABEL_CANCEL: () => "Cancel",
  LABEL_CLOSE: () => "Close",
  LABEL_CONTACT: () => "Contact",
  LABEL_CONTACT_SUPPORT_1: () => "Please try again later or",
  LABEL_CONTACT_SUPPORT: () => "contact support",
  LABEL_CONTACT_SUPPORT_2: () => "for assistance.",
  LABEL_DATE: () => "Date",
  LABEL_DELETE: () => "Delete",
  LABEL_END: () => "End",
  LABEL_END_OF_PAGE: () => "You've reached the end",
  LABEL_EXPLORE_OPENINGS: () => "Explore Openings",
  LABEL_FULL_DAY: () => "Full Day",
  LABEL_GRADE: () => "Grade",
  LABEL_GREAT: () => "Great",
  LABEL_HALF_DAY: () => "Half Day",
  LABEL_HOURLY: () => "Hourly",
  LABEL_JOIN_WAITLIST: () => "Join the Waitlist",
  LABEL_LEAVE_WAITLIST: () => "Remove from Waitlist",
  LABEL_LESSON_PLAN: () => "Lesson Plan",
  LABEL_NEGATIVE: () => "Negative",
  LABEL_NEUTRAL: () => "Neutral",
  LABEL_NEW: () => "New",
  LABEL_NEXT: () => "Next",
  LABEL_NEXT_PAY_DATE: () => "Next pay date",
  LABEL_NONE: () => "None",
  LABEL_NOTES: () => "Notes",
  LABEL_OTHER: () => "Other",
  LABEL_PAY: () => "Pay",
  LABEL_PAY_DATE: () => "Pay date",
  LABEL_POSITIVE: () => "Positive",
  LABEL_SAVE: () => "Save",
  LABEL_SELECT: () => "Select",
  LABEL_SELECT_ALL_THAT_APPLY: () => "Select all that apply.",
  LABEL_SIGN_UP: () => "Sign Up",
  LABEL_SOMETHING_WENT_WRONG: () => "Something went wrong",
  LABEL_START: () => "Start",
  LABEL_SUBJECT: () => "Subject",
  LABEL_SWING_LOADING_ALT: () => "Swing Loading Icon",
  LABEL_TEACHER: () => "Teacher",
  LABEL_TIME: () => "Time",
  LABEL_TIMEZONE_DISCLAIMER: ({
    schoolName,
    timeZone,
  }: {
    schoolName: string;
    timeZone?: string;
  }) => `Times are displayed in ${schoolName}'s timezone (${timeZone})`,
  LABEL_TOTAL: () => "Total",
  LABEL_VERIFY: () => "Verify",
  EMPTY_PAST_REQUESTS_DESCRIPTION: () => "Explore openings to find your next request.",
  EMPTY_PAST_REQUESTS_TITLE: () => "You haven't completed a request in a bit",
  EMPTY_UPCOMING_REQUESTS_DESCRIPTION: () =>
    "Explore openings to see what's available in your area.",
  EMPTY_UPCOMING_REQUESTS_TITLE: () => "Ready to sign up for your next request?",
  VERIFICATION_CODE_INPUT_LABEL: () => "4-digit code",
  VERIFICATION_CODE_INPUT_PLACEHOLDER: () => "Verification Code",

  /* School Profile Pages */
  SCHOOL_PROFILE_TITLE: () => "School Profile",
  SCHOOL_WEBSITE_LABEL: () => "School Website",
  SCHOOL_SUMMARY_LABEL: () => "School Summary",

  SCHOOL_DETAILS_HEADER: () => "School Details",
  SCHOOL_SCHEDULE_LABEL: () => "School Schedule",

  ARRIVING_ONSITE_LABEL: () => "Arriving Onsite",
  DAY_OF_CONTACT_HEADER: () => "Day Of Contact",
  DAY_OF_CONTACT_NAME_LABEL: () => "Day-Of Contact Name",
  DAY_OF_CONTACT_PHONE_LABEL: () => "Phone",
  DAY_OF_CONTACT_EMAIL_LABEL: () => "Email",
  PARKING_HEADER: () => "Parking",
  PARKING_COST_LABEL: () => "Parking Cost (Hourly)",
  PARKING_LOCATION_LABEL: () => "Parking Location",
  LOCATION_HEADER: () => "Location",
  CHECK_IN_PROCEDURE_HEADER: () => "Check-in Procedure",
  CHECK_IN_LOCATION_LABEL: () => "Check-in Location",
  CHECK_IN_PROCEDURE_LABEL: () => "Check-in Procedure",
  CHECK_OUT_PROCEDURE_HEADER: () => "Check-out Procedure",
  CHECK_OUT_LOCATION_LABEL: () => "Check-out Location",
  CHECK_OUT_PROCEDURE_LABEL: () => "Check-out Procedure",

  SITE_POLICIES_HEADER: () => "Site Policies",
  TAKING_ATTENDANCE_LABEL: () => "Taking Attendance",
  ESCALATION_LABEL: () => "Escalation Procedure",
  SUBSTITUTES_HEADER: () => "Substitutes",
  STUDENTS_HEADER: () => "Students",
  LEAVING_CLASSROOM_LABEL: () => "Leaving the Classroom",
  LUNCH_PROTOCOL_LABEL: () => "Lunch Protocol",
  DRESS_CODE_LABEL: () => "Dress Code",
  ELECTRONICS_LABEL: () => "Electronics",
  EMERGENCY_PROCEDURES_HEADER: () => "Emergency Procedures",
  PROTOCOL_HEADER: () => "Protocol",
  CODE_WORDS_HEADER: () => "Code Words",
  LOCKDOWN_HEADER: () => "Lockdown Expectations/Consequences",
  NOT_PROVIDED: () => "Not provided",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
type GetMatchingKeys<Map extends Record<string, (...args: any[]) => string>, Shape> = {
  [Key in keyof Map]: Parameters<Map[Key]> extends Shape ? Key : never;
}[keyof Map];

type KeyWithEmptyFnParam = GetMatchingKeys<typeof string_table, []>;
type KeyWithFnParam = GetMatchingKeys<typeof string_table, [object]>;

export function msg<Key extends KeyWithFnParam>(
  key: Key,
  args: Parameters<(typeof string_table)[Key]>[0],
): string;
export function msg<Key extends KeyWithEmptyFnParam>(key: Key): string;
export function msg<Key extends keyof typeof string_table>(
  key: Key,
  ...args: Parameters<(typeof string_table)[typeof key]>
) {
  return string_table[key](args[0] as any);
}
