import { ReactNode } from "react";
import { Colors, Icon } from "swing-components";

type ErrorMessageProps = {
  children: ReactNode;
};

export function ErrorMessage(props: ErrorMessageProps) {
  const { children } = props;
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        marginTop: "8px",
        fontSize: "14px",
        color: Colors.red500,
      }}
    >
      <Icon name="Alert" color={Colors.red500} />
      {children}
    </div>
  );
}
