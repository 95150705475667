import { useQuery } from "@tanstack/react-query";

import { AlertBoxError, ContentSingleColumn, TipaltiIframe } from "~components";
import { GET, msg } from "~utils";
import { ScorePage } from "../../ScoreTemplates/ScorePage";

export function PaymentSetup() {
  const { data: tipaltiPaymentData, isLoading: isTipaltiLoading } = useQuery({
    queryKey: ["fetchPayment"],
    queryFn: () => GET("/api/sub/me/payment"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchOnWindowFocus: false, // avoid iframe content being re-loaded
  });

  return (
    <ScorePage title={msg("PAGE_TITLE_PAYMENT_SETUP")} hasBack isLoading={isTipaltiLoading}>
      <ContentSingleColumn>
        <div style={{ width: "100%", padding: "24px 16px" }}>
          {tipaltiPaymentData?.data.setupUrl ? (
            <TipaltiIframe
              title={msg("PAGE_TITLE_PAYMENT_MY_EARNINGS")}
              tipaltiUrl={tipaltiPaymentData?.data.setupUrl}
            />
          ) : (
            <AlertBoxError />
          )}
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}
