import { AxiosError } from "axios";
import { useState } from "react";
import { convertPreferencesToSave, convertSubjects, SubjectValueProps } from "swing-components";

import {
  AlertBoxError,
  isSameArrayContent,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
} from "~components";
import { Question } from "~onboarding/components";
import { QUESTION_SUBJECTS, UnsyncedSubAttribute } from "~onboarding/utils";
import { msg } from "~utils";

type ModalContentClassroomPreferencesSubjectsEditProps = {
  subjects?: SubjectValueProps;
  hasApiError?: AxiosError | null;
  onConfirm: (subjects: SubjectValueProps) => void;
  onDismiss: (data?: string | number, role?: string) => void;
  title: string;
};

export function ModalContentClassroomPreferencesSubjectsEdit(
  props: ModalContentClassroomPreferencesSubjectsEditProps,
) {
  const { subjects, hasApiError, onConfirm, onDismiss, title } = props;
  const [_subjects, _setSubjects] = useState<SubjectValueProps>(subjects ?? []);
  const _originalSubjects = subjects ?? [];
  const isSaveDisabled = _subjects.length <= 0 || isSameArrayContent(_originalSubjects, _subjects);

  function onChange({ value }: UnsyncedSubAttribute) {
    if (value) {
      const val = value as string;
      const convertedVals = convertPreferencesToSave(val, convertSubjects);
      _setSubjects(convertedVals);
    } else {
      _setSubjects([]);
    }
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        {!!hasApiError && <AlertBoxError />}
        <div>
          <Question {...QUESTION_SUBJECTS} value={JSON.stringify(_subjects)} onChange={onChange} />
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isSaveDisabled,
          text: msg("LABEL_SAVE"),
          onClick: () => onConfirm(_subjects),
        }}
      />
    </ModalSubProfileEdit>
  );
}
