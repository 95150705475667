import {
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
} from "~components";
import { msg } from "~utils";

type ModalContentPhoneNumberSuccessProps = {
  onDismiss: () => void;
  title: string;
};

export function ModalContentPhoneNumberSuccess(props: ModalContentPhoneNumberSuccessProps) {
  const { onDismiss, title } = props;

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img src="assets/phone-notification.png" alt="" />
        </div>
        <span
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            fontWeight: "var(--swing-font-semibold",
          }}
        >
          {msg("PROFILE_PHONE_NUMBER_SUCCESS_CONTENT_TITLE")}
        </span>
        <span style={{ textAlign: "center", margin: "0 auto", display: "block" }}>
          {msg("PROFILE_PHONE_NUMBER_SUCCESS_CONTENT_BODY")}
        </span>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: false,
          text: `${msg("LABEL_GREAT")}!`,
          onClick: () => {
            onDismiss();
          },
        }}
      />
    </ModalSubProfileEdit>
  );
}
