import { Colors, Icon } from "swing-components";

import { ButtonScore, MarkdownContent } from "~components";
import type { DownloadAnswerType } from "~onboarding/utils";

type DownloadAnswerProps = Omit<DownloadAnswerType, "type"> & {
  /**
   * The function to be called when the link is clicked
   */
  onChange: (value: string) => void;
  /**
   * Disables the ability to download the file.
   */
  isReadOnly?: boolean;
};

export function DownloadAnswer(props: DownloadAnswerProps) {
  const { markdown, download, value, onChange, isReadOnly } = props;
  const { label, url, filename } = download;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      {markdown && <MarkdownContent body={markdown} />}
      <ButtonScore
        disabled={isReadOnly}
        target="_blank"
        rel="noreferrer"
        href={url}
        download={filename}
        fill="outline"
        onClick={() => onChange(value)}
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ position: "absolute", left: 0 }}>
              <Icon name="Download" color={isReadOnly ? Colors.slate400 : Colors.blue500} />
            </div>
            {label}
          </div>
        }
      />
    </div>
  );
}
