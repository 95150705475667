import { useEffect, useState } from "react";

type TipaltiIframeProps = {
  tipaltiUrl: string;
  title: string;
};

interface TipaltiIframeInfoType {
  TipaltiIframeInfo: {
    height?: number;
  };
}

export function TipaltiIframe(props: TipaltiIframeProps) {
  const { tipaltiUrl, title } = props;
  const [iframeHeight, setIframeHeight] = useState<number>();

  const listener = (e: MessageEvent<TipaltiIframeInfoType>) => {
    if (e.data && e.data.TipaltiIframeInfo) {
      setIframeHeight(e.data.TipaltiIframeInfo.height);
    }
  };
  useEffect(() => {
    window.addEventListener("message", listener, false);
    return () => {
      window.removeEventListener("message", listener, false);
    };
  }, []);

  return (
    <iframe
      title={title}
      src={tipaltiUrl}
      height={iframeHeight}
      style={{ width: "100%", border: "none", padding: 0 }}
    />
  );
}
