import { Colors } from "swing-components";

import { Chip, ChipProps, ProgressIcon, ProgressIconProps } from "~components";

import "./TaskCardBase.css";

export type TaskCardBaseProps = {
  /**
   * Boolean for rendering boxShadow
   */
  boxShadow?: boolean;
  /**
   * Optional object containing props for the ProgressIcon
   * If this is not passed the Icon will not be rendered
   */
  progressIcon?: ProgressIconProps;
  /**
   * Bolded text
   */
  title: string;
  /**
   * Smaller text
   */
  description: string;
  /**
   * Optional object containing props for the Chip
   * If this is not passed the Chip will not be rendered
   */
  chip?: Omit<ChipProps, "icon">;
  onClick?: () => void;
};

export function TaskCardBase(props: TaskCardBaseProps) {
  const { boxShadow, progressIcon, title, description, chip, onClick } = props;

  const shadow = boxShadow ? "shadow" : "";

  return (
    <button
      onClick={onClick}
      className={shadow}
      style={{
        backgroundColor: Colors["white200"],
        color: Colors.black,
        display: "flex",
        flexDirection: "row",
        borderRadius: "8px",
        padding: "16px",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "152px",
        width: "100%",
        textAlign: "left",
        // Change the cursor when onClick is passed
        cursor: onClick ? "pointer" : "default",
      }}
    >
      {progressIcon && (
        <ProgressIcon
          icon={progressIcon.icon}
          percentage={progressIcon.percentage}
          color={progressIcon.color}
        />
      )}
      <div style={{ marginLeft: "16px" }}>
        <p style={{ fontSize: "14px", fontWeight: "600" }}>{title}</p>
        <p style={{ fontSize: "14px", fontWeight: "400" }}>{description}</p>
        {chip && <Chip color={chip.color} label={chip.label} />}
      </div>
    </button>
  );
}
