import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Colors } from "swing-components";

import styles from "./Card.module.css";

type CardProps = {
  children: ReactNode;
  isBorderRadius?: boolean;
  isBoxShadow?: boolean;
  href?: string;
};

/**
 * Component | Card
 *
 * A card with the appropriate styles and render the children.
 *
 * @Figma https://www.figma.com/design/uJsnuoosxqn26Rot52zCXZ/%F0%9F%A7%B9-Swing-Design-System-v2?node-id=8232-4118&m=dev
 */
export function Card(props: CardProps) {
  const { children, isBorderRadius = true, isBoxShadow = true, href } = props;
  const clickableStyles = href ? styles["on-click-styles"] : "";

  return (
    <div
      style={{
        padding: 16,
        borderRadius: isBorderRadius ? 16 : 0,
        backgroundColor: Colors.white200,
        // Taken from design system guidelines
        boxShadow: isBoxShadow ? "0px 0px 16px 4px rgba(40, 60, 93, 0.16)" : "none",
        minHeight: "fit-content",
      }}
      className={clickableStyles}
    >
      {href && <Link to={href} rel="noopener noreferrer" className={styles["card-link"]} />}
      {children}
    </div>
  );
}
