import { Colors } from "swing-components";

import { MarkdownContent } from "~components";
import {
  AccordionAnswer,
  CheckboxAnswer,
  ContentAnswer,
  DownloadAnswer,
  LinkAnswer,
  NumberAnswer,
  OutlineAnswer,
  RadioAnswer,
  SignatureAnswer,
  TextAnswer,
  UploadAnswer,
  WebinarConfirmationAnswer,
  WebinarDatesAnswer,
} from "~onboarding/components";
import type { AnswerType, QuestionComputed, UnsyncedSubAttribute } from "~onboarding/utils";
import { WebinarOption } from "~utils";

type QuestionProps = QuestionComputed & {
  /**
   * When answering a Question, this function will pass the `value` to the
   * parent Step component.
   */
  onChange: ({ attribute, value }: UnsyncedSubAttribute) => void;
  /**
   * Disables the editability of the Question
   */
  isReadOnly?: boolean;
};

export function Question(props: QuestionProps) {
  const {
    attribute,
    label,
    description,
    value,
    answerPlacement = "BOTTOM",
    onChange,
    isReadOnly,
    answerType,
  } = props;

  /***** Handler *****/
  /**
   * Pass the value to the parent Step component only when the Question is not
   * in a readonly state.
   */
  function handleChange(value: UnsyncedSubAttribute["value"]) {
    // Do not sent updates when the Question is read-only
    if (isReadOnly) return;

    // Otherwise, pass the identifier and value to the parent Step component
    onChange({ attribute, value });
  }

  return (
    <div
      style={{
        border: "none",
        backgroundColor: Colors.white200,
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      {answerPlacement === "TOP" && (
        <Answer
          answerType={answerType}
          onChange={handleChange}
          value={value}
          isReadOnly={isReadOnly}
        />
      )}

      {/* avoid warning from nested p tag */}
      <div style={{ margin: 0, fontWeight: 600, fontSize: 16, color: Colors.black }}>
        <MarkdownContent body={label} />
      </div>
      {description && (
        <p style={{ margin: 0, fontWeight: 400, fontSize: 16, color: Colors.black }}>
          {description}
        </p>
      )}

      {answerPlacement === "BOTTOM" && (
        <Answer
          answerType={answerType}
          onChange={handleChange}
          value={value}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
}

/********** Helper Component *********/
type AnswerProps = {
  answerType: AnswerType;
  onChange: (value: UnsyncedSubAttribute["value"]) => void;
  value?: string | object;
  isReadOnly?: boolean;
};

/** Renders any answer type */
function Answer(props: AnswerProps) {
  const { answerType, onChange, value, isReadOnly } = props;
  const { type } = answerType;

  /***** Render - Content Types */
  if (type === "ACCORDION")
    return <AccordionAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly} />;
  if (type === "CONTENT")
    return <ContentAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly} />;
  if (type === "OUTLINE")
    return <OutlineAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly} />;

  /***** Render - Click Types */
  if (type === "LINK")
    return <LinkAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly} />;
  if (type === "DOWNLOAD")
    return <DownloadAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly} />;
  if (type === "UPLOAD")
    return <UploadAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly || !!value} />;

  /***** Render - Input Types */
  if (type === "TEXT")
    return (
      <TextAnswer
        {...answerType}
        onChange={onChange}
        value={value as string}
        isReadOnly={isReadOnly}
      />
    );
  if (type === "NUMBER")
    return (
      <NumberAnswer
        {...answerType}
        onChange={onChange}
        value={value as string}
        isReadOnly={isReadOnly}
      />
    );

  /***** Render - List Types */
  if (type === "RADIO")
    return (
      <RadioAnswer
        {...answerType}
        onChange={onChange}
        value={value as string}
        isReadOnly={isReadOnly}
      />
    );
  if (type === "CHECKBOX")
    return (
      <CheckboxAnswer
        {...answerType}
        onChange={onChange}
        value={value as string}
        isReadOnly={isReadOnly}
      />
    );
  if (type === "WEBINAR_DATES")
    return (
      <WebinarDatesAnswer
        {...answerType}
        onChange={onChange}
        value={value as WebinarOption}
        isReadOnly={isReadOnly}
      />
    );
  if (type === "WEBINAR_CONFIRMATION")
    return <WebinarConfirmationAnswer {...answerType} value={value as WebinarOption} />;
  if (type === "SIGNATURE")
    return (
      <SignatureAnswer {...answerType} onChange={onChange} isReadOnly={isReadOnly || !!value} />
    );

  // Default
  return null;
}
