import { msg } from "~utils";
import styles from "./LoadingInterstitial.module.css";

export function LoadingInterstitial() {
  return (
    <div className={styles["loader-wrapper"]}>
      <img alt="" src="assets/swing-s.svg" className={styles["swing-s"]} />
      <img
        alt={msg("LABEL_SWING_LOADING_ALT")}
        src="assets/swing-curve.svg"
        className={styles["loader"]}
      />
    </div>
  );
}
