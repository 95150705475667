import type { JSX } from "@ionic/core/components";
import { IonButton } from "@ionic/react";
import { CSSProperties, ReactNode, useState } from "react";
import { Colors } from "swing-components";

import { useIsDesktop } from "~utils";
import styles from "./ButtonScore.module.css";

type buttonFill = "clear" | "outline" | "solid";

type Button = JSX.IonButton & {
  label?: ReactNode;
  fill?: buttonFill;
  style?: CSSProperties;
  onClick?: () => void;
};
// Stripped down button for use by sub app to support divergent development between
// teams. Plan to consolidate back into shared component after stable app release
export function ButtonScore(params: Button) {
  const { label, style, disabled, fill = "solid", ...rest } = params;
  // Ionic button does not support border color change on hover
  // so instead we can use a react useState to change the border color
  const [isHover, setIsHover] = useState(false);

  const Styles = {
    outline: {
      "--color-hover": Colors.blue600,
      "--color-hover-opacity": "0.0",
      "--border-color": isHover ? Colors.blue600 : Colors.blue500,
      "--background-hover-opacity": "0.0",
    },
    solid: {
      "--background-hover": "#000",
      "--background-hover-opacity": "0.3",
      "--box-shadow": "0px 2px 4px 0px rgba(40, 60, 93, 0.16)",
    },
    disabled: {
      "--background": Colors.slate200,
      color: Colors.slate400,
      "--box-shadow": "0 2px 2px 0 #000 14.77",
      "--ripple-color": Colors.slate200,
    },
    clear: {},
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function getStyle(fill: buttonFill) {
    const buttonStyle = disabled ? Styles["disabled"] : Styles[fill];
    return { margin: 0, ...buttonStyle, ...style };
  }

  const ionFill = disabled ? "solid" : fill;

  return (
    <IonButton
      data-testid="button-score"
      className={styles.buttonScore}
      fill={ionFill}
      style={getStyle(ionFill)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
      {...rest}
    >
      {label}
    </IonButton>
  );
}

type ButtonScoreWrapperProps = {
  buttonPrimary: Button;
  buttonSecondary?: Button;
  backgroundColor?: keyof typeof Colors;
};

export function ButtonScoreWrapper(props: ButtonScoreWrapperProps) {
  const { buttonPrimary, buttonSecondary, backgroundColor } = props;
  const isDesktop = useIsDesktop();

  const backgroundColorStyle = {
    backgroundColor: isDesktop ? "inherit" : backgroundColor ? Colors[backgroundColor] : "inherit",
  };

  const buttonLayoutStyle = buttonSecondary ? styles["two-buttons"] : "";

  return (
    <div
      className={`${styles["action-button-wrapper"]} ${buttonLayoutStyle}`}
      style={backgroundColorStyle}
    >
      {buttonSecondary && (
        <div className={styles["action-button"]}>
          <ButtonScore
            {...buttonSecondary}
            fill="outline"
            disabled={buttonSecondary.disabled}
            onClick={!buttonSecondary.disabled ? buttonSecondary.onClick : undefined}
          />
        </div>
      )}
      <div className={styles["action-button"]}>
        <ButtonScore
          {...buttonPrimary}
          onClick={!buttonPrimary.disabled ? buttonPrimary.onClick : undefined}
        />
      </div>
    </div>
  );
}
